<template>
  <div>
    <div
      class="d-flex justify-content-between w-100"
    >
      <div
        class="expand-icon d-flex align-items-center justify-content-center"
        :style="expandable ? 'cursor: pointer' : 'cursor: default'"
        @click="$emit('click', expandable && !expanded)"
      >
        <div v-if="expanded && expandable">
          <i
            class="pb-1"
            :class="{
              'ion ion-md-arrow-dropdown': !smallVariant,
              'ion ion-md-remove-circle-outline': smallVariant,
            }"
          />
        </div>
        <div v-else-if="expandable">
          <i
            v-if="!expanded && !expandable && smallVariant"
            class="pb-1 ion ion-md-radio-button-off"
          />
          <i
            v-else
            class="pb-1"
            :class="{
              'ion ion-md-arrow-dropright': !smallVariant,
              'ion ion-md-add-circle-outline': smallVariant,
            }"
          />
        </div>
      </div>

      <div
        class="d-flex flex-grow-1 no-gutters asset align-items-center hoverable"
        :class="{selected, 'small-variant': smallVariant}"
        :style="expandable || selectable ? 'cursor: pointer' : 'cursor: default'"
        style="height: 30px; overflow: hidden"
        @click="clickRow"
      >
        <div
          v-for="(column, index) in columns"
          :key="index"
          :class="index == 0 ? (column.class || '') : `${column.class || ''} col px-2`"
          class="d-flex text-left flex-grow-1 mw-100"
          style="line-height: 15px"
          :style="{ width: index === 0 ? `calc(100% - ${paddingSum + 30}px)` : null }"
        >
          <div
            v-tippy="{ arrow: true, delay: 1000 }"
            class="mr-2 text-truncate flex-grow-1 name"
            :class="{'ml-2': !smallVariant}"
            :content="node[column.key]"
          >
            {{ node[column.key] }}
          </div>
          <div
            v-if="node[column.secondary]"
            v-tippy="{ arrow: true, delay: 1000 }"
            class="mr-2 align-self-center text-truncate"
            :content="node[column.secondary]"
          >
            <span
              class="text-secondary small"
              style="line-height: 1; padding-bottom: 2px"
            >
              {{ node[column.secondary] }}
            </span>
          </div>
          <i
            v-if="disabled"
            v-tippy="{ placement : 'right' }"
            class="ion ion-md-close-circle-outline"
            :content="$t('taxonomies.nodeDisabledForSelection')"
          />
        </div>
        <div class="align-self-center pl-2">
          <button
            v-if="!noEdit && !selectable"
            class="btn btn-outline-secondary btn-sm icon-btn"
            @click.stop="$emit('edit')"
          >
            <i class="ion ion-md-create" />
          </button>
          <div
            v-if="selected"
            class="ml-auto align-self-center"
          >
            <i class="ion ion-md-checkmark" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { elementType } from '@/utils/dictionary';

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    node: {
      type: Object,
      default: () => ({}),
    },
    reduce: {
      type: Function,
      required: true,
    },
    expanded: Boolean,
    expandable: Boolean,
    paddingSum: {
      type: Number,
      default: 0,
    },
    selected: Boolean,
    selectable: Boolean,
    disabled: Boolean,
    smallVariant: Boolean,
    showTaxonomyBasedTree: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    noEdit() {
      if (!this.node || !this.node.type) return true;
      return this.node.type === elementType.plant;
    },
    noAdd() {
      if (!this.node || !this.node.type) return false;
      return this.node.type === elementType.machine;
    },
  },
  methods: {
    clickRow() {
      if (this.disabled) return;

      if (this.selectable) {
        this.$emit('select', this.reduce(this.node));
      } else {
        this.$emit('click', this.expandable && !this.expanded);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .hoverable {
    transition: background-color 300ms;

    &:hover {
      background-color: rgba(150, 150, 150, 0.1);
    }
  }

  .name {
    font-weight: 400;
    font-size: 14px;
  }

  .expand-icon {
    min-width: 20px;
    text-align: center;
    border-radius: 5px;
    display: flex;

    &:hover {
      background-color: #fafafa;
    }
  }

  .asset {
    background-color: #fafafa;
    margin-top: 2px;
    border-radius: 5px;
    padding: 0px 10px;
    line-height: 1;
    text-align: left;
    border: 1px solid transparent;
    position: relative;

    &.small-variant {
      box-shadow: unset !important;
      padding: 2px 6px 2px 0;
      height: 24px !important;
    }

    &.selected {
      background-color: #dfdfdf;
    }

    &.disabled {
      box-shadow: none;
    }
  }

  .selected {
    background-color: rgba(150, 150, 150, 0.3);
  }

  .selected-icon {
    font-size: 12px;
    line-height: 1;
  }

  .name {
    font-weight: 500;
    font-size: 12px;
  }

</style>
