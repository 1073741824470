<template>
  <div>
    <div
      v-if="!hideParent"
    >
      <div
        class="d-flex v-centered mr-0"
        :style="{
          'margin-left': `${currentDepth * 25}px`
        }"
      >
        <div
          style="width: 20px; text-align: center; cursor: pointer"
        >
          <div v-if="root.children">
            <i
              v-if="expand"
              class="ion ion-md-arrow-dropdown"
              @click="toggle(root.children && !expand)"
            />
            <i
              v-else
              class="ion ion-md-arrow-dropright"
              @click="toggle(root.children && !expand)"
            />
          </div>
        </div>
        <div
          class="big-box hoverable w-100 flex-grow-1 position-relative"
          :style="{
            'border-color':
              parentSelected || selected === root.name ? '#333' : null,
          }"
          :class="{'disable-select': isDisabled}"
          @click="select"
        >
          <div class="pr-2 d-flex align-items-center">
            {{ root.text }}
          </div>
          <div
            v-if="checkWithCheckmark(root.name)"
            class="v-center position-absolute"
            style="right: 8px; top: 50%; transform: translateY(-50%)"
          >
            <i class="ion ion-md-checkmark" />
          </div>
          <div
            v-else-if="!expand && checkWithCheckmarkSelected"
            class="v-center position-absolute selected-perm-circle"
            style="right: 8px; top: 50%; transform: translateY(-50%)"
          >
            {{ getCountOfSelectedChildren(root) }}
          </div>
          <div class="d-flex flex-wrap w-100">
            <div
              v-for="p in sameNamePermissions"
              :key="`${p.name}_${p.value}`"
              v-tippy="{ arrow: true, trigger: p.inherited ? 'mouseenter' : 'manual' }"
              :content="`${$t('permissions.inheritedFrom')} ${p.inherited}`"
              class="badge text-uppercase mt-1 overflow-hidden mw-100 mr-3"
              style="font-size: 10px; opacity: 0.6; text-overflow: ellipsis;"
              :class="p.inherited ? 'badge-outline-secondary' : 'badge-secondary'"
              :style="p.inherited ? 'border: 1px solid' : ''"
            >
              {{ p.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <SmoothReflow v-if="isExpanded">
      <div
        v-for="element in root.children"
        :key="element.id"
      >
        <PermissionsTree
          v-if="expand"
          :root="element"
          :permissions="element.children"
          :check-with-checkmark-selected="checkWithCheckmarkSelected"
          :current-depth="currentDepth + 1"
          :is-expanded="expand"
          :assigned-permissions="assignedPermissions"
          :parent-selected="selected === root.name || parentSelected"
          :root-name="rootName"
          :selected="selected"
          :selected-subject-type="selectedSubjectType"
          @permission-selected="permissionSelected"
          @select="selectChild($event)"
        />
      </div>
    </SmoothReflow>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'PermissionsTree',
  props: {
    permissions: {
      type: Array,
      default: () => [],
    },
    selected: String,
    root: {
      type: Object,
      required: true,
    },
    parentSelected: Boolean,
    isExpanded: {
      type: Boolean,
      required: false,
    },
    assignedPermissions: {
      type: Array,
      default: () => [],
    },
    isClickedFromParent: {
      type: Boolean,
      required: false,
    },
    currentDepth: {
      type: Number,
      required: false,
    },
    hideParent: Boolean,
    checkWithCheckmarkSelected: {
      type: Boolean,
      default: false,
    },
    rootName: {
      type: String,
      required: false,
    },
    selectedSubjectType:
      {
        type: String,
        required: false,
      },
  },
  data() {
    return {
      isTicked: false,
      isClicked: this.isClickedFromParent,
      expand: true,
      validSubjectTypeForPlant: ['PlantId', 'LineId'],
    };
  },
  computed: {
    ...mapGetters(['permissionTypeList', 'tenantId']),
    ...mapState({
      plants: state => state.structure.plants || [],
      tenants: state => state.tenant.tenants || [],
      roles: state => state.role.allRoles || [],
      elements: state => state.structure.elements || [],
    }),
    isDisabled() {
      return !!this.selectedSubjectType && !this.validSubjectType(this.selectedSubjectType);
    },
    sameNamePermissions() {
      return this.assignedPermissions
        .filter(x => x.name === this.root.name)
        .map(x => ({
          ...x,
          perm: this.permissionTypeList
            .find(y => y.name.toLowerCase() === x.name.toLowerCase()),
        }))
        .filter(x => x.perm)
        .map(x => ({
          ...x,
          name: this.findName(x.perm.arg, x.value) || '',
        }))
        .filter(x => x.name);
    },
  },
  methods: {
    validSubjectType(selectedSubjectType) {
      if (this.validSubjectTypeForPlant.includes(selectedSubjectType)) {
        return this.validSubjectTypeForPlant.includes(this.root.arg);
      }
      return this.root.arg === 'TenantId';
    },
    toggle(expanded) {
      this.expand = expanded;
    },
    isSelected(elementName) {
      return this.assignedPermissions.includes(elementName);
    },
    getCountOfSelectedChildren(root) {
      return this.getSelectedChildren(root, 0);
    },
    checkWithCheckmark(elementName) {
      return this.selected === elementName
        || (this.checkWithCheckmarkSelected && this.isSelected(elementName));
    },
    selectChild(event) {
      this.$emit('select', event);
    },
    select() {
      const isPermSelected = this.assignedPermissions.includes(this.root.name);
      const shouldCleansubjectType = isPermSelected && this.assignedPermissions.length === 1;
      this.$emit('select', this.root.name);

      this.sendPermSelected(shouldCleansubjectType);
    },
    permissionSelected(arg, shouldCleansubjectType) {
      this.$emit('permission-selected', arg, shouldCleansubjectType);
    },
    sendPermSelected(shouldCleansubjectType) {
      const permType = this.findPerm(this.root.name);
      let newsubjectType = permType.arg;
      if (!permType || !newsubjectType) {
        return;
      }
      if (shouldCleansubjectType) {
        newsubjectType = '';
      }

      this.$emit('permission-selected', newsubjectType);
    },
    getSelectedChildren(root, previousSelectedChildCount) {
      let selectedChildCount = previousSelectedChildCount;
      if (root.children === undefined) {
        return previousSelectedChildCount;
      }
      root.children.forEach(element => {
        if (this.isSelected(element.name)) {
          selectedChildCount += 1;
        }
        selectedChildCount = this.getSelectedChildren(element, selectedChildCount);
      });
      return selectedChildCount;
    },
    findPerm(permName) {
      return this.permissionTypeList.find(x => x.name.toLowerCase() === permName.toLowerCase());
    },
    findName(arg, id) {
      switch (arg.toLowerCase()) {
        case 'plantid':
          return this.plants.find(x => x.id === id)?.name;
        case 'elementid':
        case 'lineid': {
          const element = this.elements.concat(this.plants)
            .find(x => x.id === id);
          if (!element) return '';

          if (element.plantId && this.plants.length > 1) {
            const plant = this.plants.find(x => x.id === element.plantId);

            if (plant && plant.id !== element.id) {
              return `${plant.name} / ${element.name}`;
            }

            if (plant) {
              return plant.name;
            }

            return element.name;
          }
          return element.name;
        }
        case 'groupid': {
          const tenant = this.tenants.find(x => x.id === id);
          if (tenant) {
            return tenant.name;
          }

          const role = this.roles.find(x => x.id === id);
          if (this.tenants.length === 1) {
            return role.name;
          }
          const roleTenant = this.tenants.find(x => x.id === role.tenantId);
          return `${roleTenant.name} / ${role.name}`;
        }
        case 'tenantid':
          return this.tenants.find(x => x.id === id)?.name;
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.big-box {
  box-shadow: 0 2px 3px #efefef, 0 2px 3px #efefef;
  border-radius: 5px;
  padding: 4px 10px;
  margin-top: 3px;
  line-height: 1.1;
  text-align: left;
  background-color: #fff;
  border: 1px solid transparent;
  font-size: 0.9rem;
}

.disable-select {
  pointer-events: none;
  opacity: 0.4;
}

.hoverable {
  transition: background-color 500ms;
  transition: text-decoration 900ms;
  cursor: pointer;

  &:hover {
    border-color: #333;
  }
}

.v-centered {
  align-items: center;
}

.selected-back-color {
  background-color: black;
}

.selected-perm-circle {
  background-color: #6c757d;
  color: white;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
}
</style>
