<template>
  <div>
    <form
      class="text-left"
      @submit.prevent="save"
    >
      <label class="mb-0 small font-weight-bold">
        {{ $t('general.name') }}
      </label>
      <BFormInput
        v-model="name"
        class="mb-2"
      />
      <div class="mt-3">
        <SaveButton
          :pending="pending"
          :remove-pending="removePending"
          :disabled="submitDisabled"
          :show-remove="!!competence"
          @cancel="$emit('close')"
          @remove="remove()"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { handleApiError } from '@/utils/handleApiError';

export default {
  props: {
    competence: Object,
  },
  data: () => ({
    pending: false,
    removePending: false,
    error: null,
    id: '',
    name: '',
  }),
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    ...mapGetters(['tenantId']),
    submitDisabled() {
      return !this.name;
    },
    competenceRequestModel() {
      return {
        name: this.name,
      };
    },
  },
  methods: {
    ...mapActions(['createCompetence', 'updateCompetence', 'deleteCompetence']),
    save() {
      if (this.competence) {
        this.update();
      } else {
        this.create();
      }
    },
    update() {
      this.pending = true;
      this.updateCompetence({
        params: {
          id: this.id,
        },
        data: this.competenceRequestModel,
      })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
          this.$emit('close');
        });
    },
    create() {
      this.pending = true;
      this.createCompetence({
        params: {
          tenantId: this.tenantId,
        },
        data: this.competenceRequestModel,
      })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
          this.$emit('close');
        });
    },
    remove() {
      this.removePending = true;
      this.deleteCompetence({
        params: {
          id: this.id,
        },
      })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
          this.$emit('close');
        });
    },
  },
  created() {
    if (this.competence) {
      this.id = this.competence.id;
      this.name = this.competence.name;
    }
  },
};
</script>

<style>

</style>
