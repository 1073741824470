<template>
  <div class="text-danger small">
    {{ message || $t('error.error') }}
  </div>
</template>

<script>
export default {
  props: {
    message: String,
  },
};
</script>
