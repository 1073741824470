<template>
  <CustomSelect
    v-model="innerValue"
    v-bind="$attrs"
    :options="allTaxonomies"
  >
    <template #selected>
      <i class="fas fa-sitemap" />
      <span
        class="select-option-text"
        :style="{width: `calc(100% + 10px)`}"
      >
        {{ selectedTaxonomyDescription }}
      </span>
    </template>

    <template #option="taxonomy">
      <i
        v-if="taxonomy.id"
        class="fas fa-sitemap icon"
      />
      <span class="select-option-text">
        {{ taxonomy.description || taxonomy.code || '-' }}
      </span>
    </template>
  </CustomSelect>
</template>

<script>
import CustomSelect from './CustomSelect';

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    taxonomies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dropdownVisible: false,
    };
  },
  components: { CustomSelect },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    selectedTaxonomyDescription() {
      if (!this.innerValue) return this.$t('general.default');
      const taxonomy = this.taxonomies.find(t => t.id === this.innerValue);
      return taxonomy?.description || taxonomy?.code || '-';
    },
    allTaxonomies() {
      return [
        { id: null, description: this.$t('taxonomies.defaultTaxonomy') },
        ...this.taxonomies,
      ];
    },
  },
};
</script>
