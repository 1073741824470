import Vue from 'vue';

export default class Graph {
  constructor(id, nodes, edges, rootId) {
    this.nodes = {};
    this.id = id;
    nodes.forEach((node, i) => {
      this.nodes[node.id] = { index: i, ...node };
    });

    this.order = nodes.reduce((acc, curr, idx) => {
      acc[curr.id] = idx;
      return acc;
    }, {});

    this.edges = edges;
    this.rootId = rootId;

    this.calcNodeDepths();
  }

  childrenOf(id) {
    return this.edges
      .filter(({ from }) => from === id)
      .map(({ to }) => to);
  }

  // Calculates the topological ordering of the graph
  sort() {
    const startEdge = this.edges.find(e => this.edges.some(({ to }) => e.from === to));
    const start = startEdge ? startEdge.from : Object.keys(this.nodes)[0];

    const stack = [];
    const visited = {};

    const topoSort = nodeId => {
      const toConn = this.edges.filter(({ from }) => from === nodeId)
        .map(x => x.to);
      const fromConn = this.edges.filter(({ to }) => to === nodeId)
        .map(x => x.from);
      visited[nodeId] = true;

      toConn.concat(fromConn)
        .forEach(node => {
          if (!visited[node]) {
            topoSort(node);
          }
        });

      stack.push(nodeId);
    };

    topoSort(start);

    this.order = stack.reverse()
      .reduce((acc, id, idx) => {
        acc[id] = idx;
        return acc;
      }, {});
  }

  inline() {
    const startList = this.edges.filter(e => !this.edges.some(({ to }) => e.from === to));
    if (!startList) return {};
    let order = [];
    startList.forEach(start => {
      order = [...order, start.from];
      this.BFSearch(start.from, nodeId => {
        order.push(nodeId);
      });
    });

    return order.reduce((acc, id, idx) => {
      acc[id] = idx;
      return acc;
    }, {});
  }

  allWithoutEdges() {
    return Object.values(this.nodes)
      .filter(n => !this.edges.some(e => e.from === n.id || e.to === n.id));
  }

  allLowerChildren(parentId) {
    const loop = pid => {
      const lowerChildren = this.edges
        .filter(({ from }) => from === pid)
        .map(({ to }) => to)
        .filter(id => this.depths[id] > this.depths[pid]);

      return lowerChildren
        .flatMap(id => loop(id))
        .concat(lowerChildren);
    };

    return loop(parentId)
      .concat([parentId])
      .sort((a, b) => this.order[a] - this.order[b]);
  }

  lowerChildrenOf(parentId, depths) {
    const loop = (pid, d) => {
      const lowerChildren = this.edges
        .filter(({ from }) => from === pid)
        .map(({ to }) => to)
        .filter(id => this.depths[id] > this.depths[pid]);

      if (d > 1) {
        return lowerChildren
          .flatMap(id => loop(id, d - 1))
          .concat(lowerChildren);
      }

      return lowerChildren;
    };

    return loop(parentId, depths || 1)
      .sort((a, b) => this.order[a] - this.order[b]);
  }

  parentsIds(childId) {
    return this.edges
      .filter(({ to }) => to === childId)
      .map(({ from }) => from);
  }

  parent(childId, depths) {
    const d = depths || 1;
    const parents = this.edges
      .filter(({ to }) => to === childId)
      .map(({ from }) => from)
      .filter(id => this.depths[id] < this.depths[childId]);

    if (parents.length === 0) {
      return null;
    }

    if (d > 1) {
      return this.parent(parents[0], d - 1);
    }

    return parents[0];
  }

  calcNodeDepths() {
    const depths = { [this.rootId]: 1 };

    this.BFSearch(this.rootId, (nodeId, parentId) => {
      depths[nodeId] = depths[parentId] + 1;
    });

    this.depths = depths;
  }

  node(id) {
    return this.nodes[id];
  }

  addEdge(e) {
    this.edges.push(e);
  }

  removeEdge({
    from,
    to,
  }) {
    this.edges = this.edges.filter(e => e.from !== from || e.to !== to);
  }

  addNode(node) {
    Vue.set(this.nodes, node.id, node);
  }

  removeNode(id) {
    Vue.delete(this.nodes, id);
  }

  depth(id) {
    return this.depths[id];
  }

  nodeList() {
    return this.nodeIds()
      .map(key => {
        const node = this.nodes[key];
        return { id: key, ...node };
      });
  }

  nodeIds() {
    return Object.keys(this.nodes);
  }

  edgesInDepth(depth) {
    return this.edges
      .filter(({
        from,
        to,
      }) => this.depth(from) === depth && this.depth(from) === this.depth(to));
  }

  nodesInDepth(depth) {
    return Object.keys(this.depths)
      .filter(k => this.depths[k] === depth);
  }

  BFSearch(startId, callback) {
    const visited = {};
    const queue = [startId];
    while (queue.length > 0) {
      const elementId = queue.shift();
      const children = this.childrenOf(elementId)
        .filter(id => !visited[id]);
      // eslint-disable-next-line no-loop-func
      children.forEach(nodeId => {
        callback(nodeId, elementId);
        visited[nodeId] = true;
        queue.push(nodeId);
      });
    }
  }

  getPositionsForNodes(nodes) {
    let positions = {};
    let y = 0;

    nodes.forEach(n => {
      if (positions[n]) return;
      const pos = this.getPositions(n);
      const {
        min,
        max,
      } = Object.values(pos)
        .reduce((acc, curr) => ({
          min: Math.min(curr.y, acc.min),
          max: Math.max(curr.y, acc.max),
        }), {
          min: 0,
          max: 0,
        });
      y += (max - min);

      const movedPos = Object.keys(pos)
        .reduce((acc, curr) => ({
          [curr]: {
            x: pos[curr].x,
            y: pos[curr].y + y,
          },
          ...acc,
        }), {});

      positions = { ...positions, ...movedPos };
      y += 1;
    });

    return positions;
  }

  getPositions(firstId) {
    const depth = this.depth(firstId);
    const result = {
      [firstId]: {
        x: 0,
        y: 0,
      },
    };
    const yInX = { 0: 1 };
    let maxX = 0;
    const edges = this.edgesInDepth(depth);
    let lastId = firstId;

    const nextLayer = (nodeId, x) => {
      const layer = edges
        .filter(({ from }) => from === nodeId)
        .map(({ to }) => to);

      layer.forEach(id => {
        if (!result[id]) {
          if (!yInX[x]) {
            yInX[x] = 1;
          } else {
            yInX[x] += 1;
          }
          result[id] = {
            x,
            y: (yInX[x] - 1),
          };
        }
        if (x > maxX) {
          maxX = Math.max(maxX, x);
          lastId = id;
        }
        nextLayer(id, x + 1);
      });
    };

    const previousLayer = (nodeId, x) => {
      const layer = edges
        .filter(({ to }) => to === nodeId)
        .map(({ from }) => from);

      layer.forEach(id => {
        if (!result[id]) {
          if (!yInX[x]) {
            yInX[x] = 1;
          } else {
            yInX[x] += 1;
          }
          result[id] = {
            x,
            y: (yInX[x] - 1),
          };
        }
        previousLayer(id, x - 1);
      });
    };

    nextLayer(firstId, 1);
    previousLayer(lastId, maxX - 1);

    return result;
  }
}
