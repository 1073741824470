/* eslint-disable no-console */

export function error(message) {
  console.log(
    `%c !!! %c ${message} `,
    'background-color: #f00; color: #fff; padding: 2px; font-weight: bold;',
    'background-color: #fcc; color: #333; padding: 2px;',
  );
}

export function warn(message) {
  console.log(
    `%c ! %c ${message} `,
    'background-color: #FF7400; color: #fff; padding: 2px; font-weight: bold;',
    'background-color: #FFD6B3; color: #333; padding: 2px;',
  );
}

export function info(message) {
  console.log(
    `%c ~ %c ${message} `,
    'background-color: #00f; color: #fff; padding: 2px; font-weight: bold;',
    'background-color: #ccf; color: #333; padding: 2px;',
  );
}

export default {
  error,
  info,
};
