<template>
  <div>
    <Tippy
      ref="popup"
      class="popup"
      placement="bottom"
      theme="light"
      interactive
      :distance="15"
      append-to="parent"
      trigger="click"
      arrow
    >
      <template #trigger>
        <span class="plant-title">
          <span
            v-if="selectedName"
            class="text-fancy mb-0 title underscore"
          >
            {{ selectedName }}
          </span>
          <span
            v-else-if="!selected && list.length > 0"
            class="text-fancy  mb-0 title underscore"
          >
            <i class="ion ion-ios-swap" />
          </span>
        </span>
      </template>
      <div class="text-center plant-list text-fancy">
        <div
          v-for="element in list.filter(p => p.id.toString().toLowerCase() !== selected.toString().toLowerCase())"
          :key="element.id"
          class="p-select"
          @click="$emit('selected', element.id); close()"
        >
          <span class="underscore">{{ element.name }}</span>
        </div>
      </div>
    </Tippy>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    selected: [String, Number],
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    selectedName() {
      const el = this.list.find(x => x.id.toString().toLowerCase() === this.selected.toString().toLowerCase());
      return el ? el.name : null;
    },
  },
  methods: {
    close() {
      this.$refs.popup.tippy().hide();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .plant-title {
    position: relative;
    cursor: pointer;
    .expand-arrow > i {
      transition: transform 500ms, color 500ms;
    }
  }
  .title {
    font-size: 1.5rem;
    color: white;
    line-height: 1.1;
  }

  .p-select {
    position: relative;
    cursor: pointer;
    padding-top: 2px;
    padding-bottom: 2px;
    transition: color 500ms;
  }
  .p-select:hover {
    color: $ilabo;
  }

  .popup, .popup :deep(*) {
    outline: none;
  }

  .underscore {
    &:active {
      text-decoration: none;
    }

    &::after {
      content: "";
      position: absolute;
    }

    &::after {
      top: 90%;
      height: 2px !important;
      width: 0%;
      left: 50%;
      background-color: rgba(224, 224, 224, 0.6);
      transition: 0.4s ease-out all .1s;
    }
    &:hover::after {
      width: 90%;
      left: 5%;
      transition: 0.5s ease all;
    }
  }

  .plant-list {
    font-weight: 300;
    font-size: 18px;
  }

  .globe { cursor: pointer; }
  .globe:hover {
    color: $ilabo;
  }

  @media screen and (max-width: 768px) {
    .title {
      font-size: 1.1rem;
      line-height: 30px;
      letter-spacing: 0px;
    }
  }

</style>
