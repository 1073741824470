<template>
  <div class="header">
    <!-- eslint-disable -->
    <svg
      width="375"
      height="56"
      viewBox="0 0 375 56"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="header-wave"
    >
      <path
        v-if="!hideSecondaryWave"
        d="M90.7031 47.4217C55.1557 41.4217 260.34 30.9347 304.137 20L373.999 22.5V42C317.924 75.5 277.452 31.4217 231.891 43.9217C178.47 58.5782 126.251 53.4217 90.7031 47.4217Z"
        fill="#F2F2F2"
        stroke="#F2F2F2"
      />

      <path
        d="M0 42.2665V-1H375V40.5C375 40.5 337.951 46 273.365 40.5C208.778 35 131.885 48.623 83.8205 48.123C35.7564 47.623 0 42.2665 0 42.2665Z"
        :fill="color"
      />
    </svg>
    <!-- eslint-enable -->

    <div class="logo position-relative">
      <SystemSelect
        class="logix"
        :base-url="authUrl"
        :hub-url="hubUrl"
        :current="system"
        :token="token"
        style="max-width: 200px"
        @modules="$emit('modules', $event)"
      />
      <div class="plant-select text-center d-flex justify-content-center">
        <slot />
      </div>

      <slot name="right-menu">
        <RightMenu
          class="right-menu"
          :notification-action="notificationAction"
          :hide-list-button="hideListButton"
          :user-dropdown="userDropdown"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SystemSelect from '../../components/SystemSelect';
import RightMenu from './RightMenu';

export default {
  props: {
    authUrl: String,
    hubUrl: String,
    system: String,
    userDropdown: Array,
    color: String,
    notificationAction: Function,
    hideListButton: Boolean,
    hideSecondaryWave: Boolean,
  },
  components: {
    RightMenu,
    SystemSelect,
  },
  computed: {
    ...mapGetters('core', ['token']),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/vars.icss";

.logo {
  width: 100%;
  position: relative;
  min-height: 60px;
  text-align: left;
  background-repeat: no-repeat;
  background-size: 100% 90%;
  z-index: 1001;

  img.bg {
    min-height: 60px;
    position: absolute;
    min-width: 100%;
    z-index: 0;
    left: 0;
    top: 0;
  }

  .hamburger {
    position: absolute;
    right: 15px;
    top: 5px;
  }
}

.right-menu {
  position: absolute;
  right: 0;
  top: 0;
}

.navigation {
  position: absolute;
  top: 0px;
  height: 38px;
  right: 10px;
}

.header {
  width: 100%;
  position: relative;
  min-height: 60px;
  z-index: 1001;
}

.header {
  .header-wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    z-index: -1;
  }
}

.plant-select {
  position: absolute;
  top: 0;
  width: 100%;
}

.logo img {
  position: relative;
  z-index: 1;
}
</style>
