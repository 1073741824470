import i18n from '@/lang';

export const handleApiError = res => {
  let result = res;
  if (result.data) {
    result = { ...result.data };
  }

  if (result.message || result.title) {
    return {
      title: result.message ?? result.title,
      list: result.errors,
    };
  }
  if (result.errors) {
    return {
      title: i18n.t('error.listOfErrors'),
      list: result.errors,
    };
  }

  return {
    title: '',
    list: Object.entries(result),
  };
};

export default handleApiError;
