export const SYSTEM_ADMIN = 'SYSTEM_ADMIN';

export const PACKOS_PLANT_ADMIN = 'PACKOS_PLANT_ADMIN';
export const PACKOS_ELEMENT_ACCESS = 'PACKOS_ELEMENT_ACCESS';
export const PACKOS_DOWNTIME_CHANGE = 'PACKOS_DOWNTIME_CHANGE';
export const PACKOS_ORDER_MANAGEMENT = 'PACKOS_ORDER_MANAGEMENT';
export const PACKOS_ORDER_VIEW = 'PACKOS_ORDER_VIEW';
export const PACKOS_ORDER_LIFECYCLE = 'PACKOS_ORDER_LIFECYCLE';
export const PACKOS_ORDER_EDIT = 'PACKOS_ORDER_EDIT';
export const PACKOS_MATERIAL_MANAGEMENT = 'PACKOS_MATERIAL_MANAGEMENT';
export const PACKOS_SKU_LIST = 'PACKOS_SKU_LIST';
export const PACKOS_SKU_EDIT = 'PACKOS_SKU_EDIT';
export const PACKOS_STATES_MANAGEMENT = 'PACKOS_STATES_MANAGEMENT';
export const PACKOS_STATES_EDIT = 'PACKOS_STATES_EDIT';
export const PACKOS_REASON_ADD = 'PACKOS_REASON_ADD';
export const PACKOS_CORRECTIVE_ACTION_ADD = 'PACKOS_CORRECTIVE_ACTION_ADD';
export const PACKOS_PROBLEMS_EDIT = 'PACKOS_PROBLEMS_EDIT';
export const PACKOS_REPORT_ACCESS = 'PACKOS_REPORT_ACCESS';
export const PACKOS_FLOW = 'PACKOS_FLOW';
export const PACKOS_ELEMENTS_ORDER = 'PACKOS_ELEMENTS_ORDER';
export const PACKOS_UNITS = 'PACKOS_UNITS';
export const PACKOS_SHIFTS = 'PACKOS_SHIFTS';
export const PACKOS_SIGNALS = 'PACKOS_SIGNALS';
export const PACKOS_TARGETS_CONFIG = 'PACKOS_TARGETS_CONFIG';
export const PACKOS_MANUAL_PRODUCTION = 'PACKOS_MANUAL_PRODUCTION';
export const PACKOS_MANUAL_DOWNTIME = 'PACKOS_MANUAL_DOWNTIME';
export const PACKOS_DOWNTIME_MANAGEMENT = 'PACKOS_DOWNTIME_MANAGEMENT';
export const PACKOS_ORDER_COMPLETED_MANUALPRODUCTION = 'PACKOS_ORDER_COMPLETED_MANUALPRODUCTION';
export const PACKOS_ORDER_INPROGRESS_EDIT = 'PACKOS_ORDER_INPROGRESS_EDIT';
export const PACKOS_ORDER_COMPLETED_EDIT = 'PACKOS_ORDER_COMPLETED_EDIT';

export const CONFIG_TENANT_ADMIN = 'CONFIG_TENANT_ADMIN';
export const CONFIG_PLANT_ADMIN = 'CONFIG_PLANT_ADMIN';
export const CONFIG_USER_MANAGEMENT = 'CONFIG_USER_MANAGEMENT';
export const CONFIG_NOTIFICATIONS = 'CONFIG_NOTIFICATIONS';
export const CONFIG_LICENSE_TENANT_ACCESS = 'CONFIG_LICENSE_TENANT_ACCESS';
export const CONFIG_RELEASE_NOTES_ACCESS = 'CONFIG_RELEASE_NOTES_ACCESS';

export const KEEPUP_PLANT_ADMIN = 'KEEPUP_PLANT_ADMIN';
export const KEEPUP_ELEMENT_ACCESS = 'KEEPUP_ELEMENT_ACCESS';
export const KEEPUP_SERVICE_DEFINITION_MANAGEMENT = 'KEEPUP_SERVICE_DEFINITION_MANAGEMENT';
export const KEEPUP_ORDER_MANAGEMENT = 'KEEPUP_ORDER_MANAGEMENT';
export const KEEPUP_ORDER_VIEW = 'KEEPUP_ORDER_VIEW';
export const KEEPUP_REPORT_ACCESS = 'KEEPUP_REPORT_ACCESS';
export const KEEPUP_WRITE = 'KEEPUP_WRITE';
export const KEEPUP_READ = 'KEEPUP_READ';
export const KEEPUP_PLANT_ACCESS = 'KEEPUP_PLANT_ACCESS';

export const WORKBOOST_TENANT_ADMIN = 'WORKBOOST_TENANT_ADMIN';
export const WORKBOOST_TENANT_PROJECT_CREATE = 'WORKBOOST_TENANT_PROJECT_CREATE';
export const WORKBOOST_PROJECT_ADMIN = 'WORKBOOST_PROJECT_ADMIN';
export const WORKBOOST_PROJECT_VIEW = 'WORKBOOST_PROJECT_VIEW';
export const WORKBOOST_TEMPLATES_MANAGEMENT = 'WORKBOOST_TEMPLATES_MANAGEMENT';
export const WORKBOOST_PROJECT_QUESTS_CREATE = 'WORKBOOST_PROJECT_QUESTS_CREATE';
export const WORKBOOST_GROUP_ADMIN = 'WORKBOOST_GROUP_ADMIN';
export const WORKBOOST_GROUP_QUESTS_VIEW = 'WORKBOOST_GROUP_QUESTS_VIEW';
export const WORKBOOST_REPORT_ACCESS = 'WORKBOOST_REPORT_ACCESS';

export const LOGICPRINT5_LABELLING = 'LOGICPRINT5_LABELLING';
export const LOGICPRINT5_ORDERS = 'LOGICPRINT5_ORDERS';
export const LOGICPRINT5_SKU = 'LOGICPRINT5_SKU';
export const LOGICPRINT5_LABELS = 'LOGICPRINT5_LABELS';
export const LOGICPRINT5_REQUESTS = 'LOGICPRINT5_REQUESTS';
export const LOGICPRINT5_SETTINGS = 'LOGICPRINT5_SETTINGS';
export const LOGICPRINT5_SERIALIZATION_AND_AGGREGATION = 'LOGICPRINT5_SERIALIZATION_AND_AGGREGATION';

export const LOGICPRINT7_COCKPIT_MANAGEMENT = 'LOGICPRINT7_COCKPIT_MANAGEMENT';
export const LOGICPRINT7_COCKPIT_VIEW = 'LOGICPRINT7_COCKPIT_VIEW';
export const LOGICPRINT7_COCKPIT_OPERATIONS = 'LOGICPRINT7_COCKPIT_OPERATIONS';
export const LOGICPRINT7_COCKPIT_RESTRICTIONS = 'LOGICPRINT7_COCKPIT_RESTRICTIONS';
export const LOGICPRINT7_ORDERS_MANAGEMENT = 'LOGICPRINT7_ORDERS_MANAGEMENT';
export const LOGICPRINT7_ORDERS_VIEW = 'LOGICPRINT7_ORDERS_VIEW';
export const LOGICPRINT7_ORDERS_LIFECYCLE = 'LOGICPRINT7_ORDERS_LIFECYCLE';
export const LOGICPRINT7_ORDERS_CREATE = 'LOGICPRINT7_ORDERS_CREATE';
export const LOGICPRINT7_ORDERS_EDIT = 'LOGICPRINT7_ORDERS_EDIT';
export const LOGICPRINT7_SKU_MANAGEMENT = 'LOGICPRINT7_SKU_MANAGEMENT';
export const LOGICPRINT7_SKU_VIEW = 'LOGICPRINT7_SKU_VIEW';
export const LOGICPRINT7_SKU_EDIT = 'LOGICPRINT7_SKU_EDIT';
export const LOGICPRINT7_SKU_RESTRICTIONS = 'LOGICPRINT7_SKU_RESTRICTIONS';
export const LOGICPRINT7_PALLETS_MANAGEMENT = 'LOGICPRINT7_PALLETS_MANAGEMENT';
export const LOGICPRINT7_PALLETS_VIEW = 'LOGICPRINT7_PALLETS_VIEW';
export const LOGICPRINT7_PALLETS_LIFECYCLE = 'LOGICPRINT7_PALLETS_LIFECYCLE';
export const LOGICPRINT7_SETTINGS = 'LOGICPRINT7_SETTINGS';

export const GOODIN_PLANNING = 'GOODIN_PLANNING';
export const GOODIN_PRODUCTION = 'GOODIN_PRODUCTION';
export const GOODIN_ARCHIVE = 'GOODIN_ARCHIVE';
export const GOODIN_IMPORT_LOG = 'GOODIN_IMPORT_LOG';

export const KSM_OPERATOR = 'KSM_OPERATOR';
export const KSM_PRODUCTION_ASSISTANCE = 'KSM_PRODUCTION_ASSISTANCE';
export const KSM_ADMIN = 'KSM_ADMIN';
export const KSM_SERVICE = 'KSM_SERVICE';
