<template>
  <div class="d-flex justify-content-center">
    <div class="container">
      <div class="d-flex text-left mt-2">
        <h4>
          <i class="ion ion-md-paper pr-2" />
          {{ $t('pages.licenses') }}
        </h4>
      </div>
      <div>
        <LTable
          :list="sortedTenants"
          :columns="columns"
          :searchable="true"
        >
          <template #buttons="{ item }">
            <div class="text-right">
              <button
                class="btn btn-sm table-btn icon-btn mx-2"
                style="margin-bottom: -2px"
                @click="select(item)"
              >
                <i class="ion ion-md-create" />
              </button>
            </div>
          </template>
        </LTable>
      </div>
      <LModal
        :show.sync="licenseForm"
      >
        <LicensesForm
          v-if="licenseForm"
          :tenant="tenant"
          @close="licenseForm = false"
        />
      </LModal>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import LicensesForm from '@/pages/licenses/LicensesForm';
import LTable from '@core/components/LTable';

export default {
  data: () => ({
    search: '',
    list: [],
    tenant: null,
    licenseForm: false,
  }),
  components: {
    LTable,
    LicensesForm,
  },
  computed: {
    ...mapGetters('core', ['can']),
    ...mapGetters(['tenantId']),
    ...mapState({
      tenants: state => state.tenant.tenants || [],
    }),
    sortedTenants() {
      return [...this.tenants]
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    canAdd() {
      return this.can(this.$perm.CONFIG_TENANT_ADMIN, this.tenantId);
    },
    columns() {
      return [
        {
          key: 'name',
          text: this.$t('general.name'),
        },
        {
          key: 'product',
          text: this.$t('pages.products'),
        },
        {
          key: 'buttons',
          text: '',
        },
      ];
    },
  },
  methods: {
    select(element) {
      if (!element) {
        this.tenant = null;
      } else {
        this.tenant = element;
      }
      this.licenseForm = true;
    },
  },
};
</script>
