<template>
  <div class="taxonomy-info-form">
    <BFormGroup
      :label="$t('taxonomies.classificationTitle')"
      label-class="font-weight-bold"
    >
      <BInput
        v-model="innerDescription"
        :disabled="descriptionInputDisabled"
        :placeholder="$t('taxonomies.enterDescription')"
      />
    </BFormGroup>

    <PlantSelect
      v-model="innerNamespaceId"
      :label="$t('general.plant')"
      label-class="font-weight-bold"
      :disabled="namespaceIdInputDisabled"
    />

    <BFormGroup
      :label="$t('general.code')"
      label-class="font-weight-bold"
    >
      <BInput
        v-model="innerCode"
        :placeholder="$t('taxonomies.enterCode')"
        :disabled="codeInputDisabled"
      />
    </BFormGroup>

    <BFormGroup
      label-class="font-weight-bold"
      :label="$t('taxonomies.classificationFor')"
    >
      <BFormCheckboxGroup v-model="innerTags">
        <BFormCheckbox :value="taxonomyTag.workEvent">
          {{ $t('taxonomies.problems') }}
        </BFormCheckbox>
        <BFormCheckbox :value="taxonomyTag.element">
          {{ $t('taxonomies.factoryStructure') }}
        </BFormCheckbox>
      </BFormCheckboxGroup>
    </BFormGroup>
  </div>
</template>

<script>
import PlantSelect from '@/components/plants/PlantSelect';
import { taxonomyTag } from '@/utils/dictionary';

export default {
  name: 'TaxonomyInfoForm',
  props: {
    description: {
      type: String,
      default: '',
    },
    namespaceId: {
      type: String,
      default: '',
    },
    code: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: () => [],
    },
    descriptionInputDisabled: Boolean,
    namespaceIdInputDisabled: Boolean,
    codeInputDisabled: Boolean,
  },
  data: () => ({
    taxonomyTag,
  }),
  components: { PlantSelect },
  computed: {
    innerDescription: {
      get() {
        return this.description;
      },
      set(v) {
        this.$emit('update:description', v);
      },
    },
    innerCode: {
      get() {
        return this.code;
      },
      set(v) {
        this.$emit('update:code', v);
      },
    },
    innerNamespaceId: {
      get() {
        return this.namespaceId;
      },
      set(v) {
        this.$emit('update:namespaceId', v);
      },
    },
    innerTags: {
      get() {
        return this.tags;
      },
      set(v) {
        this.$emit('update:tags', v);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.taxonomy-info-form :deep() {
  .custom-control-input {
    box-shadow: unset !important;
  }
  .custom-control-input:checked ~ .custom-control-label{
   &:before {
     border-color: #88AD41;
     background-color: #88AD41;
   }
  }
}
</style>
