export default list => list
  .filter(x => x)
  .map(x => {
    try {
      let error = null;

      try {
        error = JSON.parse(x.resultMessage);
      } catch {
        //
      }

      return {
        ...JSON.parse(x.entity || x.request),
        error,
        importResult: x,
      };
    } catch {
      //
    }
    return {
      importResult: x,
    };
  })
  .filter(x => x);
