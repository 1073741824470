<template>
  <div class="layout d-flex flex-column">
    <Header
      :auth-url="authUrl"
      system="hub"
      :color="$color('hub')"
      :hide-list-button="true"
      @modules="$store.commit('core/setModules', $event)"
    >
      <div
        class="plant-select text-center d-flex justify-content-center"
      >
        <UnderscoreSelect
          :list="sortedTenants"
          :selected="tenantId"
          @selected="setTenant"
        />
      </div>
    </Header>
    <div class="d-flex flex-grow-1">
      <Sidebar :page="page" />
      <div class="content flex-grow-1 container-fluid pt-2 px-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Header from '@core/layout/navbar/Header';
import UnderscoreSelect from '@core/components/UnderscoreSelect';
import Sidebar from '@/layout/Sidebar';
import getOidcSettings from '@/api/oidc';

export default {
  props: {
    page: String,
  },
  data: () => ({
    authUrl: `${getOidcSettings().authority}/api`,
  }),
  components: {
    UnderscoreSelect,
    Header,
    Sidebar,
  },
  computed: {
    ...mapGetters(['tenantId']),
    ...mapGetters('oidcStore', ['oidcIsAuthenticated']),
    ...mapState({
      tenants: state => state.tenant.tenants || [],
    }),
    sortedTenants() {
      return [...this.tenants]
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {
    oidcIsAuthenticated() {
      this.loadTenants();
    },
  },
  methods: {
    ...mapActions(['getTenants']),
    setTenant(id) {
      this.$router.push(`/${id}/account`);
    },
    loadTenants() {
      if (!this.oidcIsAuthenticated) return;
      this.getTenants();
    },
  },
  created() {
    this.loadTenants();
  },
};
</script>

<style lang="scss" scoped>

  .layout {
    background-color: #F5F5F5;
  }

  .plant-select :deep(.title) {
    font-size: 1.3rem;
  }

  .plant-select {
    position: absolute;
    top: 9px;
    width: 100%;
    padding-left: 80px;
  }

@media screen and (min-width: 1000px) {
  .layout {
    min-height: 100vh;
  }
}

</style>
