/** EXAMPLE USAGE:
 * const makeSearch = search(this.input);
 * list.filter(x => makeSearch(x.name));
*/

const normalize = str =>
  str.normalize('NFKD')
    .replace(/\u0142/g, 'l')
    .replace(/[\u0300-\u036F]/g, '');

const escapeRegExp = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export default search => {
  if (!search) return () => true;
  const regex = new RegExp(normalize(escapeRegExp(search)), 'gi');
  return str => str && normalize(str.toString()).search(regex) !== -1;
};

export const dropdownSearch = (text, inputText, searchErrors) => {
  const inp = inputText.trim().replace(/\s\s+/g, ' ').split(' ');

  return inp.every(x => {
    try {
      return text.match(new RegExp(x, 'i'));
    } catch (e) {
      searchErrors.push(e);
      return true;
    }
  });
};
