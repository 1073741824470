<template>
  <div>
    <PushStatus
      v-if="serviceWorker"
      :show="show"
      class="py-2"
      :hide-cancel="true"
    />
    <hr
      v-if="serviceWorker"
      class="mt-0"
    >
    <div v-if="!hideListButton">
      <button
        class="btn btn-sm btn-block btn-outline-ilabo mt-1"
        @click="showList"
      >
        <i class="ion ion-ios-list pr-2" />
        {{ $t('notify.list') }}
      </button>
    </div>
    <div
      v-if="composed && composed.length > 0"
      v-prevent-parent-scroll
      class="dropdown-wrapper"
      :class="{ 'mt-2': !hideListButton }"
      @scroll.stop="onScroll"
    >
      <div
        v-for="n in composed"
        :key="n.id"
        class="notification pt-1"
        :class="{ clickable: !!(notificationAction && notificationAction(n)) }"
        @click.stop="executeAction(notificationAction, n)"
      >
        <div class="box">
          <div class="d-flex">
            <div class="notification-icon align-self-center text-center">
              <i :class="getIcon(n)" />
            </div>
            <div class="notification-content text-left">
              <div
                class="pt-1"
                style="font-size: 10px; font-weight: bold; line-height: 1;"
              >
                {{ plantName(n.plantCode) }}
              </div>
              <div>{{ n.title }}</div>
              <div class="small text-secondary">
                {{ n.created | format }}
              </div>
            </div>
          </div>
          <div
            class="text-secondary details text-left"
          >
            {{ n.details }}
          </div>
          <i
            v-if="!n.read"
            class="fas fa-circle unread text-ilabo"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="!pending"
      class="text-center"
    >
      {{ $t('notify.nothing') }}
    </div>

    <div v-if="pending">
      <Loader size="20px" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import infiniteScroll from 'vue-infinite-scroll';
import preventParentScroll from 'vue-prevent-parent-scroll';
import PushStatus from './PushStatus';

Vue.use(preventParentScroll);
Vue.use(infiniteScroll);

export default {
  props: {
    hideListButton: Boolean,
    notificationAction: Function,
    show: Boolean,
  },
  data: () => ({
    skip: 0,
    take: 10,
    news: [],
    allNews: [],
    pending: false,
    nothingMore: false,
    unread: 0,
  }),
  filters: {
    format(data) {
      return moment(data * 1000).format('HH:mm DD MMM');
    },
  },
  components: {
    PushStatus,
  },
  computed: {
    ...mapGetters('core', ['plantName', 'serviceWorker']),
    composed() {
      return this.allNews
        .reduce((acc, curr) => {
          const index = acc.findIndex(el => el.id === curr.id);
          if (index === -1) {
            acc.push(curr);
          } else if (acc[index].read) {
            acc[index] = curr;
          }
          return acc;
        }, [])
        .sort((a, b) => {
          if (a.read !== b.read) {
            return a.read - b.read;
          }
          return b.created - a.created;
        });
    },
  },
  watch: {
    unread(n) {
      this.$emit('number', n);
    },
    show(v) {
      if (v) {
        this.skip = 0;
        this.allNews = [];
        this.nothingMore = false;
        this.requestHistory(0);
        this.mark();
      }
    },
  },
  methods: {
    ...mapActions('core', ['getNotifications', 'markAsRead']),
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (this.pending) return;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.loadMore();
      }
    },
    executeAction(action, n) {
      if (!action || !action(n)) return;
      action(n)();
      this.$emit('close');
    },
    getIcon(n) {
      switch (n.type) {
        case 'StartJob':
          return 'icon-orders';
        case 'StopJob':
          return 'fas fa-check-circle';
        default:
          return 'ion ion-ios-notifications';
      }
    },
    showList() {
      this.$store.dispatch('core/showNotifications', null, { root: true });
      this.$emit('close');
    },
    loadMore() {
      this.skip += this.take;
      this.requestHistory(this.skip);
    },
    mark() {
      if (!this.news || this.news.length === 0) return;
      const ids = this.news.map(n => n.id);
      if (ids.length === 0) return;
      this.markAsRead({
        data: {
          read: 1,
          notificationsIds: ids,
        },
      })
        .then(() => {
          this.unread = 0;
        });
    },
    requestUnread() {
      const utcOffset = -(moment().utcOffset());

      return this.getNotifications({
        params: {
          utcOffset,
          query: {
            skip: 0,
            take: 10,
            unreadOnly: 1,
          },
        },
      })
        .then(({ data }) => {
          this.unread = data.length;
          this.news = data;
        });
    },
    requestHistory(skip) {
      this.pending = true;
      const utcOffset = -(moment().utcOffset());
      return this.getNotifications({
        params: {
          utcOffset,
          query: {
            skip,
            take: this.take,
            unreadOnly: 0,
          },
        },
      })
        .then(({ data }) => {
          if (data.length < this.take) {
            this.nothingMore = true;
          } else {
            this.nothingMore = false;
          }
          this.pending = false;
          this.allNews = this.allNews.concat(data);
          this.mark();
        });
    },
  },
  created() {
    this.requestUnread();
  },
};
</script>

<style lang="scss" scoped>

  .dropdown-wrapper {
    max-height: 600px;
    min-width: 500px;
    max-width: 500px;
    overflow-y: auto;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -10px;
    margin-right: -10px;
  }

  .notification-icon {
    width: 30px;
    font-size: 22px;
    padding-right: 7px;
  }

  .notification {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0 5px;

    &.clickable {
      cursor: pointer;

      .box:hover {
        box-shadow: 0 0 4px rgba(100, 100, 100, 0.5);
        transform: translateX(2px);
      }
    }

    .box {
      position: relative;
      padding: 2px 7px;
      transition: box-shadow 400ms, transform 300ms;
      box-shadow: 0 0 3px rgba(100, 100, 100, 0.2);
    }

    .unread {
      position: absolute;
      font-size: 9px;
      top: -2px;
      right: -2px;
    }

    .details {
      font-size: 12px;
      line-height: 1.1;
      padding: 5px 10px;
    }
  }

</style>
