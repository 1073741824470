<template>
  <div>
    <div class="h4">
      <i class="ion ion-md-paper pr-2" />

      <span class="name">
        {{ elementName(lineId) }} - {{ $t('licenses.machineLicenses').toLowerCase() }}
      </span>
    </div>
    <MachineLicenseRow
      v-for="machine in machineLicensesWithNames"
      :key="machine.elementId"
      style="margin: 20px"
      :machine-license-data="machine"
    />
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import MachineLicenseRow from './MachineLicenseRow';

export default {
  props: {
    lineId: {
      type: String,
      required: true,
    },
    machineLicenses: {
      type: Array,
      required: true,
    },
  },
  components: {
    MachineLicenseRow,
  },
  computed: {
    ...mapGetters(['elementName']),
    machineLicensesWithNames() {
      return this.machineLicenses
        .map(x => ({
          ...x,
          machineName: this.elementName(x.elementId),
        }))
        .sort((a, b) =>
          a.machineName.localeCompare(b.machineName));
    },
  },
};
</script>
<style>
</style>
