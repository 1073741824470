<template>
  <div>
    <div>
      <div
        v-if="elementType === 'Line'"
        class="mt-2"
      >
        <BFormCheckbox
          :checked="value.activities === 'true'"
          name="check-button"
          switch
          @change="update('activities', $event)"
        >
          {{ $t('elements.capability.activities') }}
        </BFormCheckbox>
      </div>
      <div class="mt-2">
        <BFormCheckbox
          :checked="value.downtimes === 'true'"
          name="check-button"
          switch
          @change="update('downtimes', $event)"
        >
          {{ $t('elements.capability.downtimes') }}
        </BFormCheckbox>
      </div>
      <div class="mt-2">
        <BFormCheckbox
          :checked="value.production === 'true'"
          name="check-button"
          switch
          @change="update('production', $event)"
        >
          {{ $t('elements.capability.production') }}
        </BFormCheckbox>
      </div>
      <div class="mt-2">
        <BFormCheckbox
          :checked="value.oee === 'true'"
          switch
          @change="update('oee', $event)"
        >
          {{ $t('elements.capability.oee') }}
        </BFormCheckbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    elementType: String,
  },
  methods: {
    update(property, value) {
      this.$emit('input', {
        ...this.value,
        [property]: value.toString(),
      });
    },
  },
};
</script>
