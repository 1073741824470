import axios from 'axios';
import { paramsSerializer, setHeaders } from './axiosSetup';

export const hub = axios.create({ paramsSerializer });
export const notify = axios.create({ paramsSerializer });

export const init = store => {
  setHeaders(hub, store);
  setHeaders(notify, store);
};
