<template>
  <div class="container pl-0 pr-0">
    <div
      class="text-left mt-2 mb-2"
    >
      <div class="row">
        <div class="col-6 ">
          <h6>{{ $t('role.chooseRole') }}</h6>
          <div class="border-box">
            <div
              v-for="(itemRole, index) in allRolesSorted"
              :key="index"
              class="big-box hoverable flex-grow-1 position-relative"
              @click="toggleRoleSelect(itemRole)"
            >
              <div class="pr-2 d-flex align-items-center">
                {{ itemRole.name }}
              </div>
              <div
                v-if="roleIsSelected(itemRole.id)"
                class="v-center position-absolute"
                style="right: 8px; top: 50%; transform: translateY(-50%)"
              >
                <i class="ion ion-md-checkmark" />
              </div>

              <div class="d-flex flex-wrap">
                <div
                  v-for="p in assignedPermissionsWithSubjectNames.filter(x => x.subjectId === itemRole.id)"
                  :key="`${p.name}_${p.value}`"
                  v-tippy="{ arrow: true, trigger: p.inherited ? 'mouseenter' : 'manual' }"
                  :content="`${$t('permissions.inheritedFrom')} ${p.inherited}`"
                  class="badge text-uppercase mr-1 mt-1"
                  style="font-size: 10px; opacity: 0.6"
                  :class="p.inherited ? 'badge-outline-secondary' : 'badge-secondary'"
                  :style="p.inherited ? 'border: 1px solid' : ''"
                >
                  {{ p.name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="selectedRole"
          class="col-6"
        >
          <div v-if="!selectedPlantId && (shouldSelectElement || shouldSelectLine)">
            <h6>{{ $t('permissions.choosePlant') }}</h6>
            <div class="border-box">
              <ListSelector
                :list="filteredPlants"
                @selected="selectedPlantId = $event"
              />
            </div>
          </div>

          <div v-else-if="!selectedTenantId && shouldSelectGroup">
            <h6>{{ $t('permissions.chooseTenant') }}</h6>
            <div class="border-box">
              <ListSelector
                :list="filteredTenants"
                @selected="selectedTenantId = $event"
              />
            </div>
          </div>

          <div v-else>
            <h6>
              <span v-if="shouldSelectElement || shouldSelectLine">
                <span
                  v-if="filteredPlants.length > 1"
                  @click="selectedPlantId = null"
                >
                  <i class="zoom-hoverable ion ion-ios-arrow-dropleft-circle" />
                </span>
                {{ $t('permissions.chooseAsset') }}
              </span>
              <span v-else-if="shouldSelectGroup">
                <span
                  v-if="filteredTenants.length > 1"
                  @click="selectedTenantId = null"
                >
                  <i class="zoom-hoverable ion ion-ios-arrow-dropleft-circle" />
                </span>
                {{ $t('permissions.chooseGroup') }}
              </span>
              <span v-else-if="shouldSelectTenant">
                {{ $t('permissions.chooseTenant') }}
              </span>
              <span v-else>
                {{ $t('permissions.choosePlant') }}
              </span>
            </h6>

            <div class="border-box">
              <div v-if="!selectedRolePermissionNamesPending">
                <TreeTablePermissions
                  v-if="shouldSelectElement || shouldSelectLine"
                  :assigned-permissions="assignedPermissionsFiltered"
                  class="tree-lg"
                  :pending="pending"
                  :list="plantElements"
                  :root="selectedPlant"
                  :selectable="true"
                  :is-expanded="false"
                  :names-of-permission-being-modified="selectedRolePermissionNames"
                  @select="toggleElement($event, 'PlantId')"
                  @root-changed="rootChanged($event)"
                />
                <TreeTablePermissions
                  v-else-if="shouldSelectTenant"
                  class="tree-lg"
                  :assigned-permissions="assignedPermissionsFiltered"
                  :pending="pending"
                  :list="filteredTenants.map(x => ({ ...x, parentId: '0' }))"
                  :root="{ id: '0' }"
                  :selectable="true"
                  :padding="0"
                  :hide-root="true"
                  :is-expanded="false"
                  :names-of-permission-being-modified="selectedRolePermissionNames"
                  @select="toggleElement($event, 'TenantId')"
                  @root-changed="rootChanged($event)"
                />
                <TreeTablePermissions
                  v-else-if="shouldSelectGroup"
                  :assigned-permissions="assignedPermissionsFiltered"
                  class="tree-lg"
                  :pending="pending"
                  :list="selectedTenantRoles"
                  :root="selectedTenant"
                  :selectable="true"
                  :is-expanded="false"
                  :names-of-permission-being-modified="selectedRolePermissionNames"
                  @select="toggleElement($event, 'TenantId')"
                  @root-changed="rootChanged($event)"
                />
                <TreeTablePermissions
                  v-else
                  class="tree-lg"
                  :assigned-permissions="assignedPermissionsFiltered"
                  :pending="pending"
                  :list="filteredPlants.map(x => ({ ...x, parentId: '0' }))"
                  :root="{ id: '0' }"
                  :selectable="true"
                  :padding="0"
                  :hide-root="true"
                  :is-expanded="false"
                  :names-of-permission-being-modified="selectedRolePermissionNames"
                  @select="toggleElement($event, 'PlantId')"
                  @root-changed="rootChanged($event)"
                />
              </div>
              <div v-else>
                <Loader />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-3" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { handleApiError } from '@/utils/handleApiError';
import ListSelector from '@/components/utils/ListSelector';
import TreeTablePermissions from '@/components/tables/TreeTablePermissions';
import { elementType } from '@/utils/dictionary';

export default {
  props: {
    user: Object,
    roleId: String,
  },
  data: () => ({
    selectedPlantId: null,
    selectedRole: null,
    pending: {},
    roles: [],
    error: null,
    localRoles: [],
    assignedPermissions: [],
    selectedTenantId: null,
    selectedRolePermissionNames: [],
    selectedRolePermissionNamesPending: false,
  }),
  components: {
    TreeTablePermissions,
    ListSelector,
  },
  computed: {
    ...mapState({
      plants: state => state.structure.plants || [],
      tenantRoles: state => state.role.roles || [],
      allRoles: state => state.role.roles || [],
      elements: state => state.structure.elements || [],
      tenants: state => state.tenant.tenants || [],
    }),
    ...mapGetters('core', ['can']),
    ...mapGetters(['tenantId', 'usersOnly']),
    allRolesSorted() {
      return this.allRoles.filter(x => x.subjectType != null).sort((a, b) => a.name.localeCompare(b.name));
    },
    filteredPlants() {
      return this.plants.filter(x => this.can(this.$perm.CONFIG_PLANT_ADMIN, x.id));
    },
    filteredTenants() {
      return this.tenants
        .filter(x => this.can(this.$perm.CONFIG_USER_MANAGEMENT, x.id)
          || this.can(this.$perm.CONFIG_TENANT_ADMIN, x.id));
    },
    assignedPermissionsFiltered() {
      return this.selectedRole !== null ? this.assignedPermissions
        .filter(x => x.subjectId === this.selectedRole.id) : null;
    },
    assignedPermissionsWithSubjectNames() {
      return this.assignedPermissions
        .map(x => ({
          ...x,
          name: this.findName(x.subjectType, x.value) || '',
        }))
        .filter(x => x.name);
    },
    selectedTenantRoles() {
      return this.allRoles
        .map(x => ({ ...x, parentId: x.tenantId }))
        .filter(x => x.tenantId === this.selectedTenantId);
    },
    role() {
      if (!this.roleId) return null;
      return this.tenantRoles.find(x => x.id === this.roleId);
    },
    selectedPlant() {
      return this.filteredPlants.find(x => x.id === this.selectedPlantId);
    },
    selectedTenant() {
      return this.filteredTenants.find(x => x.id === this.selectedTenantId);
    },
    plantElements() {
      const plantElements = this.elements
        .filter(x => x.plantId === this.selectedPlantId);

      const lines = plantElements.filter(x => x.type.toLowerCase() === elementType.line.toLowerCase());

      const getParents = (allElements, children) => {
        if (children.length === 0) return [];

        const parentDict = children
          .map(c => allElements.find(x => x.id === c.parentId))
          .filter(x => x)
          .reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
          }, {});

        const parents = Object.values(parentDict);

        return parents.concat(getParents(allElements, parents));
      };

      return lines.concat(getParents(plantElements, lines));
    },
    shouldSelectElement() {
      return this.selectedRole !== null && this.selectedRole.subjectType.toLowerCase() === 'elementid';
    },
    shouldSelectGroup() {
      return this.selectedRole !== null && this.selectedRole?.subjectType.toLowerCase() === 'groupid';
    },
    shouldSelectTenant() {
      return this.selectedRole !== null && this.selectedRole?.subjectType.toLowerCase() === 'tenantid';
    },
    shouldSelectLine() {
      return this.selectedRole !== null && this.selectedRole?.subjectType.toLowerCase() === 'lineid';
    },
  },
  watch: {
    selectedRole() {
      if (!this.selectedRole) {
        this.selectedRolePermissionNames = [];
        return;
      }
      this.selectedRolePermissionNamesPending = true;
      this.getRolePermission({
        params: {
          roleId: this.selectedRole.id,
        },
      })
        .then(({ data }) => {
          this.selectedRolePermissionNames = data ?? [];
        })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.selectedRolePermissionNamesPending = false;
        });
    },
  },
  methods: {
    ...mapActions([
      'getUser',
      'setUserRolePermission',
      'deleteRolePermission',
      'getAssignedRolePermissions',
      'getRolePermission',
    ]),
    findName(subjectType, id) {
      switch (subjectType.toLowerCase()) {
        case 'plantid':
          return this.plants.find(x => x.id === id)?.name;
        case 'lineid': {
          const element = this.elements.concat(this.plants).find(x => x.id === id);
          if (!element) return '';

          if (element.plantId && this.plants.length > 1) {
            const plant = this.plants.find(x => x.id === element.plantId);

            if (plant && plant.id !== element.id) {
              return `${plant.name} / ${element.name}`;
            }

            if (plant) {
              return plant.name;
            }

            return element.name;
          }
          return element.name;
        }
        case 'tenantid':
          return this.tenants.find(x => x.id === id)?.name;
        default:
          return '';
      }
    },
    roleIsSelected(roleId) {
      if (this.selectedRole != null) {
        return this.selectedRole.id === roleId;
      }
      return false;
    },
    toggleRoleSelect(newRole) {
      if (this.selectedRole === newRole) { this.selectedRole = null; } else { this.selectedRole = newRole; }
    },
    toggleElement(selected, rootType) {
      if (!selected) return;
      this.error = null;
      if (this.elementIsAdded(selected.id)) {
        this.addRolePermission(selected, rootType);
      } else {
        this.removeRolePermission(selected.id);
      }
    },
    elementIsAdded(elementId) {
      return this.assignedPermissions
        .find(perm => perm.value.toLowerCase() === elementId && perm.subjectId === this.selectedRole.id) === undefined;
    },
    addRolePermission(selected, rootType) {
      const rootId = rootType === 'TenantId'
        ? (this.selectedTenantId || selected.id)
        : (this.selectedPlantId || selected.id);

      const newPerm = {
        roleId: this.selectedRole.id,
        userId: this.user.id,
        rootId,
        rootType,
        subjectId: selected.id,
      };
      this.assignedPermissions.push({
        rootId,
        subjectId: this.selectedRole.id,
        subjectType: this.selectedRole.subjectType,
        value: selected.id,
      });
      return this.setUserRolePermission({
        data: newPerm,
      })
        .then(() => {
          this.getUser({ params: { userId: this.user.id } });
        })
        .catch(({ response }) => {
          this.error = handleApiError(response);
        })
        .finally(() => {
          this.$set(this.pending, false);
        });
    },
    removeRolePermission(elementId) {
      return this.deleteRolePermission({
        data: {
          userId: this.user.id,
          roleId: this.selectedRole.id,
          elementId,
        },
      })
        .then(({ data }) => {
          this.assignedPermissions = this.assignedPermissions
            .filter(x => !(x.subjectId === data.roleId && x.value === data.elementId));
        })
        .then(() => {
          this.getUser({ params: { userId: this.user.id } });
        })
        .catch(({ response }) => {
          this.error = handleApiError(response);
        })
        .finally(() => {
          this.$set(this.pending, elementId, false);
        });
    },
  },
  created() {
    this.localRoles = this.user.roles || [];
    this.getAssignedRolePermissions({
      params: {
        userId: this.user.id,
        query: {
          subjectTypes: this.usersOnly
            ? ['tenantid']
            : ['tenantid', 'plantid', 'lineid'],
        },
      },
    })
      .then(({ data }) => {
        this.assignedPermissions = data || [];
      });
  },
};
</script>

<style lang="scss" scoped>
.big-box {
  box-shadow: 0 2px 3px #efefef, 0 2px 3px #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 10px 0px;
  margin-top: 3px;
  line-height: 1.1;
  text-align: left;
  background-color: #fff;
  border: 1px solid transparent;
  font-size: 0.9rem;
  cursor: pointer;
}
  .tree-table-row-permissions{
    margin-top: 5px !important;
    margin-left: -40px !important;
  }

  .border-box {
    background-color: rgb(245, 245, 245);
    padding: 10px;
    border-radius: 10px;
  }

  .zoom-hoverable {
    cursor: pointer;

    &:hover {
      transform: scale(1.5);
    }
  }
</style>
