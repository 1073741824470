<template>
  <div
    class="px-1 d-flex flex-column"
    style="max-height: 100%"
  >
    <div class="row align-items-center mb-2">
      <div
        class="col-12 col-lg-3 position-relative"
        style="height: 1rem"
      >
        <slot name="actions" />

        <div
          class="results position-absolute text-lowercase"
          style="top: 3px; right: 10px"
        >
          <span
            v-if="checkedList.length > 0"
            class="font-weight-bold text-ilabo"
          >
            {{ checkedList.length }}
            {{ $t('general.selected') }} |
          </span>
          {{ filteredAndSorted.length }} {{ $t('general.results') }}
        </div>
      </div>
      <div
        v-if="searchable"
        class="col-12 col-lg-3 justify-content-between"
      >
        <BFormGroup
          :label="`${$t('general.search')}`"
          class="small"
        >
          <BFormInput
            ref="search"
            v-model="search"
            :placeholder="`${$t('general.search')}...`"
            :state="searchErrors.length === 0 ? null : false"
            class="search"
            size="sm"
            type="search"
            @click="$refs.search.select()"
            @input="resetScroll"
          />
        </BFormGroup>
      </div>
      <div
        v-else
        class="col-12 col-lg-3"
      />
      <div
        class="col-12 col-lg-3 justify-content-between"
      >
        <slot name="filters" />
      </div>
      <div class="col-12 col-lg-3" />
    </div>

    <div class="list-content flex-grow-1 mt-3">
      <div
        class="w-100"
        style="min-width: 500px;"
      >
        <div
          :class="{ selectable }"
          class="d-flex font-weight-bold pb-1 column-names align-items-center px-2"
        >
          <div
            v-if="selectable"
            v-tippy="{ arrow: true }"
            :content="$t('general.selectAll')"
            class="pl-1"
          >
            <BFormCheckbox
              v-model="checkAll"
            />
          </div>
          <div
            v-for="c in columns"
            :key="c.key"
            :class="[c.width ? '' : 'col', isMobile && 'px-1', c.key === 'edit' && 'col-1' ]"
            :style="{
              width: c.key !== 'edit' && c.width ? c.width : columnSize + '%',
              minWidth: c.key !== 'edit' && c.width ? c.width : columnSize + '%',
            }"
            class="d-flex no-gutters"
            style="padding-right: 5px; flex-shrink: 0"
          >
            <div
              v-if="c.sortable"
              class="pr-1"
              @click="toggleSort(c.key)"
            >
              <i
                v-if="sort[c.key] === 'up'"
                class="ion ion-md-arrow-dropup"
              />
              <i
                v-else-if="sort[c.key] === 'down'"
                class="ion ion-md-arrow-dropdown text-dark"
              />
            </div>
            <div
              :class="c.columnClass || {}"
              class="column-title"
              @click="toggleSort(c.key)"
            >
              {{ c.text }}
            </div>
            <div
              v-if="c.groupable"
              class="group-icon ml-2"
              @click="toggleGroup(c.key)"
            >
              <i
                v-if="groupBy !== c.key"
                class="ion ion-md-folder"
              />
              <span
                v-else
                class="position-relative text-dark"
              >
                <i class="ion ion-md-folder-open" />
                <i
                  class="ion ion-md-close"
                  style="position: absolute; font-size: 9px; top: -1px; right: -2px"
                />
              </span>
            </div>
          </div>
        </div>
        <div
          v-infinite-scroll="loadMore"
          infinite-scroll-disabled="scrollBusy"
          infinite-scroll-distance="10"
        >
          <div
            v-for="(g, key) in groups"
            :key="g.id"
          >
            <div
              v-if="key"
              class="text-left font-weight-bold pt-2"
              style="font-size: 12px"
            >
              {{ key }}
            </div>
            <div
              :class="key ? 'pl-2' : ''"
            >
              <div
                v-for="(el, i) in g"
                :key="el.id"
                class="d-flex align-items-center"
              >
                <slot
                  :element="el"
                  :odd="i % 2 === 0"
                >
                  <div
                    v-if="selectable"
                    class="pl-1"
                  >
                    <BFormCheckbox
                      v-model="check[el.id]"
                    />
                  </div>
                  <div
                    class="flex-grow-1"
                    @click="$emit('select', el)"
                  >
                    <div
                      :class="{ hoverable, odd: i % 2 === 0 }"
                      class="d-flex table-row"
                    >
                      <div
                        v-for="c in columns"
                        :key="c.key"
                        :class="[c.width ? '' : 'col', isMobile && 'px-1', c.key === 'edit' && 'col-1' ]"
                        :style="{
                          width: c.key !== 'edit' && c.width
                            ? c.width
                            : columnSize + '%',
                          minWidth: c.key !== 'edit' && c.width
                            ? c.width
                            : columnSize + '%',
                          'font-size': isMobile && '11px',
                        }"
                      >
                        <slot
                          :item="el"
                          :name="c.key"
                        >
                          {{ el[c.key] }}
                        </slot>
                      </div>
                    </div>
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import createSearch, { dropdownSearch } from '../utils/search';

const initShow = 40;

export default {
  props: {
    searchable: Boolean,
    searchableFocus: {
      type: Boolean,
      default: () => true,
    },
    selectable: Boolean,
    columns: {
      type: Array,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    selected: Object,
    hoverable: Boolean,
    defaultGroup: String,
    defaultSort: String,
    useAdvancedSearch: Boolean,
    sortBy: {
      type: Object,
      default: () => {
      },
    },
  },
  data: () => ({
    search: '',
    sort: {},
    groupBy: '',
    check: {},
    show: initShow,
    scrollBusy: false,
    searchErrors: [],
  }),
  computed: {
    ...mapGetters(['isMobile']),
    checkAll: {
      get() {
        return false;
      },
      set(v) {
        if (v) {
          this.filteredAndSorted.forEach(({ id }) => {
            this.$set(this.check, id, true);
          });
        } else {
          this.list.forEach(({ id }) => {
            this.$set(this.check, id, false);
          });
        }
      },
    },
    checkedList() {
      return Object.keys(this.check).filter(x => this.check[x]);
    },
    filteredAndSorted() {
      const sortNum = Object.keys(this.sort)
        .filter(x => this.sort[x] === 'up' || this.sort[x] === 'down')
        .length;

      const defaultSort = this.defaultSort || this.columns[0].key;
      const sortBy = sortNum > 0 ? this.sort : { [defaultSort]: 'down' };

      const sortString = (key, a, b) => {
        if (!sortBy[key]) return 0;
        if (typeof a[key] === 'number') {
          return a[key] - b[key];
        }
        if (a[key] === null || a[key] === undefined) return 0;
        return a[key].toString().localeCompare(b[key]);
      };

      const list = (this.list || []).slice()
        .sort((a, b) => {
          // Find first column with different value
          const c = this.columns.find(({ key }) => sortString(key, a, b));
          const key = c ? c.key : defaultSort;

          if (sortBy[key] === 'down') {
            return sortString(key, a, b);
          }
          return -sortString(key, a, b);
        });

      if (this.search) {
        if (this.useAdvancedSearch) {
          // eslint-disable-next-line
          this.searchErrors = [];
          return list.filter(l => this.columns
            .filter(x => !x.noSearch)
            .some(({ key }) =>
              dropdownSearch(l[key] || '', this.search || '', this.searchErrors)));
        }

        const search = createSearch(this.search);
        return list
          .filter(x => !x.noSearch)
          .filter(l => this.columns.some(({ key }) => search(l[key])));
      }

      return list;
    },
    groups() {
      if (!this.filteredAndSorted) return [];
      if (!this.groupBy) {
        return {
          '': this.filteredAndSorted.slice(0, this.show),
        };
      }
      return this.filteredAndSorted.reduce((acc, curr) => {
        const key = curr[this.groupBy];
        if (acc[key]) {
          acc[key].push(curr);
        } else {
          acc[key] = [curr];
        }
        return acc;
      }, {});
    },
    columnSize() {
      const editButtonModifier = (this.columns?.lastItem?.key === 'edit') * 1;
      return ((12 - editButtonModifier)
        / (this.columns.length - editButtonModifier)) / 0.12;
    },
  },
  watch: {
    check(c) {
      this.$emit('update:selected', c);
    },
    filteredAndSorted(l) {
      const newCheck = {};
      l.forEach(({ id }) => {
        if (this.check[id]) {
          this.$set(newCheck, id, true);
        }
      });
      this.check = newCheck;
    },
    sortBy: {
      immediate: true,
      handler(val) {
        if (val) {
          Object.entries(val).forEach(([key, value]) =>
            this.$set(this.sort, key, value));
        }
      },
    },
  },
  methods: {
    resetScroll() {
      this.show = initShow;
      this.loadMore();
    },
    clearSelect() {
      this.check = {};
    },
    toggleSort(key) {
      if (this.sort[key] === 'down') {
        this.$set(this.sort, key, 'up');
      } else if (this.sort[key] === 'up') {
        this.$set(this.sort, key, null);
      } else {
        this.$set(this.sort, key, 'down');
      }
    },
    loadMore() {
      this.scrollBusy = true;
      if (this.show >= this.filteredAndSorted.length) return;

      this.$nextTick(() => {
        setTimeout(() => {
          this.scrollBusy = false;
        }, 200);
      });

      this.show += 20;
    },
    toggleGroup(name) {
      if (this.groupBy === name) {
        this.groupBy = null;
      } else {
        this.groupBy = name;
      }
    },
  },
  created() {
    if (this.defaultGroup) {
      this.groupBy = this.defaultGroup;
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.search && this.searchableFocus) {
        this.$refs.search.focus();
      }
    }, 0);
  },
};
</script>

<style lang="scss" scoped>
  .column-names {
    font-size: 12px;
    text-align: left;
    user-select: none;
  }

  .column-title:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .list-content {
    overflow: auto;
  }

  .results {
    font-size: 10px;
    color: rgba(150, 150, 150, 1);
    padding-top: 2px;
    text-align: right;
  }

  .group-icon {
    transition: transform 200ms;
    cursor: pointer;
    margin-left: 3px;
    color: rgb(100, 100, 100);

    &:hover {
      transform: scale(1.25);
      color: #333;
    }
  }

  .table-row {
    padding: 4px 10px;
    font-size: 14px;
    line-height: 1.1;
    margin-top: 2px;
    align-items: center;

    &.odd {
      background-color: rgba(200, 200, 200, 0.2);
    }

    &.hoverable {
      cursor: pointer;
    }

    &.hoverable:hover {
      background-color: hsla(0, 0%, 58.8%, .1)
    }
  }
</style>
