<template>
  <div>
    <div class="d-flex justify-content-between page-header">
      <div class="d-flex text-left align-items-center title">
        <i class="fas fa-medal pr-2" />
        {{ $t('people.competences') }}
      </div>
      <div class="d-flex align-items-center">
        <button
          v-if="canAdd"
          class="btn btn-success btn-sm mr-2 action-button"
          @click="selected = null; form = true"
        >
          <i class="fas fa-add pr-1" />
          {{ $t('competence.add') }}
        </button>
      </div>
    </div>

    <div class="px-3">
      <div class="mb-2">
        <BFormInput
          ref="search"
          v-model="search"
          type="search"
          :placeholder="`${$t('general.search')}...`"
          class="search"
          @click="$refs.search.select()"
        />
      </div>
      <SimpleTable
        :items="filteredCompetences"
        :fields="columns"
        sort-by="Name"
        class="user-table"
        @row-clicked="select"
      />
    </div>

    <LModal
      :show.sync="form"
    >
      <CompetenceDetailsForm
        v-if="form"
        :competence="selected"
        @close="form = false"
      />
    </LModal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import createSearch from '@core/utils/search';
import CompetenceDetailsForm from '@/components/people/competences/CompetenceDetailsForm';

export default {
  data: () => ({
    selected: null,
    form: false,
    search: '',
  }),
  components: {
    CompetenceDetailsForm,
  },
  computed: {
    ...mapGetters(['tenantId']),
    ...mapGetters('core', ['can']),
    ...mapState({
      competences: state => state.competence.competences || [],
    }),
    filteredCompetences() {
      const search = createSearch(this.search);
      return this.competences
        .filter(x => search(x.name));
    },
    canAdd() {
      return this.can(this.$perm.CONFIG_USER_MANAGEMENT, this.tenantId)
      || this.can(this.$perm.CONFIG_TENANT_ADMIN, this.tenantId);
    },
    columns() {
      return [
        {
          key: '',
        },
        {
          key: 'name',
          label: this.$t('general.name'),
        },
      ];
    },
  },
  methods: {
    select(element) {
      this.selected = element;
      this.form = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../page-style.scss";

.card {
  background-color: $grey;
  display: inline;
}
.active-card {
  background-color: $ilabo;
}
</style>
