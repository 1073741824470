import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const taxonomies = new Vapi({
  axios,
  state: {
    loadingTaxonomies: false,
    taxonomies: [],
  },
})
  .get({
    action: 'getTaxonomies',
    path: () => 'taxonomies',
  })
  .get({
    action: 'getTaxonomiesById',
    path: () => 'taxonomies/by-id',
  })
  .get({
    action: 'getTaxonomy',
    path: ({ taxonomyId }) => `taxonomies/${taxonomyId}`,
  })
  .post({
    action: 'createTaxonomy',
    path: () => 'taxonomies',
  })
  .put({
    action: 'updateTaxonomy',
    path: ({ taxonomyId }) => `taxonomies/${taxonomyId}`,
  })
  .delete({
    action: 'removeTaxonomy',
    path: ({ taxonomyId }) => `taxonomies/${taxonomyId}`,
  })
  .put({
    action: 'createTaxonomyLevel',
    path: ({ taxonomyId }) => `taxonomies/${taxonomyId}/levels/add`,
  })
  .put({
    action: 'updateTaxonomyLevel',
    path: ({ taxonomyId }) => `taxonomies/${taxonomyId}/levels/update`,
  })
  .put({
    action: 'removeTaxonomyLevel',
    path: ({ taxonomyId }) => `taxonomies/${taxonomyId}/levels/remove`,
  })
  .put({
    action: 'createTaxonomyNode',
    path: ({ taxonomyId }) => `taxonomies/${taxonomyId}/nodes/add`,
  })
  .put({
    action: 'updateTaxonomyNode',
    path: ({ taxonomyId }) => `taxonomies/${taxonomyId}/nodes/update`,
  })
  .put({
    action: 'removeTaxonomyNode',
    path: ({ taxonomyId }) => `taxonomies/${taxonomyId}/nodes/remove`,
  })
  .get({
    action: 'getTaxonomiesAssignmentData',
    path: () => '/taxonomies/assignment-data',
  })
  .get({
    action: 'getTaxonomiesForElement',
    path: ({
      plantId,
      elementId,
    }) => `plant/${plantId}/element/${elementId}/taxonomies`,
  })
  .get({
    action: 'getElementsInTaxonomy',
    path: ({ plantId, taxonomyId }) => `plant/${plantId}/taxonomy/${taxonomyId}/elements`,
  })
  .put({
    action: 'assignTaxonomyForElement',
    path: ({
      plantId,
      elementId,
    }) => `plant/${plantId}/element/${elementId}/taxonomies/assign`,
  })
  .put({
    action: 'unassignTaxonomyForElement',
    path: ({
      plantId,
      elementId,
    }) => `plant/${plantId}/element/${elementId}/taxonomies/unassign`,
  })
  .getStore();

taxonomies.mutations = {
  ...taxonomies.mutations,
  setLoadingTaxonomies: (s, p) => {
    s.loadingTaxonomies = p;
  },
  setTaxonomies: (s, p) => {
    s.taxonomies = p;
  },
};

taxonomies.getters = {
  taxonomies: s => s.taxonomies,
  loadingTaxonomies: s => s.loadingTaxonomies,
};

export default taxonomies;
