<template>
  <div
    :class="{'light': light}"
    class="pro-select"
  >
    <div
      v-if="label"
      class="pro-select-label"
    >
      {{ label }}
    </div>

    <div
      :class="{'light': light}"
      :style="{height: boxHeight}"
      class="pro-select-inner"
      @click="toggleDropdown"
    >
      <div
        v-if="iconClass"
        :class="((multiselect && selectedItems.length > 0) || (!multiselect && value))
          ? (iconColorClass || 'text-primary')
          : ''
        "
        :style="{ 'color': selectedItems.length === 1 ? `${selectedItems[0].color} !important` : '' }"
        class="pro-select-icon"
      >
        <i
          :class="selectedItems.length === 1 ? getItemIconClass(selectedItems[0]) : iconClass"
        />
      </div>

      <div
        v-if="showAllOption
          && ((multiselect && selectedItems.length === 0) || (!multiselect && !selectedItem))"
        class="pro-select-text"
      >
        {{ $t('general.all') }}
      </div>

      <div
        v-else-if="multiselect && selectedItems.length === 1"
        class="pro-select-text"
      >
        {{ getItemLabel(selectedItems[0]) }}
      </div>

      <div
        v-else-if="multiselect"
        class="pro-select-text"
      >
        {{ selectedItems.length }}
        {{ $t('general.selected') }}
      </div>

      <div
        v-else
        class="pro-select-text"
      >
        {{ getItemLabel(selectedItem) }}
      </div>

      <div class="pro-select-caret">
        <i class="fas fa-caret-down" />
      </div>
    </div>

    <div
      v-if="dropdownOpen"
      ref="dropdown"
      v-click-outside="handleClickOutside"
      :style="{width: dropdownWidth}"
      class="pro-select-dropdown"
      @scroll="handleScroll"
    >
      <div
        v-if="showSearch"
        class="pro-select-dropdown-search"
      >
        <input
          v-model="searchQuery"
          :placeholder="$t('general.search')"
          class="form-control"
        >
      </div>
      <div
        v-if="showSearch"
        class="pro-select-dropdown-divider"
      />

      <div
        v-if="showAllOption"
        class="pro-select-dropdown-item"
        @click="setAllOption"
      >
        <div
          v-if="iconClass"
          class="pro-select-icon"
        >
          <i :class="iconClass" />
        </div>

        {{ $t('general.all') }}
      </div>
      <div
        v-if="showAllOption"
        class="pro-select-dropdown-divider"
      />

      <div
        v-for="item in filteredItems"
        :id="`pro-select-item-${getItemValue(item)}`"
        :key="getItemValue(item)"
        :class="{'selected': isItemSelected(item)}"
        class="pro-select-dropdown-item"
        @click="setValue(getItemValue(item))"
      >
        <div
          v-if="getItemIconClass(item)"
          class="pro-select-icon"
        >
          <i
            :class="getItemIconClass(item)"
            :style="{ 'color': item.color }"
          />
        </div>

        {{ getItemLabel(item) }}

        <div
          v-if="isItemSelected(item)"
          class="pro-select-dropdown-item-check"
        >
          <i class="fas fa-check" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProSelect',
  props: {
    value: [String, Number, Array],
    options: {
      type: Array,
      required: true,
    },
    multiselect: Boolean,
    showAllOption: Boolean,
    showSearch: Boolean,
    light: Boolean,
    iconClass: String,
    iconColorClass: String,
    hideDropdown: Boolean,
    dropdownWidth: {
      type: String,
      default: '150%',
    },
    boxHeight: {
      type: String,
      default: '32px',
    },
    label: {
      type: String,
      default: '',
    },
    customLabelExtractFunction: Function,
    customValueExtractFunction: Function,
    dynamicShowingList: Boolean,
  },
  data: () => ({
    dropdownOpen: false,
    searchQuery: '',
    dynamicShowingAmount: 20,
  }),
  computed: {
    allItems() {
      return [...this.options];
    },
    innerValue: {
      set(v) {
        this.$emit('input', v);
      },
      get() {
        return this.value;
      },
    },
    filteredItems() {
      let items = [];
      if (this.searchQuery) {
        const searchItems = this.allItems.filter(i => this.getItemLabel(i).toLowerCase()
          ?.includes(this.searchQuery.trim().toLowerCase()));
        items = searchItems;
      } else {
        items = this.allItems;
      }

      if (this.dynamicShowingList) {
        return items.filter((_, i) => i < this.dynamicShowingAmount);
      }
      return items;
    },
    selectedItems() {
      if (this.multiselect) {
        return this.allItems
          .filter(i => this.innerValue?.includes(this.getItemValue(i))) || [];
      }
      return this.allItems
        .filter(i => this.innerValue === this.getItemValue(i)) || [];
    },
    selectedItem() {
      return this.allItems
        .find(i => this.getItemValue(i) === this.innerValue);
    },
  },
  watch: {
    dropdownOpen(v) {
      if (!v) {
        this.searchQuery = '';
        this.dynamicShowingAmount = 20;
      } else {
        this.$nextTick(() => {
          this.scrollToSelectedItem();
        });
      }
    },
    searchQuery() {
      this.scrollToTop();
    },
    hideDropdown(v) {
      if (v) {
        this.handleClickOutside();
      }
    },
  },
  methods: {
    handleScroll(event) {
      const { target } = event;
      if (target.scrollHeight - target.scrollTop < 200) {
        this.dynamicShowingAmount += 20;
      }
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    setValue(v) {
      const isSelected = this.multiselect ? this.innerValue?.includes(v) : this.innerValue === v;
      if (this.multiselect) {
        if (isSelected) {
          this.innerValue = this.innerValue.filter(value => value !== v);
        } else {
          this.innerValue = [
            ...this.innerValue,
            v,
          ];
        }
        return;
      }

      this.innerValue = isSelected ? null : v;
      this.dropdownOpen = false;
    },
    setAllOption() {
      if (this.multiselect) {
        this.innerValue = [];
        return;
      }
      this.setValue('');
    },
    handleClickOutside() {
      this.dropdownOpen = false;
    },
    isItemSelected(item) {
      if (this.multiselect) return this.innerValue?.includes(this.getItemValue(item));
      return this.getItemValue(item) === this.innerValue;
    },
    getItemValue(item) {
      if (this.customValueExtractFunction) return this.customValueExtractFunction(item);
      return item?.value;
    },
    getItemLabel(item) {
      if (this.customLabelExtractFunction) return this.customLabelExtractFunction(item);
      return item?.label || '-';
    },
    getItemIconClass(item) {
      return item?.iconClass || this.iconClass;
    },
    scrollToTop() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.scrollTo({
          top: 0,
        });
      }
    },
    scrollToSelectedItem() {
      if (this.multiselect) return;
      if (this.dynamicShowingList) return;

      if (this.$refs.dropdown) {
        const selectedItemId = this.getItemValue(this.selectedItem);
        const scrollToElement = this.$refs.dropdown.querySelector(`#pro-select-item-${selectedItemId}`);
        const halfOfDropdown = this.$refs.dropdown.clientHeight / 2;
        if (!scrollToElement) return;
        this.$refs.dropdown.scrollTo({
          top: scrollToElement.offsetTop - halfOfDropdown,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./proselect.scss";
</style>
