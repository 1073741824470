<template>
  <Loader v-if="pending" />
  <RolesEditor v-else />
</template>

<script>
import { mapState } from 'vuex';
import RolesEditor from '@/components/people/roles/RolesEditor';

export default {
  components: {
    RolesEditor,
  },
  computed: {
    ...mapState({
      pending: state => state.role.pending.roles,
    }),
  },
  created() {
    this.$emit('selected', 'roles');
  },
};
</script>

<style />
