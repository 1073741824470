import Vue from 'vue';
import axios from 'axios';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import getOidcSettings, { authorityStorageKey, settings } from '@/api/oidc';
import { notify } from '@core/api/axios';
import hub, { auditLog, auth } from '@/api/axios';
import { info } from '@core/utils/logger';

export default async function start({
  store,
  vue,
}) {
  const { data } = await axios.get(`${process.env.VUE_APP_PUBLIC_PATH}api/config`);

  const config = {
    authUrl: data.authUrl,
    notifyUrl: data.notifyUrl,
    auditLogUrl: data.auditLogUrl,
  };

  settings.authority = config.authUrl;
  localStorage.setItem(authorityStorageKey, config.authUrl);

  store.commit('setUsersOnly', data.usersOnly);

  store.registerModule('oidcStore',
    vuexOidcCreateStoreModule(getOidcSettings(), {
      namespaced: true,
      routeBase: process.env.VUE_APP_PUBLIC_PATH,
    }));

  hub.defaults.baseURL = `${process.env.VUE_APP_PUBLIC_PATH}api`;
  notify.defaults.baseURL = `${config.notifyUrl}/api`;
  auth.defaults.baseURL = `${config.authUrl}/api/v1/`;
  auditLog.defaults.baseURL = `${config.auditLogUrl}api`;

  info(`PUBLIC PATH: ${process.env.VUE_APP_PUBLIC_PATH}`);
  info(`AUTH URL: ${config.authUrl}`);
  info(`NOTIFY URL: ${config.notifyUrl}`);
  info(`AUDIT LOG URL: ${config.auditLogUrl}`);

  /* eslint-disable no-new */
  return new Vue(vue);
}
