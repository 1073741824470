<template>
  <Tippy
    ref="popup"
    theme="light"
    interactive
    trigger="click"
    :distance="10"
    arrow
    placement="bottom-end"
    sticky
  >
    <template #trigger>
      <div class="text-uppercase text-white">
        {{ currentLanguage }}
      </div>
    </template>

    <div style="margin: -0.3125rem -0.5625rem">
      <div
        v-for="el in languages"
        :key="el.lang"
        class="dropdown-row clickable d-flex py-2"
        :class="{ selected: el.lang === currentLanguage }"
        @click="setLanguage(el.lang); $refs.popup.tippy().hide()"
      >
        <div class="icon mr-2 flag">
          <span
            :class="`fi-${el.flag}`"
            class="fi fis"
          />
        </div>
        <div class="flex-grow-1 text-left">
          {{ el.name }}
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    setSiteLanguage: {
      type: Boolean,
      default: true,
    },
    currentSetLanguage: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('core', ['language', 'languages']),
    currentLanguage() {
      if (this.setSiteLanguage) {
        return this.language.lang;
      }
      return this.currentSetLanguage;
    },
  },
  methods: {
    setLanguage(lang) {
      if (this.setSiteLanguage) {
        this.$store.dispatch('core/setLanguage', { lang, save: true });
      } else {
        this.$emit('save', lang);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./dropdowns/style.scss";
  .flag {
    $size: 25px;
    cursor: pointer;

    .fi {
      display: block;
      float: left;
      border-radius: 50%;
      height: $size;
      width: $size;
      box-shadow: 0 0 4px rgba(100, 100, 100, 0.7);

      &:hover {
        box-shadow: 0 0 8px rgba(100, 100, 100, 0.9);
      }
    }

    &.not-active {
      opacity: 0.5;
    }
    .arrow {
      position: absolute;
      bottom: -8px;
      width: 100%;
      text-align: center;
      left: 0;
    }
  }

</style>
