<template>
  <div>
    <div
      class="license flex-grow-1 d-flex justify-content-between"
      style="height: 33px;"
    >
      <div class="ml-2 mr-2 align-self-center">
        <span class="name">
          {{ machineLicenseData.machineName }}
        </span>
      </div>
    </div>
    <div class="pl-2">
      <LicenseRow
        v-for="machineLicense in machineLicenses"
        :key="machineLicense.licenseType"
        style="margin-top: 2px"
        :license-type="machineLicense.licenseType"
        :license-count="machineLicense.licenseCount"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import LicenseRow from './LicenseRow';

export default {
  props: {
    machineLicenseData: {
      type: Object,
      required: true,
    },
  },
  components: {
    LicenseRow,
  },
  computed: {
    ...mapGetters(['elementName']),
    machineLicenses() {
      const licenses = [
        {
          licenseType: 'packos-entry-dummy-machine',
          licenseCount: this.machineLicenseData.entryDummyLicenses,
        },
        {
          licenseType: 'packos-entry-manual-machine',
          licenseCount: this.machineLicenseData.entryManualLicenses,
        },
        {
          licenseType: 'packos-automated-base-machine',
          licenseCount: this.machineLicenseData.baseAutomatedLicenses,
        },
        {
          licenseType: 'packos-automated-advanced-extension',
          licenseCount: this.machineLicenseData.advancedAutomatedExtensions,
        },
        {
          licenseType: 'signal-extension',
          licenseCount: this.machineLicenseData.signalExtensions,
        },
        {
          licenseType: 'vision-extension',
          licenseCount: this.machineLicenseData.visionExtensions,
        },
      ];
      return licenses.filter(x => x.licenseCount > 0);
    },
  },
};
</script>

<style lang="scss" scoped>
  .license {
    box-shadow: 0 0 2px rgba(100,100,100,0.4) inset;
    border-radius: 5px;
    padding: 4px 10px;
    line-height: 1.1;
    text-align: left;
    transition: background-color 500ms;

    .name {
      font-weight: 500;
      font-size: 14px;
    }
  }

</style>
