<template>
  <div>
    <button
      v-if="!fromPreview"
      class="btn btn-link icon-btn align-self-center mb-3"
      style="height: 31px; width: 31px"
      @click="back"
    >
      <i class="ion ion-md-return-left" />
    </button>

    <div class="d-flex">
      <h4
        class="align-self-center mr-2"
        style="margin-bottom:0px"
      >
        <i class="ion ion-md-git-network pr-2" />

        {{ $t('plants.structure') }}
      </h4>
      <button
        v-tippy="{ arrow: true}"
        :content="$t('elements.addElement')"
        class="btn btn-success btn-sm icon-btn ml-2"
        @click="elementForm = true; element = null"
      >
        <i class="fas fa-add" />
      </button>
    </div>

    <div class="d-flex justify-content-between align-items-end mt-2">
      <div class="d-inline-flex">
        <div class="d-flex">
          <div>
            <div
              class="text-secondary"
              style="margin-top:0px;"
            >
              {{ plant.name }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <BSpinner
          v-if="loadingTaxonomies"
          small
        />
        <TaxonomiesSelect
          v-else
          v-model="selectedTaxonomy"
          :taxonomies="taxonomies"
        />
      </div>
    </div>
    <hr style="margin-left: 0; margin-right: auto">

    <TreeTable
      class="tree-lg"
      :columns="columns"
      :list="selectedTaxonomy ? taxonomyBasedElementTree : plantElements"
      :root="selectedTaxonomy ? rootOfTaxonomyBasedElementTree : plant"
      :show-taxonomy-based-tree="!!selectedTaxonomy"
      @edit="edit"
      @add="add"
    />

    <LModal
      :show.sync="elementForm"
    >
      <ElementForm
        v-if="elementForm"
        :plant="plant"
        :element="element"
        :plant-elements="plantElements"
        @close="elementForm = false"
        @taxonomy-update="updateTaxonomiesData"
      />
    </LModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ElementForm from '@/components/elements/ElementForm';
import TreeTable from '@/components/tables/TreeTable';
import TaxonomiesSelect from '@core/components/TaxonomiesSelect';

export default {
  props: {
    search: String,
    plant: Object,
    fromPreview: Boolean,
  },
  data: () => ({
    sort: { name: 'down' },
    elementForm: false,
    selected: '',
    element: {},
    parent: {},
    loadingTaxonomies: false,
    taxonomies: [],
    elementTaxonomies: {},
    selectedTaxonomy: null,
  }),
  components: {
    TaxonomiesSelect,
    ElementForm,
    TreeTable,
  },
  computed: {
    ...mapGetters(['tenantId']),
    ...mapState({
      plants: state => state.structure.plants || [],
      elements: state => state.structure.elements || [],
    }),
    plantElements() {
      return this.elements.filter(x => x.plantId === this.plant.id);
    },
    selectedTaxonomyObject() {
      return this.taxonomies.find(t => t.id === this.selectedTaxonomy);
    },
    rootOfTaxonomyBasedElementTree() {
      const roots = this.selectedTaxonomyObject.nodes.find(n => !n.parentId);
      const root = {
        id: null,
        description: null,
        code: null,
      };
      if (roots.length === 1) {
        root.id = roots[0].id;
        if (!root.description && root.code) {
          root.description = root.code;
          root.code = null;
        }
        root.name = root.description;
      }
      return root;
    },
    taxonomyBasedElementTree() {
      const parsedElements = this.plantElements.map(e => {
        const elementTaxonomy = this.elementTaxonomies[e.id];

        return {
          ...e,
          taxonomyParentId: elementTaxonomy?.taxonomyNodeId || null,
        };
      })
        .filter(e => e.taxonomyParentId);

      return [
        ...this.selectedTaxonomyObject.nodes.map(n => ({
          ...n,
          name: n.description,
          taxonomyParentId: n.parentId,
        })),
        ...parsedElements,
      ];
    },
    columns() {
      return [{
        key: 'name',
        text: this.$t('general.name'),
        secondary: 'code',
      }];
    },
  },
  watch: {
    plant: {
      deep: true,
      handler() {
        this.selectedTaxonomy = null;
        this.updateTaxonomiesData();
      },
    },
    selectedTaxonomy(taxonomyId) {
      if (taxonomyId) {
        this.loadTaxonomyTree();
      }
    },
  },
  methods: {
    ...mapActions([
      'getTaxonomiesAssignmentData',
      'getElementsInTaxonomy',
    ]),
    loadTaxonomyTree() {
      this.loadingTaxonomies = true;
      this.getElementsInTaxonomy({
        params: {
          taxonomyId: this.selectedTaxonomy,
          plantId: this.plant.id,
        },
      })
        .then(({ data }) => {
          this.elementTaxonomies = data.reduce((acc, curr) => {
            acc[curr.elementId] = curr;
            return acc;
          }, {});
        })
        .finally(() => {
          this.loadingTaxonomies = false;
        });
    },
    edit(element) {
      this.element = element;
      this.elementForm = true;
    },
    add() {
      this.element = null;
      this.elementForm = true;
    },
    back() {
      this.$router.push(`/${this.tenantId}/plants`)
        .catch(() => {
        });
    },
    async fetchTaxonomiesForPlant() {
      const { data } = await this.getTaxonomiesAssignmentData({
        params: {
          query: {
            namespaceId: this.plant.id,
          },
        },
      });
      this.taxonomies = data;
    },
    async updateTaxonomiesData() {
      this.loadingTaxonomies = true;

      return this.fetchTaxonomiesForPlant()
        .finally(() => {
          this.loadingTaxonomies = false;
        });
    },
  },
  async created() {
    await this.updateTaxonomiesData();
  },
};
</script>

<style lang="scss" scoped>
.column-names {
  font-size: 12px;
  text-align: left;
  user-select: none;
}

.column-title:hover {
  text-decoration: underline;
  cursor: pointer;
}

.tree-lg :deep(.name) {
  font-size: 14px !important;
}
</style>
