import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import LogixCore from '@core';
import VueTippy, { TippyComponent } from 'vue-tippy';
import DateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import { init } from '@/api/axios';

import App from './App';
import router from './router';
import store from './store';
import i18n from './lang';
import start from './start';

import vars from './styles/vars.icss.scss';
import './styles/app.scss';

Vue.config.productionTip = false;

Vue.use(VueTippy);
Vue.use(BootstrapVue);
Vue.use(LogixCore, { colors: vars, store });
Vue.component('Tippy', TippyComponent);
Vue.component('DateTimePicker', DateTimePicker);

init(store);

start({
  store,
  vue: {
    el: '#app',
    router,
    store,
    i18n,
    render: h => h(App),
  },
});
