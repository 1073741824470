<template>
  <div class="card-preview position-relative">
    <div class="d-flex pt-2">
      <div>
        <button
          class="btn btn-link icon-btn close-button"
          style="height: 31px; width: 31px"
          @click="$emit('close-preview')"
        >
          <i class="ion ion-md-close" />
        </button>
      </div>
    </div>
    <ElementStructure
      class="pt-2 flex-grow-1"
      :plant="plant"
      :from-preview="true"
      @select="$emit('select', $event)"
    />
  </div>
</template>

<script>
import ElementStructure from '@/components/elements/ElementStructure';

export default {
  props: {
    search: String,
    plant: Object,
  },
  components: {
    ElementStructure,
  },
};
</script>

<style lang="scss" scoped>
  .card-preview {
    box-shadow: 0 0 4px rgba(100, 100, 100, 0.2);
    border-radius: 15px;
    padding-bottom: 20px;
    margin-top: 20px;
    background: white;
  }

  .close-button {
    height: 31px;
    width: 31px;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    box-shadow: unset !important;
    font-size: 1.5rem !important;
  }
</style>
