<template>
  <div>
    <div class="pl-2 pr-2">
      <ListRow
        v-for="item in filteredItems"
        :key="item.id"
        style="margin-top: 2px"
        v-bind="item"
        @selected="$emit('selected', item.id)"
      />

      <div v-if="filteredItems.length === 0">
        <NoPermissions style="font-size: 0.6rem" />
      </div>
    </div>
  </div>
</template>

<script>
import ListRow from '@/components/utils/ListRow';

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    selected: Array,
    search: String,
  },
  data: () => ({
  }),
  components: {
    ListRow,
  },
  computed: {
    selectedItems() {
      return this.selected || [];
    },
    filteredItems() {
      return this.list.map(x => ({
        id: x.id,
        name: x.name,
        selected: !!this.selectedItems.find(y => y.id === x.id),
      }));
    },
  },
};
</script>

<style />
