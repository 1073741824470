<template>
  <div>
    <BRow>
      <BCol lg="8">
        <TaxonomyListItem
          v-for="taxonomy in taxonomies"
          :id="taxonomy.id"
          :key="taxonomy.id"
          :namespace-id="taxonomy.namespaceId"
          :code="taxonomy.code"
          :description="taxonomy.description"
          :tags="taxonomy.tags"
          class="mb-3"
          @select="handleTaxonomySelect"
          @clone="showTaxonomyCloneModal"
        />
      </BCol>
    </BRow>

    <TaxonomyCloneModal
      @cancel="hideTaxonomyCloneModal"
      @confirm="handleTaxonomyClone"
    />
  </div>
</template>

<script>
import TaxonomyListItem from '@/components/taxonomies/list/TaxonomyListItem';
import TaxonomyCloneModal from '@/components/taxonomies/modals/TaxonomyCloneModal';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    taxonomyIdToClone: '',
  }),
  components: {
    TaxonomyCloneModal,
    TaxonomyListItem,
  },
  computed: {
    ...mapGetters([
      'taxonomies',
    ]),
  },
  methods: {
    handleTaxonomySelect(id) {
      this.$router.push({
        path: `${this.$route.path}/${id}`,
      });
    },
    handleTaxonomyClone() {
      // TODO: connect to cloning API endpoint
      this.hideTaxonomyCloneModal();
    },
    showTaxonomyCloneModal(id) {
      this.taxonomyIdToClone = id;
      this.$bvModal.show('taxonomy-clone-modal');
    },
    hideTaxonomyCloneModal() {
      this.taxonomyIdToClone = '';
      this.$bvModal.hide('taxonomy-clone-modal');
    },
  },
};
</script>
