import Vue from 'vue';
import Vapi from 'vuex-rest-api';
import { hub as axios } from '../api/axios';

const store = new Vapi({
  axios,
  state: {
    list: {},
  },
})
  .get({
    action: 'getUserSettings',
    path: () => 'user/settings',
    onSuccess: (s, { data }) => {
      data.forEach(x => {
        Vue.set(s.list, x.key, x.value);
      });
    },
  })
  .put({
    action: 'setUserSetting',
    path: () => 'user/setting',
    onSuccess(s, { data }) {
      Vue.set(s.list, data.key, data.value);
    },
  })
  .getStore();

store.getters = {
  userSetting: s => name => (s.list || {})[name],
  userSettings: s => s.list,
};

store.actions.initUserConfig = (ctx, scope) =>
  ctx.dispatch('getUserSettings', {
    params: { query: { scope } },
  })
    .then(() => {
      const setting = ctx.getters.userSetting('language');
      ctx.dispatch('setLanguage', { lang: setting, save: false });
    });

export default store;
