import Qs from 'qs';

export const paramsSerializer = params => {
  const { query } = params;
  if (query) {
    return Qs.stringify(query, { arrayFormat: 'repeat' });
  }
  return null;
};

export const setHeaders = (ax, store) => {
  ax.interceptors.request.use(config => {
    const token = store.getters['core/token'];
    const conf = { ...config };
    if (token != null) {
      conf.headers.Authorization = `Bearer ${token}`;
    }

    const language = store.getters['core/language'];
    if (language) {
      conf.headers['Accept-Language'] = language.lang;
    }

    return conf;
  }, err => Promise.reject(err));
};
