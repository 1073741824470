<template>
  <div style="max-width: 1000px; margin: auto">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <div>
          <button
            class="btn btn-secondary icon-btn"
            @click="back"
          >
            <i class="fas fa-chevron-left" />
          </button>
        </div>
        <h4 class="mb-0 ml-3">
          {{ title }}
        </h4>
      </div>
      <div v-if="file">
        <button
          v-if="file"
          class="btn btn-elegant btn-sm"
          @click="$emit('finish')"
        >
          <i class="fas fa-check pr-2" />
          {{ $t('general.finish') }}
        </button>
      </div>
      <div
        v-else
        style="width: 35px"
      />
    </div>
    <hr>
    <div
      v-if="job"
      class="text-center"
    >
      <div v-if="jobResult">
        <div v-if="jobResult.status.toLowerCase() === 'jobdone'">
          <slot
            name="imported"
            :imported="jobResult"
          />
        </div>
        <div
          v-else-if="jobResult.status.toLowerCase() === 'jobfailed'"
          class="text-danger"
          style="max-width: 400px; margin: auto"
        >
          <div
            style="font-size: 35px"
            class="text-danger"
          >
            <i class="fas fa-times-circle" />
          </div>
          {{ $t('error.jobFailed') }}
        </div>
        <div
          v-else
          class="text-primary"
          style="max-width: 400px; margin: auto"
        >
          <div style="font-size: 35px">
            <i class="fas fa-cloud-meatball" />
          </div>
          {{ $t('import.fileIsBeingProcessed') }}
        </div>
      </div>

      <Loader
        v-if="jobResultPending"
      />
      <div
        v-else-if="jobResultError"
        class="text-danger"
      >
        <div style="font-size: 35px">
          <i class="fas fa-times" />
        </div>
        {{ $t('error.unknown') }}
      </div>
    </div>
    <div v-else-if="!file">
      <VueDropzone
        v-if="!file"
        id="dropzone"
        style="border-radius: 20px; "
        :options="dropzoneOptions"
        @vdropzone-file-added="uploaded"
      />
      <div class="mt-4">
        <div
          v-if="jobsWithStatus && jobsWithStatus.length > 0"
          class="font-weight-bold mb-2"
        >
          {{ $t('import.previousImports') }}
        </div>
        <div
          v-for="j in jobsWithStatus"
          :key="j.id"
          class="d-flex align-items-center job-row"
          @click="setJob(j)"
        >
          <div
            class="mx-2 status-icon text-center d-flex align-items-center justify-content-center"
            :style="{
              'background-color': j.statusDetails.color
            }"
          >
            <i :class="j.statusDetails.icon" />
          </div>
          <div class="pl-2">
            <div
              class="text-secondary font-weight-bold small"
            >
              {{ formatDate(j.created) }}
            </div>
            <div
              class="pr-2"
              style="line-height: 1"
            >
              {{ j.sourceName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="pending"
      class="text-center"
    >
      <Loader />
    </div>
    <div
      v-else-if="error"
      class="text-danger text-center"
      style="max-width: 400px; margin: auto"
    >
      <div style="font-size: 35px">
        <i class="fas fa-times" />
      </div>
      {{ $t('error.importNotAvailable') }}
    </div>
    <div v-else>
      <div
        v-for="result in imported"
        :key="result.rowNumber"
        class="import-row"
      >
        <div class="d-flex">
          <slot
            name="result"
            :result="result"
          />

          <div style="width: 200px; padding-left: 10px">
            <div v-if="!result.resultStatus">
              <div class="text-danger text-center mb-2 font-weight-bold">
                <i class="fas fa-times pr-2" />
                {{ $t('import.notSaved') }}
              </div>
              <div>
                <button
                  class="btn btn-success btn-sm w-100"
                  @click="resultModal = true; edited = result"
                >
                  <i class="fas fa-pen pr-2" />
                  {{ $t('import.fillAndAdd') }}
                </button>
              </div>
            </div>
            <div
              v-else
              class="d-flex align-items-center justify-content-center h-100"
            >
              <div class="text-success text-center font-weight-bold">
                <i class="fas fa-check pr-2" />
                {{ $t('import.saved') }}
              </div>
              <!--
                TODO: Return ID from API

                <button
                class="btn btn-secondary btn-sm w-100"
                @click="resultModal = true; edited = result"
              >
                <i class="fas fa-pen pr-2" />
                {{ $t('general.edit') }}
              </button> -->
            </div>
          </div>
        </div>
      </div>

      <LModal
        :show.sync="resultModal"
      >
        <slot
          name="edit"
          :item="edited"
          :on-save="onSave"
          :close="onClose"
        />
      </LModal>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VueDropzone from 'vue2-dropzone';
import moment from 'moment';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import loopRequest from '../mixins/loopRequest';

export default {
  props: {
    title: String,
    importFunction: Function,
    getImporterJobs: Function,
    getImporterJob: Function,
    jobType: String,
    plantId: String,
    tenantId: String,
  },
  data() {
    return {
      imported: null,
      file: null,
      pending: false,
      resultModal: false,
      edited: null,
      error: false,
      job: null,
      jobResult: null,
      jobResultPending: false,
      jobResultError: null,
    };
  },
  components: {
    VueDropzone,
  },
  mixins: [loopRequest('checkJob', 5000)],
  computed: {
    ...mapState({
      jobs: state => state.imports.jobs || [],
    }),
    jobsWithStatus() {
      return this.jobs.map(j => ({
        ...j,
        statusDetails: this.getStatusIcon(j.status.toLowerCase()),
      }));
    },
    dropzoneOptions() {
      return {
        url: 'packos.cloud',
        dictDefaultMessage: this.$t('import.upload'),
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { },
      };
    },
  },
  methods: {
    setJob(job) {
      this.job = job;
      this.jobResult = null;
      this.getJobResult();
    },
    back() {
      if (!this.file && !(this.job || this.jobResult)) {
        this.$router.back();
      }
      this.file = null;
      this.job = null;
      this.jobResult = null;
    },
    formatDate(date) {
      return moment(date).format('LLLL');
    },
    checkJob() {
      if (this.job) {
        const status = !this.jobResult ? null : this.jobResult.status.toLowerCase();
        if (status === 'jobdone' || status === 'jobfailed') return Promise.resolve();
        return this.getJobResult();
      }
      return this.requestJobs();
    },
    getStatusIcon(status) {
      if (status === 'jobdone') {
        return {
          icon: 'fas fa-check',
          color: this.$color('green'),
        };
      }
      if (status === 'jobfailed') {
        return {
          icon: 'fas fa-times',
          color: this.$color('red'),
        };
      }

      return {
        icon: 'fas fa-spinner fa-spin',
        color: this.$color('blue'),
      };
    },
    onClose() {
      this.resultModal = false;
      this.edited = null;
    },
    onSave(entity) {
      this.resultModal = false;
      const index = this.imported.findIndex(x => x.rowNumber === this.edited.rowNumber);
      if (index > -1) {
        this.$set(this.imported, index, {
          rowNumber: this.edited.rowNumber,
          entity,
          resultStatus: true,
        });
      }
    },
    uploaded(file) {
      this.pending = true;
      this.error = false;
      this.file = file;

      const formData = new FormData();
      formData.append('file', file);
      this.importFunction({
        params: {
          tenantId: this.tenantId,
          plantId: this.plantId,
        },
        data: formData,
      })
        .then(({ data }) => {
          this.pending = false;
          if (data.job) {
            this.setJob(data.result);
          } else {
            this.imported = data.result;
          }
        })
        .catch(() => {
          this.error = true;
          this.pending = false;
        });
    },
    getJobResult() {
      this.jobResultPending = true;
      this.jobResultError = false;
      return this.getImporterJob({
        params: {
          tenantId: this.tenantId,
          plantId: this.plantId,
          jobId: this.job.id,
        },
      })
        .then(({ data }) => {
          this.jobResult = {
            ...data,
            data: JSON.parse(data.data || '{}'),
          };
          this.jobResultPending = false;
        })
        .catch(({ response: { data } }) => {
          this.jobResultError = data;
          this.jobResultPending = false;
        });
    },
    requestJobs() {
      return this.getImporterJobs({
        params: {
          tenantId: this.tenantId,
          plantId: this.plantId,
          take: 10,
          skip: 0,
          query: {
            jobType: this.jobType,
          },
        },
      });
    },
  },
  created() {
    this.requestJobs();
  },
};
</script>

<style lang="scss" scoped>
  .import-row {
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px solid rgba(100, 100, 100, 0.5);
  }

  .job-row {
    border-top: 1px solid #efefef;
    cursor: pointer;
    padding: 10px 10px;

    .status-icon {
      height: 30px;
      color: white;
      width: 30px;
      flex-shrink: 0;
      border-radius: 50%;
    }

    &:hover {
      background-color: #fafafa;
    }
  }
</style>
