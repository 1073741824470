<template>
  <BFormGroup v-bind="$attrs">
    <BSelect v-model="innerValue">
      <BSelectOption
        value=""
        disabled
      >
        {{ $t('plants.selectPlant') }}
      </BSelectOption>
      <BSelectOption
        v-for="plant in filteredPlants"
        :key="plant.id"
        :value="plant.id"
      >
        {{ plant.name }}
      </BSelectOption>
    </BSelect>
  </BFormGroup>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    sort: { name: 'down' },
  }),
  computed: {
    ...mapState({
      plants: state => state.structure.plants || [],
    }),
    ...mapGetters('core', ['can']),
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    filteredPlants() {
      const sortString = (key, a, b, force) => {
        if (this.sort[key] === 'down' || force) {
          return a[key].localeCompare(b[key]);
        }
        if (this.sort[key] === 'up') {
          return b[key].localeCompare(a[key]);
        }
        return 0;
      };

      return this.plants
        .filter(x =>
          this.can(this.$perm.CONFIG_PLANT_ADMIN, x.id)
          || this.can(this.$perm.CONFIG_TENANT_ADMIN, x.tenantId))
        .sort((a, b) => sortString('name', a, b) || sortString('name', a, b, true));
    },
  },
};
</script>
