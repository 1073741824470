<template>
  <UsersEditor />
</template>

<script>
import UsersEditor from '@/components/people/users/UsersEditor';

export default {
  components: {
    UsersEditor,
  },
  created() {
    this.$emit('selected', 'users');
  },
};
</script>

<style lang="scss" scoped>

</style>
