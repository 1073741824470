<template>
  <div
    v-if="!pending"
    class="pt-2 text-center"
  >
    <RemovePopover
      v-if="showRemove"
      :target="`supplier-remove-${$uId}`"
      :pending="removePending"
      placement="bottom"
      @remove="$emit('remove')"
    />

    <button
      class="btn btn-secondary btn-sm mx-2"
      type="button"
      @click.prevent="$emit('cancel')"
    >
      <i
        class="pr-2"
        :class="cancelIcon || 'ion ion-md-close'"
      />
      {{ cancelLabel || $t('general.cancel') }}
    </button>

    <button
      v-if="showRemove"
      :id="`supplier-remove-${$uId}`"
      class="btn btn-dark btn-sm icon-btn mx-2"
      type="button"
    >
      <i class="ion ion-md-trash" />
    </button>

    <button
      class="btn btn-primary btn-sm mx-2"
      type="submit"
      :disabled="disabled"
      @click="$emit('save')"
    >
      <i
        class="pr-2"
        :class="saveIcon || 'ion ion-md-checkmark'"
      />
      {{ saveLabel || $t('general.save') }}
    </button>
  </div>
  <div
    v-else
    class="text-center pt-1"
  >
    <Loader />
  </div>
</template>

<script>
import RemovePopover from './RemovePopover.vue';

export default {
  props: {
    showRemove: Boolean,
    pending: Boolean,
    removePending: Boolean,
    disabled: Boolean,
    saveLabel: String,
    saveIcon: String,
    cancelLabel: String,
    cancelIcon: String,
  },
  components: {
    RemovePopover,
  },
};
</script>

<style>

</style>
