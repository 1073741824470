<template>
  <div id="app">
    <ScreenMeasure
      @mobile="setMobile"
      @screenWidth="setScreenWidth"
    />
    <RouterView />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScreenMeasure from '@core/utils/screenMeasure';

export default {
  components: {
    ScreenMeasure,
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcIsAuthenticated']),
  },
  watch: {
    oidcIsAuthenticated() {
      this.init();
    },
  },
  methods: {
    init() {
      if (!this.oidcIsAuthenticated) return;
      const config = this.$store.dispatch('core/initUserConfig', 'hub');
      const perm = this.$store.dispatch('core/initUserPermissions', ['config']);

      Promise.all([config, perm])
        .finally(() => {
          this.$store.commit('setReady', true);
        });
    },
    setMobile(v) {
      this.$store.commit('setMobile', v);
    },
    setScreenWidth(width) {
      this.$store.commit('setScreenWidth', width);
    },
  },
  created() {
    this.$store.commit('setReady', false);
    this.init();
  },
};
</script>
