<template>
  <div class="container pl-0 pr-0">
    <div
      class="text-left mt-2 mb-2"
    >
      <div class="row">
        <div class="col-6 ">
          <h6>{{ $t('permissions.choosePermission') }}</h6>
          <div class="border-box">
            <div
              v-for="el in modulesWithPermissions"
              :key="el.name"
            >
              <div
                class="permission-header d-flex align-items-center"
                @click="selectedApp === el.name ? (selectedApp = null) : selectedApp = el.name"
              >
                <img
                  class="app-logo"
                  :src="require(`@core/assets/logos/${el.name.toLowerCase()}-color-icon.png`)"
                >
                <div>
                  {{ el.text }}
                </div>
              </div>

              <SmoothReflow>
                <PermissionsTree
                  class="tree-lg"
                  :permissions="el.children"
                  :root="el"
                  :selected="selectedPermissionName"
                  :assigned-permissions="assignedPermissions"
                  :root-name="el.name"
                  :is-checked="false"
                  :hide-parent="true"
                  :is-expanded="selectedApp == el.name"
                  :is-clicked-from-parent="false"
                  :current-depth="0"
                  @select="selectPermission"
                />
              </SmoothReflow>
            </div>
            <div style="margin-left: 20px">
              <TreeTableRowPermissions
                v-if="isSysAdmin"
                class="tree-table-row-permissions"
                :node="{ id: '1', name: $t('permissions.systemAdmin') }"
                :expanded="false"
                :expandable="false"
                :pending="false"
                :padding-sum="0"
                :selected="!!sysAdmin"
                :selectable="true"
                @select="toggleSysAdmin"
              />

              <TreeTableRowPermissions
                v-if="isSysAdmin"
                class="tree-table-row-permissions"
                :node="{ id: '1', name: $t('permissions.releaseNotesAccess') }"
                :expanded="false"
                :expandable="false"
                :pending="false"
                :padding-sum="0"
                :selected="!!releaseNotesAccess"
                :selectable="true"
                @select="toggleReleaseNotesAccess"
              />
            </div>
          </div>
        </div>

        <div v-if="selectedPermissionName === SYSTEM_ADMIN || selectedPermissionName === CONFIG_RELEASE_NOTES_ACCESS" />
        <div
          v-else-if="selectedPermissionName"
          class="col-6"
        >
          <div v-if="!selectedPlantId && (shouldSelectElement || shouldSelectLine)">
            <h6>{{ $t('permissions.choosePlant') }}</h6>
            <div class="border-box">
              <ListSelector
                :list="plants"
                @selected="selectedPlantId = $event"
              />
            </div>
          </div>

          <div v-else-if="!selectedTenantId && shouldSelectGroup">
            <h6>{{ $t('permissions.chooseTenant') }}</h6>
            <div class="border-box">
              <ListSelector
                :list="filteredTenants"
                @selected="selectedTenantId = $event"
              />
            </div>
          </div>

          <div v-else>
            <h6>
              <span v-if="shouldSelectElement || shouldSelectLine">
                <span
                  v-if="plants.length > 1"
                  @click="selectedPlantId = null"
                >
                  <i class="zoom-hoverable ion ion-ios-arrow-dropleft-circle" />
                </span>
                {{ $t('permissions.chooseAsset') }}
              </span>
              <span v-else-if="shouldSelectGroup">
                <span
                  v-if="filteredTenants.length > 1"
                  @click="selectedTenantId = null"
                >
                  <i class="zoom-hoverable ion ion-ios-arrow-dropleft-circle" />
                </span>
                {{ $t('permissions.chooseGroup') }}
              </span>
              <span v-else-if="shouldSelectTenant">
                {{ $t('permissions.chooseTenant') }}
              </span>
              <span v-else>
                {{ $t('permissions.choosePlant') }}
              </span>
            </h6>
            <div class="border-box">
              <div>
                <TreeTablePermissions
                  v-if="shouldSelectElement || shouldSelectLine"
                  :assigned-permissions="selectedAssignedPermissions"
                  class="tree-lg"
                  :pending="pending"
                  :list="plantElements"
                  :root="selectedPlant"
                  :selectable="true"
                  :is-expanded="false"
                  :names-of-permission-being-modified="[selectedPermissionName]"
                  @select="togglePermission($event, selectedPlant, 'PlantId')"
                />
                <TreeTablePermissions
                  v-else-if="shouldSelectTenant"
                  class="tree-lg"
                  :assigned-permissions="selectedAssignedPermissions"
                  :pending="pending"
                  :list="filteredTenants.map(x => ({ ...x, parentId: '0' }))"
                  :root="{ id: '0' }"
                  :selectable="true"
                  :padding="0"
                  :hide-root="true"
                  :is-expanded="false"
                  :names-of-permission-being-modified="[selectedPermissionName]"
                  @select="togglePermission($event, $event, 'TenantId')"
                />
                <TreeTablePermissions
                  v-else-if="shouldSelectGroup"
                  :assigned-permissions="selectedAssignedPermissions"
                  class="tree-lg"
                  :pending="pending"
                  :list="selectedTenantRoles"
                  :root="selectedTenant"
                  :selectable="true"
                  :is-expanded="false"
                  :names-of-permission-being-modified="[selectedPermissionName]"
                  @select="togglePermission($event, selectedTenant, 'TenantId')"
                />

                <TreeTablePermissions
                  v-else
                  class="tree-lg"
                  :assigned-permissions="selectedAssignedPermissions"
                  :pending="pending"
                  :list="plants.map(x => ({ ...x, parentId: '0' }))"
                  :root="{ id: '0' }"
                  :selectable="true"
                  :padding="0"
                  :hide-root="true"
                  :is-expanded="false"
                  :names-of-permission-being-modified="[selectedPermissionName]"
                  @select="togglePermission($event, $event, 'PlantId')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-3" />
    </div>
  </div>
</template>

<script>
import PermissionsTree from '@/components/permissions/PermissionsTree';
import TreeTablePermissions from '@/components/tables/TreeTablePermissions';
import TreeTableRowPermissions from '@/components/tables/TreeTableRowPermissions';
import ListSelector from '@/components/utils/ListSelector';
import { elementType } from '@/utils/dictionary';
import { handleApiError } from '@/utils/handleApiError';
import { CONFIG_RELEASE_NOTES_ACCESS, SYSTEM_ADMIN } from '@core/dict/permissions';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: {
    user: Object,
    roleId: String,
  },
  data: () => ({
    SYSTEM_ADMIN,
    CONFIG_RELEASE_NOTES_ACCESS,
    selectedPlantId: null,
    selectedTenantId: null,
    selectedPermissionName: '',
    selectedApp: '',
    pending: {},
    rolesPending: false,
    roles: [],
    error: null,
  }),
  components: {
    TreeTablePermissions,
    TreeTableRowPermissions,
    PermissionsTree,
    ListSelector,
  },
  computed: {
    ...mapState({
      plants: state => state.structure.plants || [],
      tenantRoles: state => state.role.roles || [],
      allRoles: state => state.role.allRoles || [],
      tenants: state => state.tenant.tenants || [],
      elements: state => state.structure.elements || [],
    }),
    ...mapGetters('core', ['isSysAdmin', 'tenantModules', 'can']),
    ...mapGetters(['permissionTypes', 'permissionTypeList', 'tenantId']),
    filteredTenants() {
      return this.tenants
        .filter(x => this.can(this.$perm.CONFIG_TENANT_ADMIN, x.id)
          || this.can(this.$perm.CONFIG_USER_MANAGEMENT, x.id));
    },
    modulesWithPermissions() {
      return this.permissionTypes
        .filter(x => x.name.toLowerCase() === 'hub'
        || this.tenantModules.some(m => m.module.toLowerCase() === x.name.toLowerCase()));
    },
    selectedAssignedPermissions() {
      return this.assignedPermissions.filter(x => x.name === this.selectedPermissionName);
    },
    assignedPermissions() {
      return this.user?.permissions || [];
    },
    role() {
      if (!this.roleId) return null;
      return this.tenantRoles.find(x => x.id === this.roleId);
    },
    selectedPlant() {
      return this.plants.find(x => x.id === this.selectedPlantId);
    },
    selectedTenant() {
      return this.filteredTenants.find(x => x.id === this.selectedTenantId);
    },
    plantElements() {
      const plantElements = this.elements
        .filter(x => x.plantId === this.selectedPlantId);

      if (this.shouldSelectLine) {
        const lines = plantElements.filter(x => x.type.toLowerCase() === elementType.line.toLowerCase());

        const getParents = (allElements, children) => {
          if (children.length === 0) return [];

          const parentDict = children
            .map(c => allElements.find(x => x.id === c.parentId))
            .filter(x => x)
            .reduce((acc, curr) => {
              acc[curr.id] = curr;
              return acc;
            }, {});

          const parents = Object.values(parentDict);

          return parents.concat(getParents(allElements, parents));
        };

        return lines.concat(getParents(plantElements, lines));
      }

      return plantElements;
    },
    selectedPermission() {
      return this.permissionTypeList.find(x => x.name === this.selectedPermissionName);
    },
    shouldSelectElement() {
      return this.selectedPermission?.arg.toLowerCase() === 'elementid';
    },
    shouldSelectGroup() {
      return this.selectedPermission?.arg.toLowerCase() === 'groupid';
    },
    shouldSelectTenant() {
      return this.selectedPermission?.arg.toLowerCase() === 'tenantid';
    },
    shouldSelectLine() {
      return this.selectedPermission?.arg.toLowerCase() === 'lineid';
    },
    sysAdmin() {
      return this.assignedPermissions.find(x => x.name.toLowerCase() === SYSTEM_ADMIN.toLowerCase());
    },
    releaseNotesAccess() {
      return this.assignedPermissions.find(x => x.name === CONFIG_RELEASE_NOTES_ACCESS);
    },
    selectedTenantRoles() {
      return this.allRoles
        .map(x => ({ ...x, parentId: x.tenantId }))
        .filter(x => x.tenantId === this.selectedTenantId);
    },
  },
  methods: {
    ...mapActions([
      'setUserPermission',
      'setRolePermission',
      'resetUserPermission',
      'resetRolePermission',
    ]),
    toggleSysAdmin() {
      this.selectedPermissionName = SYSTEM_ADMIN;
      this.togglePermission({ id: '1' }, null, null);
    },
    toggleReleaseNotesAccess() {
      this.selectedPermissionName = CONFIG_RELEASE_NOTES_ACCESS;
      this.togglePermission({ id: '1' }, null, null);
    },
    selectPermission(permission) {
      if (permission === this.selectedPermissionName) {
        this.selectedPermissionName = null;
      } else {
        this.selectedPermissionName = permission;

        if (this.plants.length === 1) {
          this.selectedPlantId = this.plants[0].id;
        }
      }
    },
    togglePermission(arg, rootArg, rootType) {
      if (!arg) return;

      const rootId = rootArg !== null ? rootArg.id : null;
      const ev = arg.id;

      const perm = this.assignedPermissions.find(x => x.name === this.selectedPermissionName
        && x.value.toLowerCase() === ev.toLowerCase());

      this.$set(this.pending, ev, true);
      this.error = null;

      if (!perm) {
        this.addPermission(ev, rootId, rootType)
          .catch(({ response }) => {
            this.error = handleApiError(response);
          })
          .finally(() => {
            this.$set(this.pending, ev, false);
          });
      } else {
        this.removePermission(ev)
          .catch(({ response }) => {
            this.error = handleApiError(response);
          })
          .finally(() => {
            this.$set(this.pending, ev, false);
          });
      }
    },
    addPermission(val, rootId, rootType) {
      return this.addUserPermission(val, rootId, rootType);
    },
    addUserPermission(val, rootId, rootType) {
      return this.setUserPermission({
        params: {
          id: this.user.id,
        },
        data: {
          name: this.selectedPermissionName,
          value: val,
          rootId,
          rootType,
        },
      })
        .then(() => {
          this.$root.$bvToast.toast(this.$t('permissions.updateSuccessfully'), {
            title: this.$t('general.requestReceived'),
            variant: 'success',
            appendToast: true,
          });
        });
    },
    removePermission(val) {
      return this.removeUserPermission(val);
    },
    removeUserPermission(val) {
      const permission = this.user.permissions.find(x => x.name === this.selectedPermissionName
        && x.value.toLowerCase() === val.toLowerCase());

      if (!permission) return Promise.reject();

      return this.resetUserPermission({
        params: {
          id: this.user.id,
          permissionId: permission.id,
        },
      })
        .then(() => {
          this.$root.$bvToast.toast(this.$t('permissions.updateSuccessfully'), {
            title: this.$t('general.requestReceived'),
            variant: 'success',
            appendToast: true,
          });
        });
    },
    removeRolePermission(val) {
      if (!this.selectedPermissionName) return Promise.reject();

      const permission = this.role.permissions.find(x => x.name === this.selectedPermissionName
        && x.value.toLowerCase() === val.toLowerCase());

      if (!permission) return Promise.reject();

      return this.resetRolePermission({
        params: {
          tenantId: this.tenantId,
          id: this.role.id,
          permissionId: permission.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

  .tree-table-row-permissions{
    margin-top: 5px !important;
    margin-left: -40px !important;
  }

  .border-box {
    background-color: rgb(245, 245, 245);
    padding: 10px;
    border-radius: 10px;
  }

  .zoom-hoverable {
    cursor: pointer;

    &:hover {
      transform: scale(1.5);
    }
  }

  .permission-header {
    padding: 2px 5px;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
      border-color: #333;
    }

    .app-logo {
      max-height: 25px;
      padding-right: 5px;
    }

  }
</style>
