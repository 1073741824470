import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const imports = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getImporterJob',
    path: ({ tenantId, jobId }) => `tenant/${tenantId}/imports/job/${jobId}/result`,
  })
  .get({
    action: 'getImporterJobs',
    property: 'jobs',
    path: ({ tenantId, take, skip }) =>
      `tenant/${tenantId}/imports/last/${skip}/${take}/overview`,
  })
  .getStore();

export default imports;
