<template>
  <div>
    <div
      class="text-left mt-5 ml-5"
    >
      <img
        class="logix-icon"
        src="~@core/assets/logos/logix.png"
      >
    </div>
    <div class="d-flex align-items-center full-height">
      <div
        class="form-container flex-grow-1 mx-auto"
        :style="products ? '' : 'max-width: 450px'"
      >
        <div v-if="company">
          <h3 class="mb-5 pb-3 text-center">
            <span style="font-size: 30px">
              <i class="ion ion-md-business mr-2" />
            </span>
            {{ $t('registration.companySetUp') }}
          </h3>
          <CompanyForm
            @update:company="tenant = $event"
            @next="company = false; profile = true"
          />
        </div>
        <div v-if="profile">
          <h3 class="mb-5 pb-3 text-center">
            <i class="ion ion-md-person mr-2" />
            {{ $t('registration.profileSetUp') }}
          </h3>
          <UserForm
            @update:user="user = $event"
            @create="profile = false; login = true; create()"
          />
        </div>
        <div v-if="login && !error">
          <h3 class="mb-5 pb-3 text-center">
            <i class="ion ion-md-color-wand mr-2" />
            {{ $t('registration.successful') }}
          </h3>
          <form
            class="form-white"
          >
            <div class="position-relative">
              <button
                class="btn btn-ilabo btn-block position-relative button"
                type="button"
                @click="route()"
              >
                <span>
                  {{ $t('registration.getStarted') }}
                  <i class="ion ion-ios-arrow-forward pl-2" />
                </span>
              </button>
            </div>
          </form>
        </div>
        <div
          v-if="error"
          class="text-center"
        >
          <div>
            {{ error }}
          </div>
          <div>
            <button
              class="btn btn-ilabo btn-block position-relative button"
              @click="login = false; company = true; error = null"
            >
              <span>
                {{ $t('registration.tryAgain') }}
                <i class="ion ion-ios-arrow-forward pl-2" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CompanyForm from './CompanyForm';
import UserForm from './UserForm';

export default {
  data() {
    return {
      loading: true,
      invalid: false,
      company: true,
      profile: false,
      products: false,
      login: false,
      tenant: {},
      user: {},
      error: null,
    };
  },
  components: {
    CompanyForm,
    UserForm,
  },
  methods: {
    ...mapActions(['createTenant']),
    ...mapActions('oidcStore', ['authenticateOidc']),
    create() {
      const newTenant = {
        tenant: this.tenant,
        user: this.user,
      };
      this.createTenant({
        data: newTenant,
      })
        .catch(({ response }) => {
          this.handleApiError(response);
        });
    },
    route() {
      this.$router.push('/')
        .catch(() => {});
    },
    handleApiError(res) {
      if (res.data && res.data.message) {
        this.error = res.data.message;
      } else {
        this.error = this.$t('error.unknown');
      }
    },
  },
};

</script>

<style lang="scss" scoped>
 .logix-icon {
   height: 50px;
   top: 20px;
   left: 20px;
   position: absolute;
 }

 .form-container {
  width: 100%;
 }

 .full-height {
   height: 100vh;
 }

 .button {
  width: 100%;
  max-width: 200px;
  margin: auto;
  margin-top: 50px;
  border-radius: 30px;
 }
</style>
