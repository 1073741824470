<template>
  <div>
    <div class="d-flex justify-content-between page-header">
      <div class="d-flex text-left align-items-center title">
        <i class="fas fa-user-group pr-2" />
        {{ $t('people.roles') }}
      </div>
      <div class="d-flex align-items-center">
        <button
          v-if="canAdd"
          class="btn btn-success btn-sm mr-2 action-button"
          @click="selected = null; form = true"
        >
          <i class="fas fa-add pr-1" />
          {{ $t('role.add') }}
        </button>
      </div>
    </div>

    <div class="px-3">
      <div class="mb-2">
        <BFormInput
          ref="search"
          v-model="search"
          type="search"
          :placeholder="`${$t('general.search')}...`"
          class="search"
          @click="$refs.search.select()"
        />
      </div>
      <SimpleTable
        :items="filteredRoles"
        :fields="columns"
        sort-by="Name"
        class="user-table"
        @row-clicked="select"
      />
    </div>

    <LModal
      :show.sync="form"
      size="lg"
    >
      <RoleDetailsForm
        :role="selected"
        @close="form = false; selectd = null"
      />
    </LModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import createSearch from '@core/utils/search';
import SimpleTable from '@core/components/SimpleTable';
import RoleDetailsForm from '@/components/people/roles/RolesDetailsForm';

export default {
  data: () => ({
    selected: null,
    form: false,
    search: '',
    permissionsForm: false,
  }),
  components: {
    SimpleTable,
    RoleDetailsForm,
  },
  computed: {
    ...mapGetters(['permissionTypeList', 'tenantId']),
    ...mapGetters('core', ['can']),
    ...mapState({
      roles: state => state.role.roles || [],
    }),
    rolesWithPermissions() {
      return this.roles
        .map(x => ({
          ...x,
          allPermissions: (x.permissions || [])
            .map(n => this.permissionTypeList.find(y => y.name === n)?.text)
            .filter(y => y),
        }))
        .map(x => ({
          ...x,
          allPermissions: x.allPermissions.join(', '),
          permissions: x.allPermissions.slice(0, 3).join(', ') + ((x.permissions || []).length > 3 ? '...' : ''),
        }));
    },
    filteredRoles() {
      if (!this.canAdd) return [];
      const search = createSearch(this.search);
      return this.rolesWithPermissions
        .filter(x => search(x.name) || search(x.allPermissions));
    },
    canAdd() {
      return this.can(this.$perm.CONFIG_USER_MANAGEMENT, this.tenantId)
        || this.can(this.$perm.CONFIG_TENANT_ADMIN, this.tenantId);
    },
    columns() {
      return [
        {
          key: '',
        },
        {
          key: 'name',
          label: this.$t('general.name'),
        },
        {
          key: 'permissions',
          text: this.$t('account.permissions'),
        },
      ];
    },
  },
  watch: {
    form() {
      if (!this.form) {
        this.getUsersAndRoles();
      }
    },
  },
  methods: {
    ...mapActions([
      'getRoles',
      'getUsers',
    ]),
    getUsersAndRoles() {
      this.getRoles({ params: { tenantId: this.tenantId, save: true } });
      this.getUsers({ params: { tenantId: this.tenantId } });
    },
    select(element) {
      this.selected = element;
      this.form = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../page-style.scss";
.card {
  background-color: $grey;
  display: inline;
}
.active-card {
  background-color: $ilabo;
}
</style>
