export default {
  props: {
    delay: {
      type: Number,
      default: 500,
    },
    mobileBreakpoint: {
      type: Number,
      default: 768,
    },
  },
  methods: {
    handler() {
      if (this.timeout) { clearTimeout(this.timeout); }

      this.timeout = setTimeout(() => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        this.$emit('screenWidth', width);
        this.$emit('screenHeight', height);
        this.$emit('mobile', width < this.mobileBreakpoint);
      }, this.delay);
    },
  },
  created() {
    window.addEventListener('resize', this.handler.bind(this));
    this.handler();
  },
  destroyed() {
    window.addEventListener('resize', this.handler.bind(this));
  },
  render() { return true; },
};
