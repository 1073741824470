const getFunctions = (names, component) => {
  if (names.constructor === Array) {
    return names.map(name => component[name]);
  } if (names.constructor === String) {
    return [component[names]];
  }
  return [];
};

const loopRequest = (names, loopTime) => {
  const name = `loopRequest${loopTime || ''}`;
  function makeLoop() {
    this[name].number += 1;
    const {
      timeout, toRun, time, stopped,
    } = this[name];
    if (timeout) { clearTimeout(timeout); }

    const next = () => {
      if (timeout) { clearTimeout(timeout); }
      if (stopped) return;
      /* TODO: Check if there're no duplicated requests using number prop */
      this[name].timeout = setTimeout(() => {
        makeLoop.call(this);
      }, time);
    };

    const promises = toRun
      .map(fun => fun())
      .filter(el => el && el.constructor === Promise);

    Promise.all(promises)
      .then(
        () => { next(); },
        () => { next(); },
      );
  }

  return {
    data: () => ({
      [name]: {
        time: loopTime || 10000,
        number: 0,
        toRun: [],
        timeout: null,
        stopped: false,
      },
    }),
    destroyed() {
      const { timeout } = this[name];
      if (timeout) { clearTimeout(timeout); }
      this[name].stopped = true;
    },
    created() {
      this[name].stopped = false;
      this[name].toRun = [...this[name].toRun, ...getFunctions(names, this)];
      makeLoop.call(this);
    },
  };
};

export default loopRequest;
