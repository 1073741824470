<template>
  <div>
    <slot
      :row="{
        node: root,
        expanded,
        expandable: filtered.length > 0,
        padding: paddingSum,
      }"
    >
      <TreeTableRow
        v-if="root.id"
        :node="root"
        :columns="columns"
        :expanded="expanded && filtered.length > 0"
        :expandable="filtered.length > 0"
        :padding-sum="paddingSum"
        :selected="isSelected"
        :selectable="selectable"
        :disabled="reduceDisabled(root)"
        :reduce="reduce"
        :small-variant="smallVariant"
        :show-taxonomy-based-tree="showTaxonomyBasedTree"
        @click="toggle"
        @select="$emit('select', $event)"
        @edit="$emit('edit', root)"
        @add="$emit('add', root)"
      />
    </slot>
    <SmoothReflow>
      <div
        v-for="element in filtered"
        :key="element.id"
        :style="{ 'padding-left': !root.id ? '' : `${padding}px` }"
      >
        <TreeTable
          v-if="expanded"
          :columns="columns"
          :list="list"
          :root="element"
          :padding-sum="root.id ? (paddingSum + padding) : paddingSum"
          :selectable="selectable"
          :selected="selected"
          :reduce-disabled="reduceDisabled"
          :reduce="reduce"
          :small-variant="smallVariant"
          :show-taxonomy-based-tree="showTaxonomyBasedTree"
          @select="$emit('select', $event)"
          @edit="$emit('edit', $event)"
          @add="$emit('add', $event)"
        >
          <template #default="scope">
            <slot v-bind="scope" />
          </template>
        </TreeTable>
      </div>
    </SmoothReflow>
  </div>
</template>

<script>
import TreeTableRow from './TreeTableRow';

export default {
  name: 'TreeTable',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    root: {
      type: Object,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    paddingSum: {
      type: Number,
      default: 0,
    },
    padding: {
      type: Number,
      default: 20,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    smallVariant: {
      type: Boolean,
      default: false,
    },
    reduceDisabled: {
      type: Function,
      default: () => false,
    },
    reduce: {
      type: Function,
      default: x => x.id,
    },
    showTaxonomyBasedTree: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    expanded: true,
    highlighted: false,
  }),
  components: {
    TreeTableRow,
  },
  computed: {
    filtered() {
      if (!this.list) return [];
      const filtered = this.list.filter(e => e && e[
        this.showTaxonomyBasedTree
          ? 'taxonomyParentId'
          : 'parentId'
      ] === this.root.id);
      try {
        return this.sortByName(filtered);
      } catch {
        return this.sortByDescription(filtered);
      }
    },
    isSelected() {
      if (!this.selected) return false;
      return this.selected.some(x => x.id === this.root.id);
    },
  },
  methods: {
    getElement(id) {
      return this.list.find(el => el.id === id);
    },
    highlight(select) {
      this.highlighted = select;
      if (!this.highlightParent) return;
      this.$emit('highlight', select);
    },
    toggle(expand) {
      this.expanded = expand;
    },
    sortByName(list) {
      return list.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        }));
    },
    sortByDescription(list) {
      return list.sort((a, b) =>
        (a.description || a.code).localeCompare((b.description || b.code),
          undefined,
          {
            numeric: true,
            sensitivity: 'base',
          }));
    },
  },
};
</script>
