<template>
  <Tippy
    ref="popup"
    theme="light"
    interactive
    :distance="10"
    arrow
    max-width="550px"
    placement="bottom-end"
    sticky
    :hide-on-click="!showPushPermission"
    :trigger="showPushPermission ? 'manual' : 'click'"
    :on-show="onShow"
    :on-hide="onHide"
  >
    <template #trigger>
      <div
        id="news"
        class="app-node position-relative"
        @click="showPushPermission = false"
      >
        <div class="icon">
          <i class="fas fa-bell" />
        </div>
        <span
          v-if="news && news < 10"
          class="number-badge"
        >{{ news }}</span>
        <span
          v-else-if="news"
          class="number-badge"
        >9+</span>
      </div>
    </template>

    <PushStatus
      v-if="serviceWorker"
      v-show="showPushPermission"
      :style="isMobile && 'width: 300px' || 'width: 485px'"
      :auto-register="true"
      @show="showPushStatus"
      @cancel="cancelPush"
    />
    <NotificationsDropdown
      v-show="!showPushPermission"
      :style="isMobile && 'width: 300px' || 'width: 485px'"
      :show="newsShown && !showPushPermission"
      :notification-action="notificationAction"
      :hide-list-button="hideListButton"
      @scroll.stop.prevent
      @close="closePopovers"
      @number="news = $event"
    />
  </Tippy>
</template>

<script>
import { mapGetters } from 'vuex';
import PushStatus from './dropdowns/PushStatus';
import NotificationsDropdown from './dropdowns/NotificationsDropdown';

export default {
  props: {
    notificationAction: Function,
    hideListButton: Boolean,
  },
  data: () => ({
    showPushPermission: false,
    newsShown: false,
    news: 0,
  }),
  components: {
    PushStatus,
    NotificationsDropdown,
  },
  computed: {
    ...mapGetters(['isMobile']),
    ...mapGetters('core', ['serviceWorker']),
  },
  methods: {
    closePopovers() {
      if (this.$refs.popup) {
        this.$refs.popup.tippy().hide();
      }
    },
    cancelPush() {
      this.closePopovers();
      this.$nextTick(() => {
        localStorage.setItem('notifications:hidePush', true);
        this.showPushPermission = false;
      });
    },
    showPushStatus() {
      if (!this.serviceWorker) return;
      const hidePush = localStorage.getItem('notifications:hidePush');
      if (!hidePush) {
        this.showPushPermission = true;
        this.$refs.popup.tippy().show();
      }
    },
    onShow() {
      this.newsShown = true;
    },
    onHide() {
      this.newsShown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/vars.icss";

  .number-badge {
    background-color: $red;
    position: absolute;
    padding: 0px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    top: 0;
    color: white;
    right: 0;
  }
</style>
