<template>
  <div class="custom-tree">
    <LiquorTree
      ref="tree"
      :data="value"
      :options="treeOptions"
      @node:dragging:finish="onDragFinish"
    >
      <template #default="{node}">
        <div
          class="draggable-item"
          :class="{disabled}"
        >
          <div class="tree-line" />

          <div
            v-if="!disabled"
            class="grip"
          >
            <i class="fas fa-grip-lines" />
          </div>

          <div class="d-flex">
            <BInput
              v-model="node.data.description"
              :placeholder="$t('taxonomies.enterName')"
              :disabled="disabled"
              class="mr-2 flex-grow"
              @input="handleInput"
            />

            <BInput
              v-model="node.data.code"
              :placeholder="$t('taxonomies.enterCode')"
              class="core-input"
              :disabled="disabled"
              :class="{'mr-2': !hideLevelInput}"
              @input="handleInput"
            />

            <i
              v-if="hideLevelInput"
              v-b-tooltip.leftbottom
              class="fas fa-lock ml-3 mr-2 my-auto"
              :title="$t('taxonomies.levelLockedTooltip')"
              :class="{
                'fa-lock': node.data.policy === 'False',
                'fa-lock-open': node.data.policy === 'True',
              }"
              style="cursor: pointer; min-width: 16px;"
              @click.stop="switchLevelPolicy(node)"
            />

            <BSelect
              v-if="!hideLevelInput"
              v-model="node.data.taxonomyLevelId"
              :placeholder="$t('taxonomies.pickLevel')"
              :disabled="disabled"
              class="level-input"
              @input="handleInput"
            >
              <BSelectOption
                value=""
                disabled
              >
                {{ $t('taxonomies.pickLevel') }}
              </BSelectOption>
              <BSelectOption
                v-for="level in levelsOfNode(node)"
                :key="level.id"
                :value="level.id"
              >
                {{ level.description || level.code }}
              </BSelectOption>
            </BSelect>
          </div>

          <div
            v-if="!disabled"
            class="delete"
          >
            <i
              class="far fa-times-circle"
              style="cursor: pointer;"
              @click="removeNode(node.id)"
            />
          </div>

          <div
            v-if="haveNodeError(node)"
            v-b-tooltip.hover
            :title="$t('taxonomies.nodeWarning')"
            class="error"
          >
            <i class="fas fa-circle-exclamation" />
          </div>
        </div>
      </template>
    </LiquorTree>
  </div>
</template>

<script>
import LiquorTree from 'liquor-tree';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    hideLevelInput: Boolean,
    levelsGraph: {
      type: Object,
      default: () => ({}),
    },
    nodesGraph: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    treeOptions: {
      multiple: false,
      dnd: true,
      parentSelect: true,
    },
  }),
  components: { LiquorTree },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    haveNodeError(node) {
      if (!this.nodesGraph.depth) return false;
      return this.levelsOfNode(node).length === 0;
    },
    levelsOfNode(node) {
      const parentId = this.nodesGraph.parent(node.data.id);
      if (parentId) {
        const parent = this.nodesGraph.node(parentId);
        const levelsIds = this.levelsGraph.childrenOf(parent.taxonomyLevelId);
        return levelsIds.map(id => this.levelsGraph.node(id));
      }
      const depth = this.nodesGraph.depth(node.data.id);
      return this.levelsGraph.nodesInDepth(depth).map(id => this.levelsGraph.node(id));
    },
    transformTreeModelToArray(treeData) {
      const iterate = array => {
        const updatedArray = [];
        array.forEach(o => {
          o.expand();
          o.unselect();
          const children = iterate(o.children);
          updatedArray.push({
            ...o,
            children,
          });
        });
        return updatedArray;
      };
      return iterate(treeData);
    },
    onDragFinish(node) {
      if (this.nodesGraph?.depth && node) {
        this.$nextTick(() => {
          this.updateLevels(node);
        });
      }
      this.innerValue = this.transformTreeModelToArray(this.$refs.tree.model);
    },
    addNode() {
      const taxonomyLevelId = this.nodesGraph?.depth
        ? this.levelsGraph
          .nodesInDepth(2)[0] || null
        : undefined;
      const newNode = {
        data: {
          id: new Date().getTime().toString(),
          text: '',
          code: '',
          policy: 'True',
          taxonomyLevelId,
        },
        state: {
          selectable: true,
          expanded: true,
        },
      };
      this.$refs.tree.append(newNode);
      this.onDragFinish();
    },
    removeNode(id) {
      this.$refs.tree.remove({ id });
      this.$nextTick(() => {
        this.innerValue = this.transformTreeModelToArray(this.$refs.tree.model);
      });
    },
    updateLevels(node) {
      // eslint-disable-next-line no-param-reassign
      node.data.taxonomyLevelId = this.levelsOfNode(node)[0]?.id || null;
    },
    handleInput() {
      this.innerValue = this.transformTreeModelToArray(this.$refs.tree.model);
    },
    switchLevelPolicy(node) {
      // eslint-disable-next-line no-param-reassign
      node.data.policy = node.data.policy === 'True' ? 'False' : 'True';
      this.handleInput();
    },
  },
  created() {
    setTimeout(() => {
      if (this.$refs.tree) this.$refs.tree.expandAll();
    }, 1);
  },
};
</script>

<style lang="scss" scoped>
.custom-tree :deep() {
  .tree-root {
    margin-bottom: 0 !important;
    padding-right: 40px;
  }

  .tree-node.selected>.tree-content {
    background-color: transparent;
  }

  .drag-on>.tree-content {
    outline: 2px solid #838383 !important;
    border-radius: 4px;
  }

  .drag-above>.tree-content::before, .drag-below>.tree-content::after {
    background-color: #838383;
    border: 3px solid #838383;
    background-clip: padding-box;
    border-bottom-color: transparent;
    border-top-color: transparent;
  }

  .tree-content {
    padding: 0;
    margin: 2px 0;

    &:hover {
      background: transparent !important;
    }

    .tree-arrow {
      opacity: 0;
      width: 0px;
      margin-left: 0;
    }

    .tree-anchor {
      margin: 0 !important;
      padding: 0 !important;
    }

    .draggable-item {
      background: #F4F4F4;
      padding: 5px 50px;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      position: relative;
      cursor: grab !important;

      .form-control {
        &:disabled {
          background-color: #F4F4F4 !important;
        }
      }

      &:hover {
        background: #ececec !important;
      }

      &.disabled {
        padding: 8px;
        cursor: default !important;

        &:hover {
          background: #F4F4F4!important;
        }
      }

      .tree-line {
        position: absolute;
        left: -12px;
        top: -5px;
        width: 1px;
        height: calc(100% + 5px);
        background-color: #F4F4F4;
      }

      .grip {
        position: absolute;
        left: 26px;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: #A6A6A6;
      }

      .delete {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
        color: #A6A6A6;
      }

      .error {
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
        color: #F9414D;
      }

      .core-input, .level-input {
        min-width: 200px;
        max-width: 200px;
      }
    }
  }
}
</style>
