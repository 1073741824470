<template>
  <div>
    <Loader v-if="pending" />
    <LTable
      v-else
      :list="importedUsers"
      :columns="columns"
      :searchable="false"
      class="userList text-left"
      style="font-size: .675rem;"
    >
      <template #buttons="{ item }">
        <div
          v-if="!item.importResult.resultStatus"
          class="text-right"
        >
          <button
            class="btn btn-sm table-btn icon-btn mx-2"
            style="margin-bottom: -2px"
            @click="select(item)"
          >
            <i class="ion ion-md-create" />
          </button>
        </div>
      </template>

      <template #importResult="{ item }">
        <div v-if="!item.importResult.resultStatus">
          <div class="text-danger text-center font-weight-bold">
            <i class="fas fa-times pr-2" />
            {{ $t('import.notSaved') }}
          </div>
        </div>
        <div
          v-else
          class="d-flex align-items-center justify-content-center h-100"
        >
          <div class="text-success text-center font-weight-bold">
            <i class="fas fa-check pr-2" />
            {{ $t('import.saved') }}
          </div>
        </div>
      </template>
    </LTable>

    <LModal
      :show.sync="resultModal"
    >
      <UserDetails
        v-if="selectedUser"
        :user="selectedUser"
        :allow-delete-user="false"
        :external-error="selectedUser.error"
        @close="close()"
        @update="onSave"
        @create="onSave"
      />
      <div
        v-if="userRolesErrors"
        class="text-danger small mt-2"
      >
        <div
          v-for="error in userRolesErrors"
          :key="error"
        >
          {{ error }}
        </div>
      </div>
    </LModal>
  </div>
</template>

<script>
import LTable from '@core/components/LTable';
import UserDetails from '@/components/people/users/UserDetails';
import { mapState, mapActions, mapGetters } from 'vuex';
import parseImportEntities from '@/components/utils/parseImportEntities';

export default {
  props: {
    imported: Object,
  },
  data: () => ({
    resultModal: false,
    importedUsers: [],
    selectedUser: null,
    pending: true,
    Statuses: {
      Saved: 'Saved',
      NotSaved: 'NotSaved',
      ActionRequired: 'ActionRequired',
    },
  }),
  components: {
    LTable,
    UserDetails,
  },
  computed: {
    ...mapState({
      users: state => state.user.users || [],
      allRoles: state => state.role.roles || [],
    }),
    ...mapGetters(['tenantId']),
    userRolesErrors() {
      if (!this.selectedUser) { return []; }
      const failedUserRoleImports = this.importedRoleUsers
        .filter(x => x.email.toLowerCase() === this.selectedUser.email.toLowerCase() && !x.importResult.resultStatus);
      return failedUserRoleImports
        .map(x =>
          `${this.$t('import.unableToAssignToRole')} ${x.role}: ${x.error?.message || this.$t('error.unknown')}`);
    },
    columns() {
      return [
        {
          key: 'lastName',
          text: this.$t('users.lastName'),
        },
        {
          key: 'firstName',
          text: this.$t('users.firstName'),
        },
        {
          key: 'email',
          text: this.$t('users.email'),
        },
        {
          key: 'roles',
          text: this.$t('users.roles'),
        },
        {
          key: 'importResult',
          text: '',
        },
        {
          key: 'buttons',
          text: '',
        },
      ];
    },
  },
  methods: {
    ...mapActions(['getUsers']),
    select(element) {
      this.selectedUser = this.importedUsers.find(u => u.email === element.email);
      this.resultModal = true;
    },
    close() {
      this.resultModal = false;
    },
    onSave(user) {
      const userResultId = this.selectedUser.importResult.resultId;
      const editedUserIndex = this.importedUsers.findIndex(r => r.importResult.resultId === userResultId);
      this.$set(this.importedUsers, editedUserIndex, {
        ...user,
        importResult: {
          ...this.importedUsers[editedUserIndex].importResult,
          entity: JSON.stringify(user),
          resultStatus: true,
        },
      });
    },
    includeUserRoleStatusesInUserStatuses() {
      const userEmailsWithRoles = [...new Set(this.importedRoleUsers.map(x => x.email))];
      const additionalUsersFromUserRolesSheet = userEmailsWithRoles
        .filter(x => !this.importedUsers.some(imp => imp.email.toLowerCase() === x.toLowerCase()));

      for (let i = 0; i < additionalUsersFromUserRolesSheet.length; i += 1) {
        const userToBeAdded = this.users
          .find(x => x.email.toLowerCase() === additionalUsersFromUserRolesSheet[i].toLowerCase());
        if (userToBeAdded) {
          this.importedUsers.push({
            ...userToBeAdded,
            importResult: {
              resultStatus: true,
            },
          });
        }
      }
      const failedUserRolesImports = this.importedRoleUsers.filter(x => x.importResult.resultStatus === false);
      failedUserRolesImports.forEach(userRole => {
        const user = this.importedUsers
          .find(u => u.email.toLowerCase() === userRole.email.toLowerCase());
        user.importResult.resultStatus = false;
      });
    },
  },
  created() {
    this.getUsers({ params: { tenantId: this.tenantId } });
    const parsedImportedUsers = parseImportEntities(this.imported.data.users);
    this.importedRoleUsers = parseImportEntities(this.imported.data.userRoles);
    this.importedUsers = parsedImportedUsers.map(user => {
      const importedRoles = this.importedRoleUsers.filter(y => y.email === user.email)
        .map(x => x.team);
      const userWithTeam = user;
      userWithTeam.roles = importedRoles.toString();
      return userWithTeam;
    });
    this.includeUserRoleStatusesInUserStatuses();
    this.pending = false;
  },
};
</script>

<style lang="scss">

.userList {
  .table-row, .column-names {
    .col:last-child {
      max-width: 50px;
    }
  }
  .table-row {
    .col:not(:last-child) {
      font-size: .75rem;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
