import Vapi from 'vuex-rest-api';
import { notify as axios } from '../api/axios';

const store = new Vapi({
  axios,
  state: {
    connection: null,
    explorer: null,
  },
})
  .get({
    action: 'getNotifications',
    property: 'list',
    path: ({ utcOffset }) => `v2/notifications/user/${utcOffset}`,
  })
  .put({
    action: 'markAsRead',
    path: () => 'v2/notifications/read',
  })
  .post({
    action: 'addPushSubscription',
    path: '/push',
  })
  .getStore();

export default store;
