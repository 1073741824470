<template>
  <div class="d-flex align-items-center breadcrumbs-box">
    <p
      v-if="!isRootPage"
      class="mb-0 mr-3 text-muted"
      style="cursor: pointer;"
      @click="goToRootPage"
    >
      {{ $t('taxonomies.classifications') }}
      <i class="fas fa-angle-right ml-2" />
    </p>
    <h4
      v-if="isRootPage"
      class="mb-0"
    >
      {{ $t('taxonomies.classifications') }}
    </h4>

    <h4
      v-if="isCreatePage"
      class="mb-0"
    >
      {{ $t('taxonomies.createNewClassification') }}
    </h4>
    <h4
      v-if="isDetailsPage"
      class="mb-0"
    >
      {{ currentTaxonomyDescriptionOrCode }}
    </h4>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TaxonomiesBreadcrumbs',
  computed: {
    ...mapGetters([
      'taxonomies',
    ]),
    isRootPage() {
      return this.$route.name === 'taxonomies-list';
    },
    isCreatePage() {
      return this.$route.name === 'taxonomies-create';
    },
    isDetailsPage() {
      return this.$route.name === 'taxonomies-details';
    },
    currentTaxonomyDescriptionOrCode() {
      const { id } = this.$route.params;
      const taxonomy = this.taxonomies.find(t => t.id === id);
      if (!taxonomy) return '-';
      return taxonomy?.description || taxonomy?.code;
    },
  },
  methods: {
    goToRootPage() {
      const rootId = this.$route.path.split('/')[1];
      this.$router.push(`/${rootId}/taxonomies`);
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs-box {
  height: 40px;
}
</style>
