<template>
  <div class="container">
    <h4>
      {{ $t('keepUp.workBoostIntegration') }}
    </h4>
    <div class="settings-input mt-4">
      <div style="position:relative">
        <BFormCheckbox
          v-model="useWorkBoost"
        >
          <label>
            {{ $t('keepUp.useWorkBoost') }}
          </label>
        </BFormCheckbox>
      </div>
    </div>
    <div class="settings-input mt-2">
      <label>
        {{ $t('keepUp.project') }}:
      </label>
      <BFormSelect
        v-model="projectId"
        :options="projectOptions"
        :disabled="!useWorkBoost"
      />
    </div>
    <div class="settings-input mt-3">
      <label>
        {{ $t('keepUp.type') }}:
      </label>
      <BFormSelect
        v-model="typeId"
        :options="typeOptions"
        :disabled="!useWorkBoost"
      />
    </div>
    <div class="settings-input mt-3">
      <label>
        {{ $t('keepUp.initialState') }}:
      </label>
      <BFormSelect
        v-model="stateId"
        :options="stateOptions"
        :disabled="!useWorkBoost"
      />
    </div>
    <div class="mt-4">
      <button
        v-if="!pending"
        class="btn btn-success btn-sm"
        type="submit"
        :disabled="submitDisabled"
        @click="submit"
      >
        <i class="ion ion-md-checkmark pr-2" />
        {{ $t('general.save') }}
      </button>
      <Loader
        v-else
        class="text-left"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  props: {
    config: Object,
  },
  data: () => ({
    pending: false,
    useWorkBoost: false,
    projectId: null,
    typeId: null,
    stateId: null,
  }),
  computed: {
    ...mapGetters(['tenantId']),
    ...mapState({
      projects: state => state.product.workBoostProjects || [],
      plants: state => state.structure.plants || [],
    }),
    projectOptions() {
      return this.projects.map(x => ({
        text: x.name,
        value: x.id,
      }));
    },
    typeOptions() {
      if (!this.projectId) return [];
      const project = this.projects.find(x => x.id === this.projectId);
      if (!project) return [];
      return project.questTypes.map(x => ({
        text: x.name,
        value: x.id,
      }));
    },
    stateOptions() {
      if (!this.projectId) return [];
      const project = this.projects.find(x => x.id === this.projectId);
      if (!project) return [];
      return project.states.map(x => ({
        text: x.name,
        value: x.id,
      }));
    },
    submitDisabled() {
      return (!this.plants)
        || (this.useWorkBoost && (!this.projectId || !this.stateId));
    },
  },
  watch: {
    config(v) {
      if (!v) return;
      this.plantId = v.plantId;
      this.useWorkBoost = v.useWorkBoost;
      this.projectId = v.projectId;
      this.typeId = v.typeId;
      this.stateId = v.initialStateId;
    },
  },
  methods: {
    ...mapActions(['setProductConfig']),
    submit() {
      this.pending = true;
      this.setProductConfig({
        params: {
          tenantId: this.tenantId,
        },
        data: {
          productName: 'keepup',
          configType: 'keepup-workboost-integration',
          config: this.getConfig(),
        },
      })
        .finally(() => {
          this.pending = false;
        });
    },
    // TODO: Replace this with multiple plant settings
    getPlantId() {
      return this.plantId || this.plants[0].id;
    },
    getConfig() {
      return JSON.stringify({
        plantId: this.getPlantId(),
        useWorkBoost: this.useWorkBoost,
        projectId: this.useWorkBoost ? this.projectId : null,
        typeId: this.useWorkBoost ? this.typeId : null,
        initialStateId: this.useWorkBoost ? this.stateId : null,
      });
    },
  },
  created() {
    if (this.config) {
      this.plantId = this.config.plantId;
      this.useWorkBoost = this.config.useWorkBoost;
      this.projectId = this.config.projectId;
      this.typeId = this.config.typeId;
      this.stateId = this.config.initialStateId;
    }
  },
};
</script>

<style lang="scss" scoped>
  .settings-input {
      max-width: 350px;;
  }
</style>
