import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    licenses: [],
  },
})
  .get({
    action: 'getPlantLicenseHistory',
    property: 'plantLicenseHistory',
    path: ({ plantId, timestamp }) => `/license/plant/${plantId}/history/${timestamp}`,
  })
  .get({
    action: 'getElementLicenses',
    property: 'licenses',
    path: ({ plantId, timestamp }) => `/license/plant/${plantId}/${timestamp}`,
  })
  .getStore();

export default store;
