<template>
  <div
    :class="{ clickable }"
    class="d-flex"
    style="line-height: 18px"
    @click="toggle"
  >
    <div class="color-box-container">
      <div
        :style="{ 'background-color': value ? displayColor : backgroundColor }"
        class="color-box square-box pull-left mr-1"
      >
        <i
          v-if="value"
          class="fas fa-check"
        />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    color: String,
    backgroundColor: {
      type: String,
      default: '#eaeaea',
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    displayColor() {
      if (!this.color) return this.$color('ilabo');
      if (this.color && this.color[0] === '#') {
        return this.color;
      }
      return this.$color(this.color);
    },
  },
  methods: {
    toggle() {
      if (this.clickable) {
        this.$emit('update:value', !this.value);
      }
    },
  },
};
</script>

<style scoped>
  .square-box {
    border-radius: 4px;
    height: 16px;
    width: 16px;
    color: white;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
  }
  .clickable {
    cursor: pointer;
  }
</style>
