import { createPatch, createTests } from 'rfc6902';
import { init } from './api/axios';
import * as permissions from './dict/permissions';
import AddButton from './components/AddButton';
import CustomCheckbox from './components/CustomCheckbox';
import Hamburger from './components/Hamburger';
import LModal from './components/LModal';
import Loader from './components/Loader';
import RemovePopover from './components/RemovePopover';
import SaveButton from './components/SaveButton';
import ProSelect from './components/ProSelect';
import SearchInput from './components/SearchInput';
import SmoothReflow from './components/SmoothReflow';
import NoPermissions from './components/NoPermissions';
import LTable from './components/LTable';
import Error from './components/Error';
import getColor from './utils/colors';
import SimpleTable from './components/SimpleTable';
import Offcanvas from './components/Offcanvas';

let uId = 0;

export default {
  install(Vue, { colors, store }) {
    Vue.component('AddButton', AddButton);
    Vue.component('Error', Error);
    Vue.component('CustomCheckbox', CustomCheckbox);
    Vue.component('Hamburger', Hamburger);
    Vue.component('LModal', LModal);
    Vue.component('Loader', Loader);
    Vue.component('RemovePopover', RemovePopover);
    Vue.component('SaveButton', SaveButton);
    Vue.component('ProSelect', ProSelect);
    Vue.component('SearchInput', SearchInput);
    Vue.component('SmoothReflow', SmoothReflow);
    Vue.component('NoPermissions', NoPermissions);
    Vue.component('LTable', LTable);
    Vue.component('SimpleTable', SimpleTable);
    Vue.component('Offcanvas', Offcanvas);

    init(store);

    Vue.mixin({
      computed: {
        $now: {
          cache: false,
          get: () => Math.floor(Date.now() / 1000),
        },
        $perm: () => permissions,
      },
      methods: {
        $color: getColor(colors),
        $patch(obj, update, test) {
          const patch = createPatch(obj, {
            ...obj,
            ...update,
          });
          const tests = test === false ? [] : createTests(obj, patch);

          return [...tests, ...patch];
        },
      },
      created() {
        uId += 1;
        this.$uId = uId;
      },
    });
  },
};
