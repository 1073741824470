<template>
  <BPopover
    :show.sync="popover"
    :target="target"
    :placement="placement"
    :container="container"
  >
    <div
      v-if="!pending"
      class="d-flex"
    >
      <div class="pr-1">
        <button
          class="btn btn-secondary"
          type="button"
          @click="popover = false"
        >
          <i class="ion ion-ios-arrow-back pr-2" /> {{ $t('general.cancel') }}
        </button>
      </div>
      <div class="pl-1">
        <button
          class="btn btn-danger"
          type="button"
          @click="$emit('remove')"
        >
          <slot>
            <i class="ion ion-md-trash pr-2" />
            {{ $t('general.confirm') }}
          </slot>
        </button>
      </div>
    </div>
    <div v-else>
      <Loader
        :size="22"
        color="red"
      />
    </div>
    <div
      v-if="error"
      class="text-danger"
    >
      {{ error }}
    </div>
  </BPopover>
</template>

<script>
export default {
  props: {
    target: String,
    placement: String,
    container: String,
    pending: Boolean,
    error: String,
  },
  data: () => ({
    popover: false,
  }),
  watch: {
    pending(v) {
      if (!v && !this.error) {
        this.popover = false;
      }
    },
  },
  methods: {
    close() {
      this.popover = false;
    },
  },
};
</script>
