<template>
  <div class="position-relative system-select">
    <Tippy
      arrow
      distance="15"
      interactive
      placement="bottom"
      theme="light"
      trigger="click"
    >
      <template #trigger>
        <img
          :src="require(`../assets/logos/${current}.png`)"
          class="logo d-none d-md-inline"
        >
        <img
          :src="require(`../assets/logos/${current}-icon.png`)"
          class="logo d-md-none"
        >
      </template>

      <div
        v-if="modules.length > 0 && !pending"
        style="margin: -0.3125rem -0.5625rem"
      >
        <div
          v-for="m in sortedModules"
          :key="m.id"
          :style="{ 'background-color': m.color }"
          class="module"
          @click="show(m)"
        >
          <img
            :src="require(`../assets/logos/${m.module.toLowerCase()}.png`)"
            class="system-logo"
          >
        </div>
      </div>
      <div v-else>
        <Loader />
      </div>
    </Tippy>
  </div>
</template>

<script>
import axios from 'axios';
import colors from '../style/colors.icss.scss';
import moduleOrder from '../config/modules';

export default {
  props: {
    current: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    hubUrl: String,
    token: String,
  },
  data: () => ({
    modules: [],
    pending: false,
    error: false,
  }),
  computed: {
    sortedModules() {
      return this.modules
        .concat([
          {
            module: 'Hub',
            url: this.hubUrl || 'https://hub.ilabo.com.pl',
          },
        ])
        .filter(x => x.module.toLowerCase() !== this.current.toLowerCase())
        .filter(x => x.url != null)
        .sort((a, b) => {
          const ao = moduleOrder.findIndex(x => x === a.module.toLowerCase());
          const bo = moduleOrder.findIndex(x => x === b.module.toLowerCase());
          return ao - bo;
        })
        .map(x => ({
          ...x,
          color: (colors || {})[x.module.toLowerCase()],
        }));
    },
  },
  watch: {
    token() {
      this.getLicenses();
    },
  },
  methods: {
    getLicenses() {
      if (!this.token) return;
      this.pending = true;
      this.error = false;

      axios({
        method: 'get',
        url: `${this.baseUrl}/v1/user/licenses`,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(({ data }) => {
          this.pending = false;
          this.modules = data.map(x => ({
            ...x,
            module: x.module.toLowerCase(),
          }));
          this.$emit('modules', this.modules);
        })
        .catch(() => {
          this.error = true;
          this.pending = false;
        });
    },
    show({ url }) {
      window.location.href = url;
    },
  },
  created() {
    this.getLicenses();
  },
};
</script>

<style lang="scss" scoped>
.logo {
  margin-top: 7px;
  max-height: 28px;
  margin-left: 12px;
  position: relative;
  z-index: 200;
  cursor: pointer;
  filter: drop-shadow(0 0 3px rgba(100, 100, 100, 0.4));

  &:focus {
    outline: none;
  }

  &:hover {
    filter: drop-shadow(0 0 5px rgba(100, 100, 100, 0.8));
  }
}

.system-select :deep(*:focus) {
  outline: none;
}

.module {
  padding: 5px 15px;
  text-align: left;
}

.system-logo {
  max-height: 30px;
  transition: transform 300ms;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;

  &:hover {
    transform: translateX(-5px);
    filter: drop-shadow(0 0 5px rgba(100, 100, 100, 0.8));
  }
}

@media screen and (max-width: 768px) {
  .logo {
    max-height: 30px;
    margin-top: 6px;
  }
}
</style>
