<template>
  <BTable
    ref="table"
    table-class="simple-table"
    :fields="fields"
    :items="items"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template
      v-for="(_, name) in $scopedSlots"
      #[name]="slotData"
    >
      <slot
        :name="name"
        v-bind="slotData"
        @click="$emit('row-clicked', slotData)"
      />
    </template>
  </BTable>
</template>

<script>
export default {
  name: 'SimpleTable',
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.simple-table :deep() {
  th {
    text-align: center !important;
  }
  th, td {
    border-color: #F5F5F5;
    text-align: center;
    vertical-align: middle;

    &:first-child, &:last-child {
      text-align: unset;
    }
  }

  tbody {
    border-bottom: 1px solid #F5F5F5;

    tr {
      border-left: 1px solid #F5F5F5;
      border-right: 1px solid #F5F5F5;
    }
  }

  thead {
    th {
      border-top: 0;
      background-color: #F5F5F5;

      &:first-child {
        border-radius: 10px 0 0 0;
      }
      &:last-child {
        border-radius: 0 10px 0 0;
      }
    }
  }
}
</style>
