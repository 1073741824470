<template>
  <div>
    <div
      class="d-flex justify-content-between font-weight-bold pb-3 column-names"
      style="padding-left: 10px; margin-bottom: -15px"
    >
      <div class="d-flex">
        <div class="d-flex">
          <div
            class="pr-1"
            @click="toggleSort('name')"
          >
            <i
              v-if="sort.name === 'up'"
              class="ion ion-md-arrow-dropup"
            />
            <i
              v-else-if="sort.name === 'down'"
              class="ion ion-md-arrow-dropdown text-dark"
            />
          </div>
          <div
            class="column-title"
            @click="toggleSort('name')"
          >
            {{ $t('general.name') }}
          </div>
        </div>
      </div>
    </div>

    <div class="pl-2">
      <PlantRow
        v-for="plant in filteredPlants"
        :key="plant.id"
        style="margin-top: 2px"
        v-bind="plant"
        :selected="selected && selected.id === plant.id"
        @select="$emit('select', plant)"
        @edit="$emit('edit', plant)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import createSearch from '@core/utils/search';
import PlantRow from '@/components/plants/PlantRow';

export default {
  props: {
    search: String,
    selected: Object,
  },
  data: () => ({
    sort: { name: 'down' },
  }),
  components: {
    PlantRow,
  },
  computed: {
    ...mapGetters('core', ['can']),
    ...mapGetters(['tenantId']),
    ...mapState({
      plants: state => state.structure.plants || [],
    }),
    filteredPlants() {
      if (!this.plants) return [];

      const sortNum = Object.keys(this.sort)
        .filter(x => this.sort[x] === 'up' || this.sort[x] === 'down')
        .length;

      const sortBy = sortNum > 0 ? this.sort : { path: 'down' };

      const sortString = (key, a, b, force) => {
        if (sortBy[key] === 'down' || force) {
          return a[key].localeCompare(b[key]);
        } if (sortBy[key] === 'up') {
          return b[key].localeCompare(a[key]);
        }
        return 0;
      };

      const list = this.plants
        .filter(x =>
          this.can(this.$perm.CONFIG_PLANT_ADMIN, x.id)
          || this.can(this.$perm.CONFIG_TENANT_ADMIN, x.tenantId))
        .sort((a, b) => sortString('name', a, b) || sortString('name', a, b, true));

      if (this.search) {
        const search = createSearch(this.search);
        return list
          .filter(l => search(l.name) || search(l.code));
      }

      return list;
    },
  },
  methods: {
    toggleSort(key) {
      if (this.sort[key] === 'down') {
        this.$set(this.sort, key, 'up');
      } else if (this.sort[key] === 'up') {
        this.$set(this.sort, key, null);
      } else {
        this.$set(this.sort, key, 'down');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .column-names {
    font-size: 12px;
    text-align: left;
    user-select: none;
  }

  .column-title:hover {
    text-decoration: underline;
    cursor: pointer;
  }
</style>
