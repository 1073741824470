<template>
  <div>
    <div
      v-if="error"
      class="text-danger text-center font-weight-bold"
    >
      {{ $t('error.auth') }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'OidcCallback',
  data: () => ({
    error: null,
  }),
  methods: {
    ...mapActions('oidcStore', [
      'oidcSignInCallback',
    ]),
  },
  mounted() {
    this.oidcSignInCallback()
      .then(redirectPath => {
        this.$router.push(redirectPath);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);

        setTimeout(() => {
          window.location.href = `${window.location.origin}${process.env.VUE_APP_PUBLIC_PATH}`;
        }, 2000);
      });
  },
};
</script>
