<template>
  <div
    class="container"
  >
    <ImportPage
      ref="importPage"
      :title="$t('users.import')"
      job-type="UsersImport"
      :import-function="importUsers"
      :get-importer-jobs="getImporterJobs"
      :get-importer-job="getImporterJob"
      :tenant-id="tenantId"
      @finish="finish()"
    >
      <template #imported="{ imported }">
        <UserImportList
          :imported="imported"
          class="justify-content-center"
          small
        />
      </template>
    </ImportPage>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ImportPage from '@core/components/ImportPage';
import UserImportList from '@/components/people/users/UserImportList';

export default {
  components: {
    ImportPage,
    UserImportList,
  },
  computed: {
    ...mapGetters(['tenantId']),
  },
  methods: {
    ...mapActions(['importUsers', 'getImporterJob', 'getImporterJobs']),
    ...mapActions(['getUsers']),
    getTenantUsers() {
      this.getUsers({ params: { tenantId: this.tenantId } });
    },
    finish() {
      this.getTenantUsers();
      this.$router.push(`/${this.tenantId}/people/users`);
    },
  },
};
</script>

<style>

</style>
