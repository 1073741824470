<template>
  <button
    :class="adding ? 'btn-secondary' : 'btn-success'"
    class="btn icon-btn btn-sm rotating"
    type="button"
    @click="$emit('update:adding', !adding)"
  >
    <i
      :class="{ rotated: adding }"
      class="ion ion-md-add"
    />
  </button>
</template>

<script>
export default {
  props: {
    adding: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  button.rotating {
    transition: background-color 500ms, transform 200ms ease-in-out;
    i {
      transition: transform 500ms;
      transform: rotate(0);
    }
    .rotated {
      transform: rotate(45deg);
    }
  }
</style>
