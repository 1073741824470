<template>
  <div
    class="mx-auto"
    style="max-width: 400px"
  >
    <Loader v-if="pending" />
    <div v-else-if="user">
      <div class="mt-2">
        {{ $t('users.displayName') }}:
        <span class="ml-2">
          {{ user.firstName || '-' }} {{ user.lastName || '' }}
        </span>
      </div>
      <div class="mt-2">
        {{ $t('users.email') }}:
        <span class="ml-2">
          {{ user.email || '-' }}
        </span>
      </div>
      <div class="mt-2">
        {{ $t('users.phone') }}:
        <span class="ml-2">
          {{ user.phone || '-' }}
        </span>
      </div>
      <div class="d-flex">
        <button
          class="btn btn-sm btn-info btn-circled mx-auto mt-3"
          @click="passwordForm = true"
        >
          <i class="ion ion-md-lock pr-1" /> {{ $t('account.password') }}
        </button>
        <button
          class="btn btn-sm btn-info btn-circled mx-auto mt-3"
          @click="accessCardForm = true"
        >
          <i class="ion ion-md-card pr-1" /> {{ $t('settings.card') }}
        </button>
      </div>
    </div>

    <LModal
      :show.sync="passwordForm"
    >
      <UpdatePassword
        :user="user"
        :admin-mode="false"
        @close="passwordForm = false"
      />
    </LModal>

    <LModal
      :show.sync="accessCardForm"
    >
      <CardSettingsForm
        v-if="accessCardForm"
        :user-id="user.id"
        :admin-mode="false"
        @cancel="accessCardForm = false"
      />
    </LModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import UpdatePassword from '@/components/UpdatePassword';
import CardSettingsForm from '@/components/CardSettingsForm';

export default {
  data: () => ({
    pending: false,
    passwordForm: false,
    accessCardForm: false,
    user: null,
  }),
  components: {
    UpdatePassword,
    CardSettingsForm,
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    ...mapState({
      users: state => state.user.users || [],
    }),
  },
  methods: {
    ...mapActions(['getUser']),
  },
  created() {
    this.$emit('page', 'myAccount');
    this.pending = true;
    this.getUser({
      params: {
        userId: this.oidcUser.id,
        query: {
          includeUserAccount: true,
        },
      },
    })
      .then(({ data }) => { this.user = data; })
      .finally(() => { this.pending = false; });
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: rgba(153, 163, 172, 1);
  display: inline;
  color: white;

  &:hover {
    background-color: rgba(123, 136, 149, 1);
    color: white;
  }
}

.active-card {
  background-color: $ilabo;
  display: inline;
  color: white;

  &:hover {
    background-color: rgba(137, 157, 7, 1);
    color: white;
  }
}
</style>
