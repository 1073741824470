<template>
  <Loader v-if="pending" />
  <CompetencesEditor v-else />
</template>

<script>
import { mapState } from 'vuex';
import CompetencesEditor from '@/components/people/competences/CompetencesEditor';

export default {
  components: {
    CompetencesEditor,
  },
  computed: {
    ...mapState({
      pending: state => state.competence.pending.competences,
    }),
  },
  created() {
    this.$emit('selected', 'competences');
  },
};
</script>

<style />
