import Vue from 'vue';

export const add = propName => (s, { data }) => {
  if (!Array.isArray(s[propName])) return;
  s[propName].push(data);
};

export const update = propName => (s, { data }) => {
  if (!Array.isArray(s[propName])) return;
  const index = s[propName].findIndex(el => el.id === data.id);
  if (index !== -1) {
    Vue.set(s[propName], index, data);
  }
};

export const addOrUpdate = propName => (s, { data }) => {
  if (!Array.isArray(s[propName])) return;
  const index = s[propName].findIndex(el => el.id === data.id);
  if (index !== -1) {
    Vue.set(s[propName], index, data);
  } else {
    s[propName].push(data);
  }
};

export const remove = propName => (s, payload, _, { params }) => {
  if (!Array.isArray(s[propName])) return;
  s[propName] = s[propName].filter(el => el.id !== params.id);
};

export default {
  add,
  update,
  addOrUpdate,
  remove,
};
