<template>
  <div>
    <div
      v-if="selected"
      class="element-selector"
      @click="selectedId = null"
    >
      <div>
        {{ selected.name }}
      </div>
    </div>
    <div v-else>
      <TreeTable
        v-if="elements"
        :selectable="true"
        :columns="columns"
        :list="filteredElements"
        :root="plant"
        :read-only="true"
        :selected="selectedId"
        :is-value="x => x.type === 'consumable'"
        @select="selectedId = $event"
      />
    </div>
  </div>
</template>

<script>
import TreeTable from '@/components/tables/TreeTable';
import { elementType } from '@/utils/dictionary';

export default {
  props: {
    elementId: String,
    elements: {
      type: Array,
      required: true,
    },
    plant: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedId: null,
  }),
  components: {
    TreeTable,
  },
  computed: {
    filteredElements() {
      if (!this.elements) return [];
      return this.elements.filter(x => x.type !== elementType.machine);
    },
    selected() {
      if (!this.elements || !this.selectedId) return null;
      if (this.selectedId.toUpperCase() === this.plant.id.toUpperCase()) return this.plant;
      return this.elements.find(({ id }) => id === this.selectedId);
    },
    columns() {
      return [{
        key: 'name',
        text: this.$t('general.name'),
        secondary: 'code',
      }];
    },
  },
  watch: {
    selectedId(id) {
      this.$emit('update:elementId', id);
    },
  },
  created() {
    this.selectedId = this.elementId;
  },
};
</script>

<style lang="scss" scoped>
  .element-selector {
    cursor: pointer;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.95rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
</style>
