<template>
  <div
    class="d-flex align-items-center justify-content-center"
    style="height: 100vh"
  >
    <Loader />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    userTenantId() {
      return this.oidcUser?.TenantId;
    },
  },
  watch: {
    userTenantId() {
      this.select();
    },
  },
  methods: {
    select() {
      if (this.userTenantId) {
        this.$router.replace(`/${this.userTenantId}/account`);
      }
    },
  },
  created() {
    this.select();
  },
};
</script>

<style>

</style>
