<template>
  <div class="no-permissions">
    <div>
      <i class="fas fa-lock" />
    </div>
    <div class="text">
      {{ $t('general.noPermissions') }}
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
  .no-permissions {
    text-align: center;
    padding: 1em 0;

    .text {
      padding-top: 0.5em;
      font-size: 1.5em;
    }

    i {
      font-size: 3em;
    }
  }
</style>
