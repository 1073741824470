<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3 levels-header">
      <h5 class="mb-0">
        {{ $t('taxonomies.levels') }}

        <span
          class="small ml-1 mb-0 d-inline-block"
          style="transform: translateY(-2px); color: #6A6A6A;"
        >
          <i
            v-if="disabled"
            class="fas fa-lock"
          />
        </span>
      </h5>

      <BButton
        v-if="showEditLevelsLink"
        size="sm"
        class="rounded-pill px-4"
        @click="showLevelsEditWarningModal"
      >
        <i class="fas fa-edit mr-1" /> {{ $t('taxonomies.editLevels') }}
      </BButton>

      <BButton
        v-if="!disabled"
        size="sm"
        class="rounded-pill px-4"
        @click="addLevel"
      >
        <i class="fas fa-plus mr-1" /> {{ $t('taxonomies.addLevel') }}
      </BButton>
    </div>
    <div
      v-show="innerValue.length > 0"
      class="tree-wrapper"
    >
      <div
        class="legend"
        :class="{disabled}"
      >
        <div class="name mr-2">
          {{ $t('general.name') }}
        </div>
        <div class="code mr-2">
          {{ $t('general.code') }}
        </div>
      </div>
      <DraggableTree
        ref="tree"
        v-model="innerValue"
        :disabled="disabled"
        hide-level-input
      />
    </div>
    <p
      v-show="innerValue.length === 0"
      class="text-center py-3 text-muted"
    >
      <i
        class="d-block fas fa-project-diagram mb-2"
        style="font-size: 24px;"
      />
      {{ $t('taxonomies.addLevelToStartEditing') }}
    </p>

    <TaxonomyLevelsEditWarningModal
      @ok="$emit('levels-unlock')"
      @cancel="hideLevelsEditWarningModal"
    />
  </div>
</template>

<script>

import DraggableTree from '@/components/taxonomies/DraggableTree';
import TaxonomyLevelsEditWarningModal
  from '@/components/taxonomies/modals/TaxonomyLevelsEditWarningModal';

export default {
  props: {
    disabled: Boolean,
    showEditLevelsLink: Boolean,
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    treeOptions: {
      multiple: false,
      dnd: true,
    },
  }),
  components: {
    TaxonomyLevelsEditWarningModal,
    DraggableTree,
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    addLevel() {
      this.$refs.tree.addNode();
    },
    showLevelsEditWarningModal() {
      this.$bvModal.show('taxonomy-levels-edit-warning-modal');
    },
    hideLevelsEditWarningModal() {
      this.$bvModal.hide('taxonomy-levels-edit-warning-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.levels-header {
  height: 33px;
}

.tree-wrapper {
  border: 1px solid #EDEDED;
  border-radius: 6px;
  padding: 10px;

  .legend {
    padding: 5px 80px 5px 50px;
    width: 100%;
    display: flex;
    font-weight: bold;
    font-size: 14px;

    &.disabled {
      padding: 5px 32px 5px 8px;
    }

    .name {
      flex-grow: 1;
      margin-left: 10px;
    }

    .code, .level {
      min-width: 200px;
      max-width: 200px;
    }
  }
}
</style>
