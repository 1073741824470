<template>
  <div>
    <form
      class="text-left"
      @submit.prevent="submit"
    >
      <label class="mb-0 small font-weight-bold">
        {{ $t('plants.name') }}
      </label>
      <BFormInput
        v-model="name"
        :placeholder="`${$t('plants.name')}...`"
        class="mb-2"
      />
      <label class="mb-0 small font-weight-bold">
        {{ $t('plants.code') }}
      </label>
      <BFormInput
        v-model="code"
        :placeholder="`${$t('plants.code')}...`"
        :state="errorClass('code')"
        class="mb-2"
      />
      <span
        v-if="errorClass('code') === false"
        class="d-block is-invalid mt-1"
        style="font-size: 0.675rem"
      >
        {{ $t('error.incorrectCode') }}
      </span>

      <label class="mb-0 small font-weight-bold">
        {{ $t('plants.latitude') }}
      </label>
      <BFormInput
        v-model="latitude"
        :placeholder="`${$t('plants.latitude')}...`"
        :state="errorClass('latitude')"
        class="mb-2"
      />
      <span
        v-if="errorClass('latitude') === false"
        class="d-block is-invalid mt-1"
        style="font-size: 0.675rem"
      >
        {{ $t('error.incorrectCoordinate') }}
      </span>

      <label class="mb-0 small font-weight-bold">
        {{ $t('plants.longitude') }}
      </label>
      <BFormInput
        v-model="longitude"
        :placeholder="`${$t('plants.longitude')}...`"
        :state="errorClass('longitude')"
        class="mb-2"
      />
      <span
        v-if="errorClass('longitude') === false"
        class="d-block is-invalid mt-1"
        style="font-size: 0.675rem"
      >
        {{ $t('error.incorrectCoordinate') }}
      </span>

      <div v-if="error">
        <hr
          class="w-25"
          style="margin-top: 10px; margin-bottom: 10px"
        >
        <div class="text-center text-danger error ">
          {{ error.title }}
          <div
            v-for="(err, index) in (error.list || [])"
            :key="index"
            class="small"
          >
            {{ err }}
          </div>
        </div>
      </div>

      <div class="mt-3">
        <SaveButton
          :pending="pending"
          :disabled="disabled"
          :show-remove="!!plant"
          :remove-pending="removePending"
          @save="submit"
          @remove="remove"
          @cancel="$emit('close')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { handleApiError } from '@/utils/handleApiError';

export default {
  props: {
    plant: Object,
  },
  data: () => ({
    id: null,
    name: '',
    code: '',
    latitude: '',
    longitude: '',
    pending: false,
    attempted: false,
    removePending: false,
    error: null,
  }),
  computed: {
    ...mapGetters(['tenantId']),
    disabled() {
      return !this.name || !this.code || !this.longitude || !this.latitude;
    },
    listOfErrors() {
      const fieldsToValidate = {
        latitude: this.latitude,
        longitude: this.longitude,
        code: this.code,
      };

      const errors = Object.entries(fieldsToValidate)
        .filter(entry => !entry[1] || !entry[1].length)
        .map(key => key[0]);

      if (!this.validateLatitude(fieldsToValidate.latitude)) {
        errors.push('latitude');
      }

      if (!this.validateLongitude(fieldsToValidate.longitude)) {
        errors.push('longitude');
      }

      if (!this.validateCode(fieldsToValidate.code)) {
        errors.push('code');
      }

      return [...new Set(errors)];
    },
  },
  methods: {
    ...mapActions(['createPlant', 'updatePlant', 'deletePlant']),
    submit() {
      if (this.plant) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      this.attempted = true;
      if (this.listOfErrors.length) { return; }

      this.pending = true;
      this.createPlant({
        data: {
          name: this.name,
          code: this.code,
          longitude: parseFloat(this.longitude),
          latitude: parseFloat(this.latitude),
        },
      })
        .then(() => {
          this.$emit('close');
        })
        .catch(({ response }) => {
          this.error = handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    update() {
      this.attempted = true;
      if (this.listOfErrors.length) { return; }

      this.pending = true;
      this.updatePlant({
        params: {
          id: this.plant.id,
        },
        data: {
          name: this.name,
          code: this.code,
          longitude: parseFloat(this.longitude),
          latitude: parseFloat(this.latitude),
        },
      })
        .then(() => {
          this.$emit('close');
        })
        .catch(({ response }) => {
          this.error = handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    remove() {
      this.removePending = true;
      this.deletePlant({
        params: {
          id: this.id,
        },
      })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.removePending = false;
          this.$emit('close');
        });
    },
    errorClass(errorsToCheck) {
      if (!this.attempted) return null;
      return !this.ifError(errorsToCheck);
    },
    ifError(errorsToCheck) {
      let errorsList = errorsToCheck;
      if (typeof (errorsToCheck) === 'string') errorsList = [errorsToCheck];
      return errorsList.find(x => this.listOfErrors.includes(x));
    },
    validateLatitude(latitude) {
      const reg = /^(\+|-)?(?:90(?:(?:\.0+)?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]+)?))$/;
      return reg.test(latitude);
    },
    validateLongitude(longitude) {
      const reg = /^(\+|-)?(?:180(?:(?:\.0+)?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]+)?))$/;
      return reg.test(longitude);
    },
    validateCode(code) {
      const reg = /^[\w]{1,256}$/;
      return reg.test(code);
    },
  },
  created() {
    if (this.plant) {
      this.id = this.plant.id;
      this.name = this.plant.name;
      this.code = this.plant.code;
      this.longitude = this.plant.longitude.toString();
      this.latitude = this.plant.latitude.toString();
    }
  },
};
</script>

<style lang="scss" scoped>
  input.is-invalid {
    border-color: #d60010; // TODO: Add those colors to core
    background-color:  #ffefef;
  }

  span.is-invalid {
    color: #d60010;

    &:before {
      content: '!';
      font-weight: 900;
   }
  }

</style>
