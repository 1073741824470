<template>
  <div>
    <div class="element-dropdown-header">
      {{ $t('users.elementAccess') }}
    </div>
    <Tippy
      class="popup"
      placement="bottom"
      theme="light"
      interactive
      :distance="15"
      append-to="parent"
      trigger="click"
      arrow
    >
      <template #trigger>
        <div class="dropdown-button">
          <span v-if="plantId">
            <i class="fa-solid fa-industry pr-1" />
            {{ selectedPath }}
          </span>
          <span v-else>
            {{ $t('users.all') }}
          </span>

          <i class="fas fa-sort-down dropdown-icon" />
        </div>
      </template>

      <div style="max-height: 400px; overflow: auto">
        <div
          class="reset-button"
          style="margin-left: 20px"
          @click="plantId = null; elementId = null"
        >
          {{ $t('users.all') }}
        </div>
        <hr class="my-0">
        <TreeTable
          :selectable="true"
          :columns="columns"
          :selected="selectedElements"
          :list="plants.concat(plantElements)"
          :root="{}"
          :read-only="true"
          @select="select"
        />
      </div>
    </Tippy>
  </div>
</template>

<script>
import TreeTable from '@/components/tables/TreeTable';
import { elementType } from '@/utils/dictionary';
import { mapState } from 'vuex';

export default {
  data: () => ({
    selected: null,
    plantId: null,
    elementId: null,
  }),
  components: {
    TreeTable,
  },
  computed: {
    ...mapState({
      elements: state => state.structure.elements || [],
      plants: state => state.structure.plants || [],
    }),
    selectedPath() {
      if (!this.plantId && !this.elementId) {
        return '';
      }
      const plant = this.plants.find(x => x.id === this.plantId);
      if (!this.elementId) {
        return plant?.code || '?';
      }
      const element = this.plantElements.find(x => x.id === this.elementId);
      return `${plant?.code || '?'}/${element?.code || '?'}`;
    },
    selectedElements() {
      return [{ id: this.plantId }, { id: this.elementId }].filter(x => x.id);
    },
    plantElements() {
      return this.filteredElements
        .filter(x => x.plantId === this.plantId);
    },
    filteredElements() {
      if (!this.elements) return [];
      return this.elements.filter(x => x.type !== elementType.machine);
    },
    columns() {
      return [{
        key: 'name',
        text: this.$t('general.name'),
      }];
    },
  },
  watch: {
    plantId(id) {
      this.$emit('plantId', id);
    },
    elementId(id) {
      this.$emit('elementId', id);
    },
  },
  methods: {
    select(id) {
      const plant = this.plants.find(x => x.id === id);
      if (plant) {
        this.plantId = id;
        this.elementId = null;
      } else {
        this.elementId = id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.element-dropdown-header {
  text-transform: uppercase;
  font-size: 9px;
  color: #666;
  padding-bottom: 2px;
}

.reset-button {
  padding: 5px 10px;
  text-align: left;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: rgba(150, 150, 150, 0.1);
  }
}

.dropdown-icon {
  color: #666;
  padding-left: 7px;
  position: relative;
  top: -2px;
}

.dropdown-button {
  background-color: #F2F2F2;
  font-size: 11px;
  height: 30px;
  display: flex;
  padding: 2px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #666;
  cursor: pointer;
  position: relative;
  font-weight: 500;

  &:hover {
    background-color: #eee;
  }
}
</style>
