import axios from 'axios';
import { hub } from '@core/api/axios';
import { paramsSerializer, setHeaders } from '@core/api/axiosSetup';

export const auth = axios.create({ paramsSerializer });
export const auditLog = axios.create({ paramsSerializer });

export const init = store => {
  setHeaders(auth, store);
  setHeaders(auditLog, store);
};

export default hub;
