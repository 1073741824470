export default {
  namespaced: true,
  modules: {},
  getters: {
    roles: (s, g, rs, rg) => {
      const user = rg['oidcStore/oidcUser'];
      if (!user || !user.role) return [];
      return Array.isArray(user.role) ? user.role : [user.role];
    },
  },
};
