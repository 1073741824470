<template>
  <div>
    <p
      v-if="label"
      class="mb-0 text-uppercase"
      style="font-size: 11px"
    >
      {{ label }}
    </p>

    <div
      ref="selectRef"
      :style="{width: selectWidth}"
      class="select"
      @click="toggleDropdown"
    >
      <div class="select-option">
        <slot name="selected" />
        <i class="fas fa-caret-down" />
      </div>

      <div
        v-if="dropdownVisible"
        v-click-outside="handleClickOutside"
        :style="{
          left: leftSided ? 0 : 'unset',
          right: leftSided ? 'unset' : 0,
          width: dropdownWidth,
        }"
        class="select-dropdown"
      >
        <div
          v-for="(option, index) in options"
          :key="option.id"
          :class="{'selected': isSelected(option.id)}"
        >
          <div v-if="dividersIndexes.includes(index)">
            <hr
              v-if="dividers[dividersIndexes.indexOf(index)].label"
              class="my-1 mb-n1"
            >
            <label
              v-if="dividers[dividersIndexes.indexOf(index)].label"
              class="small text-center w-100 mb-0"
            >
              {{ dividers[dividersIndexes.indexOf(index)].label }}
            </label>
            <hr
              :class="{'mt-n1': dividers[dividersIndexes.indexOf(index)].label}"
              class="my-1"
            >
          </div>

          <div
            class="select-option"
            @click="select(option.id)"
          >
            <slot
              name="option"
              v-bind="option"
            />

            <i
              v-if="multiple && isSelected(option.id)"
              class="fas fa-check ml-auto"
              @click.stop.prevent="select(option.id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    selectWidth: {
      type: String,
      default: '100%',
    },
    dropdownWidth: {
      type: String,
      default: '100%',
    },
    leftSided: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    dividers: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    value: [String, Array],
  },
  data() {
    return {
      dropdownVisible: false,
    };
  },
  computed: {
    dividersIndexes() {
      return this.dividers.map(d => d.index);
    },
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        if (this.multiple) {
          if (!v) {
            this.$emit('input', []);
          } else if (this.inverted ? !this.isSelected(v) : this.isSelected(v)) {
            this.$emit('input', [...this.innerValue.filter(id => id !== v)]);
          } else {
            this.$emit('input', [...this.innerValue, v]);
          }
        } else this.$emit('input', v);
      },
    },
  },
  methods: {
    isSelected(id) {
      if (this.multiple) {
        if (this.inverted) {
          return !this.innerValue.includes(id);
        }
        return this.innerValue.includes(id);
      }
      if (this.inverted) return this.innerValue !== id;
      return this.innerValue === id;
    },
    toggleDropdown() {
      if (this.multiple && this.dropdownVisible) return;
      this.dropdownVisible = !this.dropdownVisible;
    },
    select(id) {
      this.innerValue = id;
    },
    handleClickOutside() {
      this.dropdownVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  background-color: #F2F2F2;
  padding: 0.25rem 1rem;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
}

.select-option {
  display: flex;
  align-items: center;
  font-size: 12px;

  &-text {
    margin-left: 8px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
  }
}

.select-dropdown {
  position: absolute;
  right: 0;
  z-index: 79000;
  top: 28px;
  padding: 5px 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 11.4736px rgba(133, 133, 133, 0.25);
  border-radius: 3px;

  .select-option {
    padding: 0.25rem 1rem;
    cursor: pointer;
    font-size: 14px;

    &--default {
      color: #818181;
    }

    .icon {
      color: #88AD41;
    }

    &-text {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.selected {
      background-color: #E0E0E3 !important;
    }

    &:hover {
      background-color: #F2F2F2;
    }
  }
}
</style>
