var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Portal',{staticClass:"w-100 h-100",attrs:{"disabled":!_vm.bindToBody,"to":"offcanvas"}},[_c('div',{ref:"offcanvasRef",staticClass:"offcanvas-wrapper",class:{
      'shown': _vm.innerShow,
      'hiding': !_vm.completelyHidden,
      'passive': _vm.passive,
    },on:{"click":function($event){$event.stopPropagation();return _vm.handleOutsideClick.apply(null, arguments)}}},[_c('div',{staticClass:"offcanvas-content",class:{
        'shown': _vm.innerShow,
        'pb-0': _vm.noBottomPadding,
        'passive': _vm.passive,
      },style:({
        paddingTop: `${_vm.paddingTop}px`,
      })},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }