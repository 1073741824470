<template>
  <div
    class="mt-1 py-2"
    style="min-width: 300px;"
  >
    <div
      v-for="(t, index) in types"
      :key="index"
      class="setting-category text-nowrap"
    >
      <div>
        <span class="title">{{ t.text }}</span>
      </div>
      <div
        v-for="cat in t.children"
        :key="cat.key"
      >
        <RouterLink
          :class="{
            active: selected === cat.key,
          }"
          :to="cat.route"
          class="setting-subcategory d-block"
          @click.native="$emit('selected', cat.key)"
        >
          {{ cat.text }}
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    selected: String,
  },
  computed: {
    ...mapGetters(['tenantId']),
    types() {
      return [
        {
          text: this.$t('pages.products'),
          children: [
            {
              key: 'keepup',
              text: 'KeepUp',
              route: `/${this.tenantId}/products/keepup`,
            },
          ],
        },
      ];
    },
  },
  watch: {
    selected(key) {
      const page = this.types
        .reduce((acc, curr) => acc || curr.children.find(c => c.key === key),
          null);

      this.$emit('page', page);
    },
  },
};
</script>

<style lang="scss" scoped>
  .setting-category {
    font-size: 23px;
    font-weight: 300;
    padding: 10px 20px;
  }

  .setting-subcategory {
    padding-left: 20px;
    text-transform: uppercase;
    padding: 5px 15px;
    margin: 4px 0;
    font-size: 15px;
    font-weight: bold;
    color: #212529;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 200ms, box-shadow 200ms;

    &.active {
      box-shadow: 0 0 5px rgba($grey, 1);
    }

    &.disabled {
      text-decoration: line-through;
    }

    &:hover {
      background-color: rgba(150, 150, 150, 0.1);
    }
  }
</style>
