<template>
  <div>
    <form
      class="form-white"
    >
      <div class="form-group">
        <div
          class="form-sm"
          style="position: relative"
        >
          <label>
            {{ $t('users.firstName') }}
            <span
              class="text-danger"
            >
              *
            </span>
          </label>
          <BFormInput
            v-model="firstName"
          />
        </div>
      </div>
      <div class="form-group">
        <div
          class="form-sm"
        >
          <label>
            {{ $t('users.lastName') }}
            <span
              class="text-danger"
            >
              *
            </span>
          </label>
          <BFormInput
            v-model="lastName"
          />
        </div>
      </div>
      <div class="form-group">
        <div
          class="form-sm"
        >
          <label>
            {{ $t('users.email') }}
            <span
              class="text-danger"
            >
              *
            </span>
          </label>
          <BFormInput
            v-model="email"
            type="email"
          />
          <div
            v-if="email && !emailValidation"
            class="text-danger w-100 small"
          >
            {{ $t('registration.invalidEmail') }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <div
          class="form-sm"
        >
          <label>
            {{ $t('account.password') }}
            <span
              class="text-danger"
            >
              *
            </span>
          </label>
          <BFormInput
            v-model="password"
            type="password"
          />
          <div class="d-flex">
            <div
              v-if="password && (!passwordLength || !passwordDigits)"
              class="text-danger w-100 small"
            >
              {{ $t('registration.passwordValidation') }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div
          class="form-sm"
        >
          <label>
            {{ $t('account.repeatPassword') }}
            <span
              class="text-danger"
            >
              *
            </span>
          </label>
          <BFormInput
            v-model="repeatedPassword"
            type="password"
          />
        </div>
        <div
          v-if="repeatedPassword && repeatedPassword !== password"
          class="text-danger w-100 small"
        >
          {{ $t('account.passwordsNotEqual') }}
        </div>
      </div>

      <div class="position-relative">
        <button
          class="btn btn-ilabo btn-block position-relative button"
          :disabled="formDisabled"
          @click="createUser()"
        >
          <span>
            {{ $t('registration.nextStep') }}
            <i class="ion ion-ios-arrow-forward pl-2" />
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      repeatedPassword: '',
    };
  },
  computed: {
    passwordLength() {
      if (!this.password) return false;
      return this.password.length >= 6;
    },
    passwordDigits() {
      if (!this.password) return false;
      return this.password.replace(/[^0-9]/g, '').length >= 2;
    },
    emailValidation() {
      /* eslint-disable no-useless-escape */
      /* eslint-disable max-len */
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      if (!this.email) return false;
      return reg.test(this.email);
    },
    formDisabled() {
      return !this.firstName
          || !this.lastName
          || !this.email
          || !this.emailValidation
          || !this.password
          || !this.repeatedPassword
          || this.repeatedPassword !== this.password
          || !this.passwordLength
          || !this.passwordDigits;
    },
  },
  methods: {
    createUser() {
      const user = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
      };
      this.$emit('update:user', user);
      this.$emit('create');
    },
  },
};
</script>

<style lang="scss" scoped>
 label {
  font-size: 13px;
  margin-bottom: 2px;
  color: rgba(150, 150, 150, 0.8);
 }

 input {
  background-color: #F2F2F2;
  border: none;
  border-radius: 15px;
 }

 .button {
  width: 100%;
  max-width: 200px;
  margin: auto;
  margin-top: 50px;
  border-radius: 30px;
 }

</style>
