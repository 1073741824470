<template>
  <div v-if="!status">
    <Loader />
  </div>
  <div
    v-else-if="status === 'prompt'"
    class="row no-gutters justify-content-center mx-0"
  >
    <div class="col-8 px-2">
      <button
        class="btn btn-block btn-sm btn-outline-ilabo"
        @click.prevent="enablePush"
      >
        <i class="fas fa-bell pr-2" />
        {{ $t('notify.enablePush') }}
      </button>
    </div>
    <div
      v-if="!hideCancel"
      class="col-4 px-2"
    >
      <button
        class="btn btn-block btn-sm btn-outline-secondary"
        @click.prevent="$emit('cancel')"
      >
        {{ $t('general.cancel') }}
      </button>
    </div>
  </div>
  <div v-else-if="status === 'denied'">
    {{ $t('notify.pushDisabled') }}
  </div>
  <div v-else>
    {{ $t('notify.pushEnabled') }}
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import * as notify from '../../../utils/notify';

export default {
  props: {
    hideCancel: Boolean,
    autoRegister: Boolean,
  },
  data: () => ({
    status: null,
    subscribeOnRegister: false,
  }),
  computed: {
    ...mapGetters('core', ['serviceWorker']),
  },
  watch: {
    serviceWorker(sw) {
      this.getStatus(true);
      if (sw && this.subscribeOnRegister) {
        this.subscribeOnRegister = false;
        this.subscribePushManager();
      }
    },
  },
  methods: {
    ...mapActions('core', ['addPushSubscription']),
    enablePush() {
      notify.askPermission()
        .then(() => {
          this.status = 'granted';
          this.subscribePushManager();
        })
        .catch(() => {
          this.status = 'denied';
        });
    },
    subscribePushManager() {
      if (!this.serviceWorker) {
        this.subscribeOnRegister = true;
        return;
      }

      notify.subscribePush(this.serviceWorker)
        .then(sub => {
          const subObj = JSON.parse(JSON.stringify(sub));
          const utcOffset = -(moment().utcOffset());

          this.addPushSubscription({
            data: {
              utcOffset,
              endpoint: subObj.endpoint,
              type: 'web',
              keys: JSON.stringify(subObj.keys),
            },
          });
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getStatus(emit) {
      notify.getNotificationPermissionState()
        .then(res => {
          this.status = res;
          if (res === 'prompt' && emit) {
            this.$emit('show');
          } else if (res === 'granted' && this.autoRegister) {
            this.subscribePushManager();
          }
        });
    },
  },
  mounted() {
    if (this.serviceWorker) {
      this.getStatus(true);
    }
  },
};
</script>

<style>

</style>
