import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const role = new Vapi({
  axios,
  state: {
    configs: [],
    workBoostProjects: [],
  },
})
  .get({
    action: 'getProductConfigs',
    property: 'configs',
    path: ({ tenantId }) => `/tenant/${tenantId}/product-configs`,
  })
  .put({
    action: 'setProductConfig',
    path: ({ tenantId }) => `tenant/${tenantId}/product-config`,
    onSuccess: (s, { data }) => {
      const configuration = s.configs.find(x => x.tenantId === data.tenantId
        && x.productName === data.productName
        && x.configType === data.configType);
      if (configuration) {
        configuration.config = data.config;
      } else {
        s.configs.push({
          tenantId: data.tenantId,
          productName: data.productName,
          configType: data.configType,
          config: data.config,
        });
      }
    },
  })
  .getStore();

export default role;
