<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3 nodes-header">
      <h5 class="mb-0">
        {{ $t('taxonomies.nodes') }}
      </h5>

      <BButton
        size="sm"
        class="rounded-pill px-4"
        @click="addLevel"
      >
        <i class="fas fa-plus mr-1" /> {{ $t('taxonomies.addNode') }}
      </BButton>
    </div>
    <div
      v-show="innerValue.length > 0"
      class="tree-wrapper"
    >
      <div class="legend">
        <div class="name mr-2">
          {{ $t('general.name') }}
        </div>
        <div class="code mr-2">
          {{ $t('general.code') }}
        </div>
        <div class="level">
          {{ $t('taxonomies.level') }}
        </div>
      </div>
      <DraggableTree
        ref="tree"
        v-model="innerValue"
        :levels-graph="levelsGraph"
        :nodes-graph="nodesGraph"
      />
    </div>
    <p
      v-show="innerValue.length === 0"
      class="text-center py-3 text-muted"
    >
      <i
        class="d-block fas fa-project-diagram mb-2"
        style="font-size: 24px;"
      />
      {{ $t('taxonomies.addNodeToStartEditing') }}
    </p>
  </div>
</template>

<script>

import DraggableTree from '@/components/taxonomies/DraggableTree';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    levelsGraph: {
      type: Object,
      default: () => ({}),
    },
    nodesGraph: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    treeOptions: {
      multiple: false,
      dnd: true,
    },
  }),
  components: { DraggableTree },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    addLevel() {
      this.$refs.tree.addNode();
    },
  },
};
</script>

<style lang="scss" scoped>
.nodes-header {
  height: 33px;
}

.tree-wrapper {
  border: 1px solid #EDEDED;
  border-radius: 6px;
  padding: 10px;

  .legend {
    padding: 5px 80px 5px 50px;
    width: 100%;
    display: flex;
    font-weight: bold;
    font-size: 14px;

    .name {
      flex-grow: 1;
      margin-left: 10px;
    }

    .code, .level {
      min-width: 200px;
      max-width: 200px;
    }
  }
}
</style>
