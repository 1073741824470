<template>
  <button
    :class="{ 'is-active': active }"
    class="hamburger hamburger--spin"
    type="button"
    @click="toggle"
  >
    <span class="hamburger-box">
      <span class="hamburger-inner" />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    active: Boolean,
  },
  methods: {
    toggle() {
      this.$emit('update:active', !this.active);
    },
  },
};
</script>

<style lang="scss">
$hamburger-types: (spin);
$hamburger-layer-color: #fff;
$hamburger-padding-x: 5px;
$hamburger-padding-y: 5px;
$hamburger-layer-width: 25px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-height: 2px;

@import "hamburgers/_sass/hamburgers/hamburgers";
</style>
