<template>
  <BModal
    :id="id"
    v-model="shown"
    :size="size"
    hide-footer
    hide-header
    :modal-class="`${fullScreen ? 'full-screen-modal' : ''} ${modalClass || ''}`"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :no-close-on-esc="noCloseOnEsc"
  >
    <button
      type="button"
      class="close modal-close position-absolute"
      @click="shown = false"
    >
      <span aria-hidden="true">×</span>
    </button>
    <slot v-if="shown || !lazy" />
  </BModal>
</template>

<script>
export default {
  props: {
    id: String,
    show: Boolean,
    size: String,
    noCloseOnBackdrop: Boolean,
    noCloseOnEsc: Boolean,
    fullScreen: Boolean,
    modalClass: String,
    showClose: {
      type: Boolean,
      default: true,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    shown: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
  },
};
</script>

<style lang="scss">

.close.modal-close {
  top: 10px;
  right: 15px;
  z-index: 100;
  padding: 0 5px;
  font-size: 35px;
}

.full-screen-modal {
  padding: 0 !important;

  .modal-dialog {
    margin: 0;
  }
  .modal-content {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    border-radius: 0;
    min-height: 100vh;
    width: 100vw;
  }
}
</style>
