<template>
  <div>
    <form
      class="text-left"
      @submit.prevent="save"
    >
      <label class="mb-0 small font-weight-bold required mt-2">
        {{ $t('users.email') }}
      </label>
      <BFormInput
        v-model="email"
        :state="errorClass('email')"
        :placeholder="`${$t('users.email')}...`"
        :disabled="id !== null"
        type="email"
      />
      <label class="mb-0 small font-weight-bold required mt-2">
        {{ $t('users.firstName') }}
      </label>
      <BFormInput
        v-model="firstName"
        :state="errorClass('firstName')"
        :placeholder="`${$t('users.firstName')}...`"
      />
      <span
        v-if="errorClass('firstName') === false"
        class="d-block is-invalid mt-1"
        style="font-size: 0.675rem"
      >
        {{ $t('error.emptyInput') }}
      </span>
      <label class="mb-0 small font-weight-bold required mt-2">
        {{ $t('users.lastName') }}
      </label>
      <BFormInput
        v-model="lastName"
        :state="errorClass('lastName')"
        :placeholder="`${$t('users.lastName')}...`"
      />
      <span
        v-if="errorClass('lastName') === false"
        class="d-block is-invalid mt-1"
        style="font-size: 0.675rem"
      >
        {{ $t('error.emptyInput') }}
      </span>
      <span
        v-if="errorClass('email') === false"
        class="d-block is-invalid mt-1"
        style="font-size: 0.675rem"
      >
        {{ $t('error.incorrectEmail') }}
      </span>
      <label class="mb-0 small font-weight-bold mt-2">
        {{ $t('users.phone') }}
      </label>
      <BFormInput
        v-model="phoneNumber"
        :placeholder="`${$t('users.phone')}...`"
      />
      <label class="mb-0 small font-weight-bold mt-2">
        {{ $t('users.companyEmployeeId') }}
      </label>
      <BFormInput
        v-model="companyEmployeeId"
        :placeholder="`${$t('users.companyEmployeeId')}...`"
        :state="errorClass('tooLongEmployeeId')"
        :disabled="companyEmployeeEditLocked"
      />
      <span
        v-if="errorClass('tooLongEmployeeId') === false"
        class="d-block is-invalid mt-1"
        style="font-size: 0.675rem"
      >
        {{ $t('error.tooLongString') }} (max 50)
      </span>

      <div v-if="!user">
        <div class="d-flex justify-content-between align-items-end mt-2">
          <label class="mb-0 small font-weight-bold required">
            {{ $t('account.password') }}
          </label>
        </div>
        <BFormInput
          v-model="password"
          :placeholder="`${$t('account.password')}...`"
          type="password"
          :state="errorClass(['password', 'passwordsNotEqual'])"
        />
        <label class="mb-0 small font-weight-bold required mt-2">
          {{ $t('account.repeatPassword') }}
        </label>
        <BFormInput
          v-model="repeatedPassword"
          type="password"
          :placeholder="`${$t('account.repeatPassword')}...`"
          :state="errorClass(['repeatPassword', 'passwordsNotEqual' ])"
        />
        <span
          v-if="errorClass('passwordsNotEqual') === false"
          class="d-block is-invalid mt-1"
          style="font-size: 0.675rem"
        >
          {{ $t('error.passwordsMustBeEqual') }}
        </span>
      </div>

      <div v-if="user">
        <div class="font-weight-bold small my-3">
          {{ $t('people.competences') }}
        </div>
        <hr
          class="w-25 ml-0"
          style="margin-top: -10px; margin-bottom: 3px"
        >
        <CompetencesSelector
          :competences.sync="competences"
        />
      </div>
      <div v-if="error">
        <hr
          class="w-25"
          style="margin-top: 10px; margin-bottom: 10px"
        >
        <div class="text-center text-danger error ">
          {{ error.title }}
          <div
            v-for="(err, index) in (error.list || [])"
            :key="index"
            class="small"
          >
            {{ err }}
          </div>
        </div>
      </div>
      <div class="mt-3">
        <SaveButton
          :pending="pending"
          :remove-pending="removePending"
          :show-remove="!!user && possibilityToDeleteUser"
          @cancel="$emit('close')"
          @remove="remove()"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { handleApiError } from '@/utils/handleApiError';
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import CompetencesSelector from './CompetencesSelector';

export default {
  props: {
    user: Object,
    possibilityToDeleteUser: {
      type: Boolean,
      default: true,
    },
    externalError: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    attempted: false,
    pending: false,
    removePending: false,
    error: null,
    id: null,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    companyEmployeeId: null,
    companyEmployeeEditLocked: false,
    password: '',
    repeatedPassword: '',
    roles: [],
    competences: [],
  }),
  components: {
    CompetencesSelector,
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    ...mapGetters(['tenantId']),
    ...mapState({
      users: state => state.user.users || [],
    }),
    userToUpdate() {
      return {
        id: this.id,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        companyEmployeeId: this.companyEmployeeIdFormatted,
        competences: this.competences,
        roles: this.roles,
      };
    },
    listOfErrors() {
      let fieldsToValidate = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
      };

      if (!this.user) {
        fieldsToValidate = {
          ...fieldsToValidate,
          password: this.password,
          repeatPassword: this.repeatedPassword,
        };
      }

      const errors = Object.entries(fieldsToValidate)
        .filter(entry => !entry[1] || !entry[1].length)
        .map(key => key[0]);

      if (!this.validateEmail(fieldsToValidate.email)) {
        errors.push('email');
      }

      if (!this.user && fieldsToValidate.password !== fieldsToValidate.repeatPassword) {
        errors.push('passwordsNotEqual');
      }

      if (this.companyEmployeeId && this.companyEmployeeId.length > 50) {
        errors.push('tooLongEmployeeId');
      }

      return [...new Set(errors)];
    },
    companyEmployeeIdFormatted() {
      if (this.companyEmployeeId?.trim() === '') {
        return null;
      }
      return this.companyEmployeeId?.trim();
    },
  },
  methods: {
    ...mapActions(['createUser', 'updateUser', 'deleteUser']),
    errorClass(errorsToCheck) {
      if (!this.attempted) return null;
      return !this.ifError(errorsToCheck);
    },
    ifError(errorsToCheck) {
      let errorsList = errorsToCheck;
      if (typeof (errorsToCheck) === 'string') errorsList = [errorsToCheck];
      return errorsList.find(x => this.listOfErrors.includes(x));
    },
    validateEmail(email) {
      // eslint-disable-next-line max-len
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    save() {
      this.error = null;
      this.attempted = true;

      if (this.listOfErrors.length) {
        this.error = { title: this.$t('error.wrongInput') };
        return;
      }

      if (this.id) {
        this.update();
      } else {
        this.create();
      }
    },
    update() {
      this.pending = true;
      this.updateUser({
        params: {
          id: this.id,
        },
        data: this.userToUpdate,
      })
        .then(({ data }) => {
          this.$emit('update', data);
          this.$emit('close');
        })
        .catch(({ response }) => {
          this.error = handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    create() {
      this.pending = true;
      const newUser = {
        firstName: this.firstName,
        lastName: this.lastName,
        password: this.password,
        email: this.email,
        phoneNumber: this.phoneNumber,
        companyEmployeeId: this.companyEmployeeIdFormatted,
        competences: this.competences,
        roles: this.roles,
      };
      this.createUser({
        params: {
          tenantId: this.tenantId,
        },
        data: newUser,
      })
        .then(({ data }) => {
          this.$emit('create', data);
          this.id = data.id;
        })
        .catch(({ response }) => {
          this.error = handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    remove() {
      this.removePending = true;
      this.deleteUser({
        params: {
          id: this.id,
        },
      })
        .then(() => {
          this.$emit('close');
        })
        .catch(({ response }) => {
          this.error = handleApiError(response);
        })
        .finally(() => {
          this.removePending = false;
        });
    },
  },
  created() {
    if (this.user) {
      if (this.user.id) {
        this.id = this.user.id;
      } else {
        this.id = this.users.find(u => this.user.email.toLowerCase() === u.email.toLowerCase())?.id;
      }
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.email = this.user.email;
      this.phoneNumber = this.user.phoneNumber;
      this.companyEmployeeId = this.user.companyEmployeeId;
      this.competences = _.cloneDeep(this.user.competences || []);
      this.roles = _.cloneDeep(this.user.roles || []);
      this.companyEmployeeEditLocked = this.companyEmployeeId !== null;
    }
    if (this.externalError) {
      this.error = handleApiError(this.externalError);
    }
  },
};
</script>

<style lang="scss">
.required{
  &:after {
    content: '*';
    color: $red;
  }
}

input.is-invalid {
  border-color: #d60010; // TODO: Add those colors to core
  background-color:  #ffefef;
}
span.is-invalid {
  color: #d60010;

  &:before {
    content: '!';
    font-weight: 900;
  }
}

</style>
