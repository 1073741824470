import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import {
  add, update, remove, addOrUpdate,
} from '@/utils/utils';

const store = new Vapi({
  axios,
  state: {
    users: [],
  },
})
  .get({
    action: 'getUsers',
    property: 'users',
    path: ({ tenantId }) => `/tenant/${tenantId}/users`,
  })
  .get({
    action: 'getUser',
    path: ({ userId }) => `/user/${userId}`,
    onSuccess: addOrUpdate('users'),
  })
  .post({
    action: 'createUser',
    path: ({ tenantId }) => `tenant/${tenantId}/user`,
    onSuccess: add('users'),
  })
  .put({
    action: 'updateUser',
    path: ({ id }) => `user/${id}`,
    onSuccess: update('users'),
  })
  .delete({
    action: 'deleteUser',
    path: ({ id }) => `user/${id}`,
    onSuccess: remove('users'),
  })
  .post({
    action: 'importUsers',
    path: ({ tenantId }) => `tenant/${tenantId}/users/import`,
  })
  .put({
    action: 'resetUserPassword',
    path: ({ id }) => `user/${id}/password/reset`,
  })
  .put({
    action: 'changeUserPassword',
    path: ({ id }) => `user/${id}/password/change`,
  })
  .get({
    action: 'getUserAccessCards',
    path: ({ id }) => `user/${id}/accessCards`,
  })
  .get({
    action: 'getAssignedRolePermissions',
    path: ({ userId }) => `permissions/user-role-permission/${userId}`,
  })
  .put({
    action: 'addUserAccessCard',
    path: ({ id }) => `user/${id}/accessCard/add`,
    onSuccess: update('users'),
  })
  .put({
    action: 'lockUserAccessCard',
    path: ({ id }) => `user/${id}/accessCard/lock`,
    onSuccess: update('users'),
  })
  .put({
    action: 'resetUserAccessCardPin',
    path: ({ id }) => `user/${id}/accessCard/pin/reset`,
    onSuccess: update('users'),
  })
  .put({
    action: 'changeMyAccessCardPin',
    path: () => 'user/me/accessCard/pin/change',
    onSuccess: update('users'),
  })
  .put({
    action: 'deleteUserAccessCard',
    path: ({ id, cardId }) => `user/${id}/accessCard/${cardId}/remove`,
    onSuccess: update('users'),
  })
  .put({
    action: 'resetUserPermission',
    property: 'userPermission',
    path: ({ id, permissionId }) => `user/${id}/permission/${permissionId}/reset`,
    onSuccess: update('users'),
  })
  .put({
    action: 'setUserPermission',
    property: 'userPermission',
    path: ({ id }) => `user/${id}/permission/set`,
    onSuccess: update('users'),
  })
  .put({
    action: 'setRolePermission',
    property: 'userPermission',
    path: () => 'user/role-permission/set',
    onSuccess: update('users'),
  })
  .put({
    action: 'deleteRolePermission',
    property: 'userPermission',
    path: () => 'user/role-permission/remove',
    onSuccess: update('users'),
  })
  .getStore();

store.getters = {
  userTenantId: (s, g, rs, rg) => rg['oidcStore/oidcUser']?.TenantId,
};

store.mutations.clearUsers = s => {
  s.users = [];
};

export default store;
