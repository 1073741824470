import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    accessCardRequirements: null,
  },
})
  .get({
    action: 'getAccessCardRequirements',
    property: 'accessCardRequirements',
    path: () => '/config/accessCards',
  })
  .getStore();

export default store;
