<template>
  <div
    v-if="!error"
    class="loader"
  >
    <ClipLoader
      :size="size | toInt"
      :color="getColor"
    />
  </div>
  <div
    v-else
    class="text-danger text-center"
  >
    <div class="loader-error">
      <i class="ion ion-ios-close" />
    </div>
    Error<br>
    {{ error.detail || error.message || error.status }}
  </div>
</template>

<script>
import { ClipLoader } from '@saeris/vue-spinners';

export default {
  props: {
    error: Object,
    size: {
      type: [Number, String],
      default: 30,
    },
    color: String,
  },
  filters: {
    toInt(v) {
      return parseInt(v, 10);
    },
  },
  components: {
    ClipLoader,
  },
  computed: {
    getColor() {
      const c = this.color || 'ilabo';
      if (!this.$color || c[0] === '#') {
        return c;
      }
      return this.$color(c);
    },
  },
};
</script>

<style scoped>
  .loader {
    text-align: center;
  }

  .loader-error i {
    font-size: 60px;
  }
</style>
