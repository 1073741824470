import Vapi from 'vuex-rest-api';
import { auditLog as axios } from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    auditLogs: [],
  },
})
  .get({
    action: 'getAuditLogs',
    property: 'auditLogs',
    path: '/q',
  })
  .getStore();

store.getters = {
  auditLogs: s => s.auditLogs,
};

export default store;
