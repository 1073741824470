<template>
  <BModal
    id="taxonomy-remove-modal"
    hide-header
    hide-footer
    content-class="px-5"
  >
    <i
      class="fas fa-times close-modal"
      @click="$emit('cancel')"
    />

    <h4 class="text-center mt-4">
      {{ $t('taxonomies.doYouWantToRemoveThisClassification') }}
    </h4>

    <hr>

    <div class="text-center py-2">
      <BButton
        class="mr-2 px-4 rounded-pill"
        @click="$emit('cancel')"
      >
        {{ $t('general.cancel') }}
      </BButton>
      <BButton
        class="px-4 rounded-pill"
        variant="success"
        @click="$emit('confirm')"
      >
        <i class="fas fa-check mr-1" />
        {{ $t('general.confirm') }}
      </BButton>
    </div>
  </BModal>
</template>

<script>

export default {};
</script>

<style lang="scss" scoped>
.close-modal {
  position: absolute;
  top: 0;
  right: -2.8rem;
  font-size: 1.2rem;
  padding: 1.2rem;
  opacity: 0.7;
  cursor: pointer;
}
</style>
