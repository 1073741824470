<template>
  <div>
    <KeepUpWorkBoostIntegration
      :config="keepUpWorkBoostIntegrationConfig"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import KeepUpWorkBoostIntegration from '@/components/products/keepUp/KeepUpWorkBoostIntegration';

export default {
  components: {
    KeepUpWorkBoostIntegration,
  },
  computed: {
    ...mapGetters(['tenantId']),
    ...mapState({
      allConfigs: state => state.product.configs || [],
    }),
    configs() {
      return this.allConfigs.filter(x => x.productName === 'keepup');
    },
    keepUpWorkBoostIntegrationConfig() {
      const configuration = this.configs.find(x => x.configType === 'keepup-workboost-integration');
      if (!configuration || !configuration.config) return null;
      return JSON.parse(configuration.config);
    },
  },
};
</script>

<style />
