import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import { add, update, remove } from '@/utils/utils';

const store = new Vapi({
  axios,
  state: {
    roles: [],
  },
})
  .get({
    action: 'getRoles',
    property: 'roles',
    path: ({ tenantId }) => `/tenant/${tenantId}/roles`,
  })
  .get({
    action: 'getAllRoles',
    path: () => '/roles',
    property: 'allRoles',
  })
  .get({
    action: 'getRolePermission',
    path: ({ roleId }) => `role-permission/${roleId}`,
    property: 'selectedPermissions',
  })
  .post({
    action: 'createRole',
    path: ({ tenantId }) => `/tenant/${tenantId}/role`,
    onSuccess: add('roles'),
  })
  .post({
    action: 'setRolePermissions',
    path: ({ tenantId }) => `/tenant/${tenantId}/role-permission/set`,
    onSuccess: update('roles'),
  })
  .put({
    action: 'updateRole',
    path: ({ id }) => `role/${id}`,
    onSuccess: update('roles'),
  })
  .delete({
    action: 'deleteRole',
    path: ({ id }) => `role/${id}`,
    onSuccess: remove('roles'),
  })
  .post({
    action: 'setUserRolePermission',
    path: 'user/user-role-permission/set',
    onSuccess: update('roles'),
  })
  .getStore();

store.mutations.clearRoles = s => {
  s.roles = [];
};

export default store;
