<template>
  <div>
    <div
      class="d-flex hoverable"
      style="height: 33px;"
    >
      <div
        class="plant flex-grow-1 d-flex justify-content-between"
        :class="{ selected }"
        @click.self="$emit('selected')"
      >
        <div class="ml-2 mr-2 align-self-center">
          <span
            class="name"
            @click.self="$emit('selected')"
          >
            {{ name }}
          </span>
        </div>
        <div
          v-if="selected"
          class="ml-auto align-self-center"
        >
          <i class="ion ion-md-checkmark" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    id: String,
    name: String,
    selected: Boolean,
  },
  computed: {
    ...mapState({
      pending: state => state.user.pending.userPermission,
    }),
  },
};
</script>

<style lang="scss" scoped>
  .plant {
    box-shadow: 0 2px 3px #efefef, 0 2px 3px #efefef;
    background-color: #fff;
    border-radius: 5px;
    padding: 4px 10px;
    line-height: 1.1;
    text-align: left;

    .name {
      font-weight: 500;
      font-size: 13px;
    }

    &.selected {
      background-color: rgba(150, 150, 150, 0.3);
    }
  }

  .hoverable {
    transition: background-color 300ms;
    cursor: pointer;

    &:hover {
      background-color: rgba(150, 150, 150, 0.1);
      .name {
        text-decoration: underline;
      }
    }
  }
</style>
