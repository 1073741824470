<template>
  <div class="container">
    <form
      class="text-left mt-3"
      @submit.prevent="save"
    >
      <div v-if="!adminMode">
        <label class="mb-0 small font-weight-bold">
          {{ $t('account.oldPassword') }}
        </label>
        <BFormInput
          v-model="oldPassword"
          type="password"
          class="mb-2"
        />
      </div>
      <label class="mb-0 small font-weight-bold">
        {{ $t('account.newPassword') }}
      </label>
      <BFormInput
        v-model="newPassword"
        type="password"
        class="mb-2"
        aria-describedby="password-input-validation"
        @focus="newPasswordInitiated = true"
      />
      <label class="mb-0 small font-weight-bold">
        {{ $t('account.repeatPassword') }}
      </label>
      <BFormGroup>
        <BFormInput
          v-model="repeatedPassword"
          type="password"
          class="mb-2"
          aria-describedby="repeated-password-input-validation"
          :state="repeatedPasswordState"
          @focus="repeatedPasswordInitiated = true"
        />
        <BFormInvalidFeedback id="repeated-password-input-validation">
          {{ repeatedPasswordFeedback }}
        </BFormInvalidFeedback>
      </BFormGroup>
      <div
        v-if="error"
        class="text-center mt-3"
      >
        <div class="error-text">
          {{ error }}
        </div>
      </div>
      <div
        class="d-flex justify-content-center mt-3"
      >
        <SaveButton
          :pending="pending"
          :disabled="submitDisabled"
          @cancel="$emit('close')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { handleApiError } from '@/utils/handleApiError';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    adminMode: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    oldPassword: '',
    newPassword: '',
    repeatedPassword: '',
    newPasswordInitiated: false,
    repeatedPasswordInitiated: false,
    error: null,
    pending: false,
  }),
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    repeatedPasswordFeedback() {
      if (!this.passwordsEqual) return this.$t('account.passwordsNotEqual');
      return '';
    },
    repeatedPasswordState() {
      if (!this.repeatedPasswordInitiated) return null;
      return this.passwordsEqual;
    },
    passwordsEqual() {
      if (!this.repeatedPassword) return false;
      return this.newPassword === this.repeatedPassword;
    },
    submitDisabled() {
      return !this.passwordsEqual;
    },
  },
  methods: {
    ...mapActions(['changeUserPassword', 'resetUserPassword']),
    save() {
      this.pending = true;
      if (this.adminMode) {
        this.resetUserPassword({
          params: {
            id: this.user.id,
          },
          data: {
            newPassword: this.newPassword,
          },
        })
          .then(() => {
            this.$emit('close');
          })
          .catch(({ response }) => {
            this.error = handleApiError(response)?.title;
          })
          .finally(() => {
            this.pending = false;
          });
      } else if (this.user.id === this.oidcUser.id) {
        this.changeUserPassword({
          params: {
            id: this.user.id,
          },
          data: {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          },
        })
          .then(() => {
            this.$emit('close');
          })
          .catch(({ response }) => {
            this.error = handleApiError(response)?.title;
          })
          .finally(() => {
            this.pending = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .error-text {
    font-size: 12px;
    font-weight: 500;
    color: $red;
  }
</style>
