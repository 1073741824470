<template>
  <div class="d-flex app-menu">
    <LanguageSelect
      class="app-node"
    />

    <Notifications
      class="app-node"
      :hide-list-button="hideListButton"
      :notification-action="notificationAction"
    />

    <InfoSelect
      class="app-node"
    />

    <Tippy
      ref="popup"
      trigger="click"
      theme="light"
      interactive
      arrow
    >
      <template #trigger>
        <div class="app-node">
          <div
            id="userDropdown"
            class="icon"
          >
            <i class="ion ion-ios-contact" />
          </div>
        </div>
      </template>

      <div style="margin: -0.3125rem -0.5625rem">
        <div class="dropdown-row bg-ilabo text-white user-row">
          <div class="icon">
            <i class="fas fa-id-badge pr-2" />
          </div>
          {{ user ? user.DisplayName : '-' }}
        </div>
        <div
          v-for="el in dropdown"
          :key="el.text"
          class="dropdown-row clickable"
          @click="el.select(); $refs.popup.tippy().hide()"
        >
          <div class="icon">
            <i :class="`${el.icon} pr-2`" />
          </div>
          <div class="flex-grow-1 text-left">
            {{ el.text }}
          </div>
        </div>
        <div
          class="text-center pt-0 pb-1 text-secondary"
          style="border-top: 1px solid rgba(100,100,100,0.5)"
        >
          <span style="font-size: 11px; text-align: center">
            {{ version || '?' }}
          </span>
        </div>
      </div>
    </Tippy>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InfoSelect from './InfoSelect';
import LanguageSelect from './LanguageSelect';
import Notifications from './Notifications';

export default {
  props: {
    userDropdown: {
      type: Array,
      default: () => [],
    },
    notificationAction: Function,
    hideListButton: Boolean,
  },
  data: () => ({
    version: process.env.VUE_APP_PACKOS_VERSION,
  }),
  components: {
    Notifications,
    LanguageSelect,
    InfoSelect,
  },
  computed: {
    ...mapGetters('core', ['hubUrl', 'user']),
    dropdown() {
      return [
        {
          icon: 'fas fa-key',
          text: this.$t('menu.userSettings'),
          select: () => this.openAccountSettings(),
        },
        ...(this.userDropdown || []),
        {
          icon: 'fas fa-power-off',
          text: this.$t('menu.logout'),
          select: () => this.signOut(),
        },
      ];
    },
  },
  methods: {
    ...mapActions('core', ['signOut']),
    openAccountSettings() {
      const url = `${this.hubUrl}/${this.user.TenantId}/settings/account`;
      const win = window.open(url, '_blank');
      win.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./dropdowns/style.scss";

  .app-menu :deep(.dropdown-toggle) {
    padding: 0;
  }

  .app-menu :deep(*:focus) {
    outline: none;
  }

  .user-row {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .app-menu {
    padding-right: 10px;
    padding-top: 2px;
    position: relative;

    .app-node {
      color: white;
      padding: 7px 0 0;
      margin-right: 5px;
      text-align: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      transition: transform 300ms;
      cursor: pointer;

      .icon {
        font-size: 21px;
        line-height: 10px;
      }

      &:hover {
        background-color: rgba(100, 100, 100, 0.2);
      }
    }
  }
</style>
