<template>
  <div>
    <form
      class="form-white"
    >
      <div class="form-group">
        <div
          class="form-sm"
          style="position: relative"
        >
          <label>
            {{ $t('registration.companyName') }}
            <span
              class="text-danger"
            >
              *
            </span>
          </label>
          <BFormInput
            v-model="companyName"
          />
        </div>
      </div>
      <div class="form-group">
        <div
          class="form-sm"
        >
          <label>
            {{ $t('registration.companyDescription') }}
            <span
              class="text-danger"
            >
              *
            </span>
          </label>
          <BFormInput
            v-model="companyDescription"
          />
        </div>
      </div>
      <div>
        <BFormCheckbox
          v-model="regulations"
          class="mt-4"
        >
          {{ $t('registration.regulations') }}
          <span
            class="text-danger"
          >
            *
          </span>
        </BFormCheckbox>
      </div>
      <div class="position-relative">
        <button
          class="btn btn-ilabo btn-block position-relative button"
          :disabled="formDisabled"
          @click="createCompany()"
        >
          <span>
            {{ $t('registration.nextStep') }}
            <i class="ion ion-ios-arrow-forward pl-2" />
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyName: null,
      companyDescription: null,
      regulations: false,
    };
  },
  computed: {
    formDisabled() {
      return !this.companyName || !this.companyDescription || !this.regulations;
    },
  },
  methods: {
    createCompany() {
      const company = {
        name: this.companyName,
        description: this.companyDescription,
      };
      this.$emit('update:company', company);
      this.$emit('next');
    },
  },
};

</script>

<style lang="scss" scoped>
label {
  font-size: 13px;
  margin-bottom: 2px;
  color: rgba(150, 150, 150, 0.8);
 }

 input {
  background-color: #F2F2F2;
  border: none;
  border-radius: 15px;
 }

 .button {
  width: 100%;
  max-width: 200px;
  margin: auto;
  margin-top: 50px;
  border-radius: 30px;
 }
</style>
