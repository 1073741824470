<template>
  <div class="d-flex container">
    <ProductsMenu
      class="text-left px-2 pt-2"
      :selected="selected"
      @close="showMenu = false"
    />
    <div class="flex-grow-1 pt-3">
      <RouterView
        @selected="selected = $event"
      />
    </div>
  </div>
</template>

<script>
import ProductsMenu from '@/components/utils/ProductsMenu';

export default {
  data: () => ({
    selected: null,
  }),
  components: {
    ProductsMenu,
  },
  mounted() {
    this.selected = 'keepup';
  },
};
</script>

<style lang="scss" scoped>
  .container {
    margin: 0px;
    padding: 0px;
  }
</style>
