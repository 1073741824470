<template>
  <div>
    <slot
      v-if="!hideRoot"
      :row="{
        node: root,
        expanded,
        expandable: filtered.length > 0,
        padding: paddingSum,
      }"
    >
      <TreeTableRowPermissions
        :node="root"
        :expanded="expanded && filtered.length > 0"
        :expandable="filtered.length > 0"
        :pending="pending[root.id]"
        :padding-sum="paddingSum"
        :selected="isSelected || parentIsSelected"
        :selectable="selectable"
        :disable="parentIsSelected"
        @select="$emit('select', { id: $event, name: root.name})"
        @toggleExpanding="expanded = !expanded"
      />
    </slot>
    <SmoothReflow>
      <div
        v-for="element in filtered"
        :key="element.id"
        :style="{ 'padding-left': `${padding}px` }"
      >
        <TreeTablePermissions
          v-if="expanded"
          :list="list"
          :root="element"
          :pending="pending"
          :inner="true"
          :padding-sum="paddingSum + padding"
          :selectable="selectable"
          :assigned-permissions="assignedPermissions"
          :names-of-permission-being-modified="namesOfPermissionBeingModified"
          @select="$emit('select', $event)"
        >
          <template #default="scope">
            <slot v-bind="scope" />
          </template>
        </TreeTablePermissions>
      </div>
    </SmoothReflow>
    <div v-if="!inner && filtered.length === 0 && hideRoot">
      <NoPermissions style="font-size: 0.6rem" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TreeTableRowPermissions from './TreeTableRowPermissions';

export default {
  name: 'TreeTablePermissions',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    root: {
      type: Object,
      required: true,
    },
    inner: Boolean,
    hideRoot: Boolean,
    list: {
      type: Array,
      default: () => [],
    },
    pending: {
      type: Object,
      default: () => ({}),
    },
    assignedPermissions: {
      type: Array,
      default: () => [],
    },
    paddingSum: {
      type: Number,
      default: 0,
    },
    padding: {
      type: Number,
      default: 20,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    namesOfPermissionBeingModified: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    expanded: true,
  }),
  components: {
    TreeTableRowPermissions,
  },
  computed: {
    ...mapGetters('core', ['isSysAdmin', 'can']),
    filtered() {
      if (!this.list) return [];
      let filtered = this.list.filter(e => e && e.parentId === this.root.id);

      // block possiblity to grant yourself CONFIG_TENANT_ADMIN permission having only CONFIG_USER_MANAGEMENT permission
      if (this.namesOfPermissionBeingModified.some(permName => permName === this.$perm.CONFIG_TENANT_ADMIN)) {
        filtered = filtered.filter(e => this.isSysAdmin
          || this.can(this.$perm.CONFIG_TENANT_ADMIN, e.id));
      }

      return this.sortByName(filtered);
    },
    isSelected() {
      if (!this.assignedPermissions) return false;
      return this.assignedPermissions
        .some(x => (x.value === this.root.id || (x.value === null && x.rootId === this.root.id)));
    },
    parentIsSelected() {
      return this.checkParentSelected(this.root.parentId);
    },
  },
  methods: {
    checkParentSelected(parentId) {
      if (parentId === undefined) { return false; }
      const parentIsSelected = this.assignedPermissions
        .some(x => parentId !== undefined && parentId !== undefined && x.value === parentId);
      if (parentIsSelected === true) { return true; }
      const parentElem = this.list.find(x => x.id === parentId);
      if (parentElem === undefined) { return false; }
      return this.checkParentSelected(parentElem.parentId);
    },
    sortByName(list) {
      return list.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
    },
  },
};
</script>

<style>

</style>
