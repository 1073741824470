<template>
  <div
    class="row no-gutters align-items-center py-1"
  >
    <div class="col-auto pr-2">
      <button
        class="btn btn-secondary btn-sm icon-btn"
        type="button"
        @click="$emit('remove', competenceId);"
      >
        <i class="ion ion-md-close" />
      </button>
    </div>
    <div
      class="col"
      style="line-height: 1"
    >
      {{ name }}
    </div>
    <div class="col">
      <DateTimePicker
        :id="`expiryDate${id}`"
        :ref="`expiryDate${id}`"
        v-model="expiryDateValue"
        :label="$t('competence.expiryDate')"
        size="sm"
        format="YYYY-MM-DD"
        formatted="DD.MM.YYYY"
        only-date
        auto-close
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    id: Number,
    competenceId: String,
    name: String,
    expiryDate: Number,
  },
  data: () => ({
    expiryDateValue: null,
  }),
  watch: {
    expiryDateValue(f) {
      this.$emit('update:expiryDate', f ? moment(f).unix() : null);
    },
  },
  methods: {
    initDates() {
      const { expiryDate } = this;
      if (expiryDate) {
        this.expiryDateValue = moment(expiryDate * 1000).format();
      }
    },
  },
  created() {
    this.initDates();
  },
};
</script>
