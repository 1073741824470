<template>
  <div
    class="h-100 d-flex flex-column users-container"
  >
    <div class="d-flex justify-content-center">
      <HorizontalMenu
        :list="routes"
        class="people-menu"
        :selected="page"
        @update:selected="changePage"
      />
    </div>
    <div class="flex-grow-1 users-content">
      <RouterView
        :key="refreshKey"
        class="h-100"
        @selected="page = $event"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import HorizontalMenu from '@core/components/menu/HorizontalMenu';

export default {
  data: () => ({
    page: null,
    refreshKey: new Date().getTime(),
  }),
  components: {
    HorizontalMenu,
  },
  computed: {
    ...mapGetters(['tenantId']),
    ...mapGetters('core', ['canAnyRoleInTenant', 'can']),
    ...mapGetters('oidcStore', ['oidcUser']),
    ...mapState({
      roles: state => state.role.allRoles || [],
    }),
    canEditUsers() {
      return this.can(this.$perm.CONFIG_TENANT_ADMIN, this.tenantId)
      || this.canAnyRoleInTenant(
        this.$perm.CONFIG_USER_MANAGEMENT,
        this.tenantId,
        this.roles,
      );
    },
    routes() {
      return [
        {
          key: 'users',
          name: this.$t('people.users'),
          icon: 'fas fa-user',
        },
        {
          key: 'roles',
          name: this.$t('people.roles'),
          icon: 'fas fa-user-group',
          hide: !this.can(this.$perm.CONFIG_TENANT_ADMIN, this.tenantId),
        },
        {
          key: 'competences',
          name: this.$t('people.competences'),
          icon: 'fas fa-medal',
          hide: !(this.can(this.$perm.CONFIG_TENANT_ADMIN, this.tenantId)
            || this.can(this.$perm.CONFIG_USER_MANAGEMENT, this.tenantId)),
        },
      ]
        .filter(x => !x.hide);
    },
  },
  methods: {
    changePage(key) {
      if (this.page === key) {
        return this.changeSearchReset();
      }
      return this.$router.push(`/${this.tenantId}/people/${key}`);
    },
    changeSearchReset() {
      this.refreshKey = new Date().getTime();
    },
  },
  created() {
    this.$emit('page', 'people');
  },
};
</script>

<style lang="scss" scoped>
.users-content {
  background-color: white;
  margin-top: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 2px 4px rgba(#333, 0.1);
}

.people-menu ::v-deep .radio-label {
  i {
    font-size: 12px;
  }
}

.users-container ::v-deep .radio-label {
  background-color: white;
}
</style>
