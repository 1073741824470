import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import { add } from '@/utils/utils';

const store = new Vapi({
  axios,
  state: {
    tenants: [],
    tenantId: null,
  },
})
  .post({
    action: 'createTenant',
    path: 'tenant',
    onSuccess: add('tenant'),
  })
  .get({
    action: 'getTenants',
    property: 'tenants',
    path: 'tenants',
  })
  .getStore();

store.actions.changeTenant = (ctx, tenantId) => {
  ctx.commit('setTenant', tenantId);
  ctx.commit('clearUsers');
  ctx.commit('clearRoles');
  ctx.commit('clearCompetences');
};

store.mutations.setTenant = (s, v) => {
  s.tenantId = v;
};

store.getters = {
  tenantId: s => s.tenantId,
};

export default store;
