import Vapi from 'vuex-rest-api';
import { SYSTEM_ADMIN } from '@core/dict/permissions';
import { hub as axios } from '../api/axios';

let resolvePermissionPromise = null;
let rejectPermissionPromise = null;

const store = new Vapi({
  axios,
  state: {
    list: [],

    // this promise is resolved only after permissions are downloaded
    promise: new Promise((resolve, reject) => {
      resolvePermissionPromise = resolve;
      rejectPermissionPromise = reject;
    }),
  },
})
  .get({
    action: 'getPermissions',
    property: 'list',
    path: () => 'permissions/my',
  })
  .getStore();

store.getters = {
  permissionsPromise: s => s.promise,
  permissions: s => s.list || [],
  isSysAdmin: (_, g) => g.permissions.some(x => x.name.toLowerCase() === SYSTEM_ADMIN.toLowerCase()),
  can: (_, g) => (perm, value) => g.isSysAdmin || g.permissions.some(x =>
    x.name.toLowerCase() === perm.toLowerCase()
        && x.value.toLowerCase() === (value || '').toLowerCase()),
  canAny: (_, g) => perm => g.isSysAdmin || g.permissions.some(x =>
    x.name.toLowerCase() === perm.toLowerCase()),
  canAnyTeamInTenant: (s, g) => (perm, tenantId, teams) => {
    const tenantTeams = teams.filter(x => x.tenantId === tenantId);

    return g.isSysAdmin || g.permissions.some(x =>
      x.name.toLowerCase() === perm.toLowerCase()
      && (tenantId.toLowerCase() === x.value.toLowerCase()
        || tenantTeams.some(t => t.id.toLowerCase() === (x.value || '').toLowerCase())));
  },
  canAnyElementInPlant: (_, g) => (perm, plantId) => g.isSysAdmin || g.permissions
    .some(x => x.name.toLowerCase() === perm.toLowerCase()
        && x.rootId.toLowerCase() === (plantId || '').toLowerCase()),
};

store.mutations.setPromise = (s, p) => {
  s.promise = p;
};

store.actions.initUserPermissions = (ctx, scope) =>
  ctx.dispatch('getPermissions', {
    params: { query: { scope } },
  })
    .then(r => {
      resolvePermissionPromise();
      return r;
    })
    .catch(r => {
      rejectPermissionPromise();
      return r;
    });

export default store;
