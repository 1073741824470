<template>
  <BFormInput
    size="sm"
    class="search"
    :value="value"
    :placeholder="`${$t('general.search')}...`"
    type="search"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: String,
  },
};
</script>

<style scoped>
  .search {
    border-radius: 20px;
    padding-left: 15px;
  }
</style>
