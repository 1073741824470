<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center"
    >
      <div
        style="width: 20px; line-height: 1px; text-align: center"
      >
        <div v-if="expanded && expandable">
          <i
            class="ion ion-md-arrow-dropdown pb-1 pr-1"
            :style="expandable ? 'cursor: pointer' : 'cursor: default'"
            @click="$emit('toggleExpanding')"
          />
        </div>
        <div v-else-if="!expanded && expandable">
          <i
            class="ion ion-md-arrow-dropright pb-1 pr-1"
            :style="expandable ? 'cursor: pointer' : 'cursor: default'"
            @click="$emit('toggleExpanding')"
          />
        </div>
      </div>

      <div
        class="d-flex flex-grow-1 no-gutters asset align-items-center"
        :class="{ selected }"
        style="height: 33px; cursor: pointer"
        @click="select"
      >
        <div
          class="d-flex align-items-center text-left"
          style="line-height: 15px"
          :style="{ width: `${282 - paddingSum}px` }"
        >
          <div
            class="ml-2 mr-2"
          >
            <CustomCheckbox
              v-if="!pending"
              :color="disable ? 'grey' : 'blue'"
              :clickable="false"
              :value="selected"
              :disable="disable"
            />
            <div
              v-else
              style="width: 24px; padding-right: 7px"
            >
              <Loader
                :size="12"
                color="blue"
              />
            </div>
          </div>
          <div class="ml-2 mr-2">
            <span class="name">
              {{ node.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    node: {
      type: Object,
      default: () => ({}),
    },
    expanded: Boolean,
    expandable: Boolean,
    pending: Boolean,
    paddingSum: {
      type: Number,
      default: 0,
    },
    selected: Boolean,
    selectable: Boolean,
    disable: Boolean,
  },
  data: () => ({
    isChecked: false,
  }),
  methods: {
    select() {
      if (this.pending || this.disable) return;
      this.$emit('select', this.node.id);
    },
  },
};
</script>

<style lang="scss" scoped>
  .hoverable {
    transition: background-color 300ms;

    &:hover {
      background-color: rgba(150, 150, 150, 0.1);
      .name {
        text-decoration: underline;
      }
    }
  }

  .name {
    font-weight: 500;
    font-size: 13px;
  }

  .asset {
    background-color: #fff;
    border-radius: 5px;
    padding: 2px 10px;
    line-height: 1;
    margin-top: 2px;
    text-align: left;
    position: relative;

    &.selected {
      background-color: #dfdfdf;
    }

    &.disabled {
      box-shadow: none;
    }
  }

  .selected {
    background-color: rgba(150, 150, 150, 0.3);
  }

  .selected-icon {
    font-size: 12px;
    line-height: 1;
  }

  .name {
    font-weight: 500;
    font-size: 12px;
  }
</style>
