export default dict => {
  // assign each color to different number
  const numberedList = Object.keys(dict).reduce((acc, curr) => {
    acc.push(dict[curr]);
    return acc;
  }, []);

  return tag => {
    // color is defined explicitly
    if (tag && tag[0] === '#') {
      return tag;
    }

    // Get color from numbered list if tag is a number
    const num = parseInt(tag, 10);
    if (!Number.isNaN(num)) {
      return numberedList[(num % numberedList.length)];
    }

    return dict[tag];
  };
};
