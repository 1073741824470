import lang from '@/lang';

const publicKey = 'BJA4SkNuI0w6E4-Nrk7TdazOh8nIYUTsxDIIu7eV7_9QLomIUL93tfG1Z1baEl4uL8-SwaYf-wW51zofaV5Q754';

function urlBase64ToUint8Array(base64String) {
  // eslint-disable-next-line
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function getNotificationPermissionState() {
  if (navigator.permissions) {
    return navigator.permissions.query({ name: 'notifications' })
      .then(result => result.state);
  }

  return new Promise(resolve => {
    resolve(Notification.permission);
  });
}

export function subscribePush(serviceWorker) {
  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicKey),
  };

  return serviceWorker.pushManager.subscribe(subscribeOptions);
}

export function askPermission() {
  if (!('Notification' in window)) {
    return Promise.reject(lang.t('notify.pushNotSupported'));
  } if (Notification.permission === 'granted') {
    return Promise.resolve();
  } if (Notification.permission !== 'denied') {
    return new Promise(((resolve, reject) => {
      const permissionResult = Notification.requestPermission(result => {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }))
      .then(permissionResult => {
        if (permissionResult !== 'granted') {
          return Promise.reject(lang.t('notify.pushDenied'));
        }
        return Promise.resolve();
      });
  }

  return Promise.reject(lang.t('notify.pushDenied'));
}

export default {
  askPermission,
};
