<template>
  <div>
    <div
      class="license flex-grow-1 d-flex justify-content-between"
      style="height: 33px;"
    >
      <div class="ml-2 mr-2 align-self-center">
        <span class="name">
          {{ getLicenseName(licenseType) }}
        </span>
      </div>
      <div class="mr-2 align-self-center mr-auto">
        <span
          class="badge small"
          style="border: 1px solid"
        >
          x{{ licenseCount }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    licenseType: String,
    licenseCount: Number,
  },
  data() {
    return {
      licenseName: String,
    };
  },
  methods: {
    getLicenseName(licenseType) {
      switch (licenseType.toLowerCase()) {
        case 'packos-entry-dummy-machine':
          return this.$t('licenses.entryDummy');
        case 'packos-entry-manual-machine':
          return this.$t('licenses.entryManual');
        case 'packos-automated-base-machine':
          return this.$t('licenses.baseAutomated');
        case 'packos-automated-advanced-extension':
          return this.$t('licenses.advancedAutomated');
        case 'signal-extension':
          return this.$t('licenses.signal');
        case 'vision-extension':
          return this.$t('licenses.vision');
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  .license {
    box-shadow: 0 0 2px rgba(100,100,100,0.4) inset;
    border-radius: 5px;
    padding: 4px 10px;
    line-height: 1.1;
    text-align: left;
    transition: background-color 500ms;

    .name {
      font-weight: 400;
      font-size: 14px;
    }

  }
</style>
