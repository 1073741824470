<template>
  <div>
    <div
      v-for="(s, id) in competences"
      :key="id"
    >
      <CompetencesRow
        :id="id"
        :name="name(s)"
        :expiry-date.sync="s.expiryDate"
        :competence-id="s.competenceId"
        @remove="remove"
      />
    </div>
    <div
      v-if="!allSelected"
      class="text-center pt-1"
    >
      <div class="d-flex align-items-center">
        <div class="pr-2">
          <button
            class="btn btn-sm icon-btn"
            :class="adding ? 'btn-secondary' : 'btn-success'"
            type="button"
            @click="adding = !adding"
          >
            <i
              v-if="!adding"
              class="ion ion-md-add"
            />
            <i
              v-else
              class="ion ion-ios-arrow-up"
            />
          </button>
        </div>
        <BFormInput
          v-if="adding"
          ref="search"
          v-model="search"
          class="flex-grow-1"
          size="sm"
          :placeholder="`${$t('general.search')}...`"
          type="search"
        />
      </div>
      <div
        v-if="adding"
        class="pt-2 scrollable"
      >
        <div
          v-for="p in searchResult"
          :key="p.id"
          class="text-left consumable-add"
          @click.stop="add(p)"
        >
          <i class="ion ion-md-add pr-2" />
          {{ p.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import createSearch from '@core/utils/search';
import CompetencesRow from '@/components/people/users/CompetencesRow';

export default {
  props: {
    competences: Array,
  },
  data: () => ({
    search: '',
    pending: false,
    adding: false,
    localCompetences: [],
  }),
  components: {
    CompetencesRow,
  },
  computed: {
    ...mapGetters(['tenantId']),
    ...mapState({
      allCompetences: state => state.competence.competences || [],
    }),
    allSelected() {
      return this.competences.length === this.allCompetences.length;
    },
    searchResult() {
      const search = createSearch(this.search);
      return this.allCompetences
        .filter(c => !(this.competences.some(s => s.competenceId === c.id)))
        .filter(p => search(p.name))
        .slice(0, 5);
    },
  },
  methods: {
    ...mapActions(['getCompetences']),

    name(s) {
      const comp = this.allCompetences.find(x => x.id === s.competenceId);
      return comp ? comp.name : '';
    },
    remove(competenceId) {
      this.localCompetences = this.localCompetences.filter(c => c.competenceId !== competenceId);
      this.$emit('update:competences', this.localCompetences);
    },
    add(p) {
      const comp = this.allCompetences.find(c => c.id === p.id);
      this.localCompetences.push({
        expiryDate: p.expiryDate,
        competenceId: comp.id,
      });
      this.$emit('update:competences', this.localCompetences);
    },
  },
  created() {
    this.localCompetences = this.competences || [];
    this.getCompetences({
      params: { tenantId: this.tenantId },
    });
  },
};
</script>

<style lang="scss" scoped>
  .consumable-add {
    transition: transform 400ms;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(150, 150, 150, 0.5);
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 2px 15px;
    &:hover {
      transform: translateX(5px);
    }
  }

  .rounded-input {
    border-radius: 20px;
  }

  .search-operation {
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }

  .remove-icon {
    font-size: 25px;
    line-height: 10px;
    cursor: pointer;
    transition: transform 300ms;

    &:hover {
      transform: scale(1.2);
    }
  }

  .search-info {
    font-size: 16px;
    margin-top: 16px;
  }

  .scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 210px;
    margin-top: 1rem;
    padding: 0.5rem ;
  }
</style>
