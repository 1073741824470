<template>
  <div class="container pl-0 pr-0">
    <div
      class="text-left mt-2 mb-2"
    >
      <div class="row">
        <div class="col-12 ">
          <h6>{{ $t('permissions.choosePermission') }}</h6>
          <div class="border-box">
            <div
              v-for="el in modulesWithPermissions"
              :key="el.name"
            >
              <div
                class="permission-header d-flex align-items-center"
                @click="selectedApp === el.name ? (selectedApp = null) : selectedApp = el.name"
              >
                <img
                  class="app-logo"
                  :src="require(`@core/assets/logos/${el.name.toLowerCase()}-color-icon.png`)"
                >
                <div>
                  {{ el.text }}
                </div>
              </div>
              <SmoothReflow>
                <PermissionsTree
                  class="tree-lg"
                  :permissions="el.children"
                  :root="el"
                  :selected="selectedPermission"
                  :assigned-permissions="selectedPermissions"
                  :hide-parent="true"
                  :is-expanded="selectedApp == el.name"
                  :is-clicked-from-parent="false"
                  :check-with-checkmark-selected="true"
                  :current-depth="1"
                  :selected-subject-type="selectedSubjectType"
                  @permission-selected="permissionSelected"
                  @select="savePermissions"
                />
              </SmoothReflow>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-3" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { handleApiError } from '@/utils/handleApiError';
import PermissionsTree from '@/components/permissions/PermissionsTree';

export default {
  props: {
    user: Object,
    roleId: String,
    subjectType: String,
  },
  data: () => ({
    selectedPermissionName: '',
    selectedApp: '',
    roles: [],
    selectedPermissions: [],
    selectedSubjectType: '',
  }),
  components: {
    PermissionsTree,
  },
  computed: {
    ...mapState({
      plants: state => state.structure.plants || [],
      tenantRoles: state => state.role.roles || [],
    }),
    ...mapGetters('core', ['isSysAdmin', 'tenantModules', 'can']),
    ...mapGetters(['permissionTypes', 'permissionTypeList', 'tenantId']),
    filteredPlants() {
      return this.plants.filter(x => this.can(this.$perm.CONFIG_PLANT_ADMIN, x.id));
    },
    modulesWithPermissions() {
      return this.permissionTypes
        .filter(x => x.name.toLowerCase() === 'hub'
        || this.tenantModules.some(m => m.module.toLowerCase() === x.name.toLowerCase()));
    },
    role() {
      if (!this.roleId) return null;
      return this.tenantRoles.find(x => x.id === this.roleId);
    },
    selectedPermission() {
      return this.permissionTypeList.find(x => x.name === this.selectedPermissionName);
    },
  },
  watch: {
    roleId(newRoleId) {
      this.getRolePermission({
        params: {
          roleId: newRoleId,
        },
      });
    },

  },
  methods: {
    ...mapActions([
      'setRolePermissions',
      'getRolePermission',
      'setsubjectTypeRole',
    ]),
    updateRolePermission(roleId) {
      this.getRolePermission({
        params: {
          roleId,
        },
      })
        .then(({ data }) => {
          this.selectedPermissions = data ?? [];
        })
        .catch(({ response }) => {
          handleApiError(response);
        });
    },
    togglePermission(permission) {
      if (this.selectedPermissions.includes(permission)) {
        this.selectedPermissions = this.selectedPermissions.filter(x => x !== permission);
      } else {
        this.selectedPermissions.push(permission);
      }
    },
    savePermissions(permission) {
      this.togglePermission(permission);
      this.setRolePermissions({
        params: {
          tenantId: this.tenantId,
        },
        data: {
          roleId: this.role.id,
          permissions: this.selectedPermissions,
        },
      })
        .then(() => {
          this.$root.$bvToast.toast(this.$t('permissions.updateSuccessfully'), {
            title: this.$t('general.requestReceived'),
            variant: 'success',
            appendToast: true,
          });
        });
    },
    permissionSelected(subjectType) {
      this.selectedSubjectType = subjectType;
    },
  },
  beforeMount() {
    this.selectedSubjectType = this.subjectType;
  },
  created() {
    this.updateRolePermission(this.roleId);
  },
};
</script>

<style lang="scss" scoped>

  .tree-table-row-permissions{
    margin-top: 5px !important;
    margin-left: -40px !important;
  }

  .border-box {
    background-color: rgb(245, 245, 245);
    padding: 10px;
    border-radius: 10px;
  }

  .zoom-hoverable {
    cursor: pointer;

    &:hover {
      transform: scale(1.5);
    }
  }

  .permission-header {
    padding: 2px 5px;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
      border-color: #333;
    }

    .app-logo {
      max-height: 25px;
      padding-right: 5px;
    }

  }
</style>
