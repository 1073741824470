<template>
  <div
    class="h-100 d-flex flex-column"
  >
    <div class="flex-grow-1">
      <RouterView
        class="h-100"
        @selected="page = $event"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    page: null,
  }),
  computed: {
    ...mapGetters(['tenantId']),
  },
  methods: {
    changePage(key) {
      return this.$router.push(`/${this.tenantId}/licenses/${key}`);
    },
  },
  created() {
    this.$emit('page', 'licenses');
  },
};
</script>

<style />
