<template>
  <div>
    <form
      class="text-left"
      @submit.prevent="submit"
    >
      <label class="mb-0 small font-weight-bold">
        {{ $t('plants.name') }}
      </label>
      <BFormInput
        v-model="name"
        :placeholder="`${$t('plants.name')}...`"
        class="mb-2"
      />
      <label class="mb-0 small font-weight-bold">
        {{ $t('plants.code') }}
      </label>
      <BFormInput
        v-model="code"
        :placeholder="`${$t('plants.code')}...`"
        class="mb-2"
      />
      <label class="mb-0 small font-weight-bold">
        {{ $t('elements.location') }}
      </label>
      <ElementSelector
        :element-id.sync="parentId"
        :elements="filteredPlantElements"
        :plant="plant"
        class="mb-2"
      />
      <label class="mb-0 small font-weight-bold">
        {{ $t('general.plant') }}
      </label>
      <BFormInput
        :value="plantName"
        disabled
        class="mb-2"
      />

      <p class="mb-0 mt-4 text-muted small text-uppercase font-weight-bold">
        {{ $t('taxonomies.assignTo') }}
      </p>
      <hr class="mt-1">
      <div
        v-if="taxonomiesFetchError"
        class="text-danger text-center mt-2"
      >
        <i class="fas fa-times" />
        <div class="small">
          {{ $t('taxonomies.unableToFetch') }}
        </div>
      </div>
      <div
        v-if="loadingPlantTaxonomies || loadingElementAssignedTaxonomies"
        class="text-center mt-3"
      >
        <BSpinner
          small
          variant="primary"
        />
      </div>
      <div v-else-if="type">
        <div v-if="plantTaxonomies.length > 0">
          <div
            v-for="plantTaxonomy in plantTaxonomies"
            :key="plantTaxonomy.id"
          >
            <label class="mb-0 small font-weight-bold">
              {{ plantTaxonomy.description || plantTaxonomy.code || '-' }}
            </label>
            <TaxonomyElementSelect
              v-model="plantTaxonomiesAssigns[plantTaxonomy.id]"
              :taxonomy-nodes="plantTaxonomy.nodes"
              :plant="plant"
            />
          </div>
        </div>

        <div v-if="type === 'Line' || type === 'Machine'">
          <p class="mb-0 mt-4 text-muted small text-uppercase font-weight-bold">
            {{ $t('elements.capabilities') }}
          </p>
          <hr class="mt-1">
          <ElementCapabilitiesSelect
            v-model="capabilities"
            :element-type="type"
          />
        </div>
      </div>

      <div v-if="error">
        <hr
          class="w-25"
          style="margin-top: 10px; margin-bottom: 10px"
        >
        <div class="text-center text-danger error ">
          {{ error.title }}
          <div
            v-for="(err, index) in (error.list || [])"
            :key="index"
            class="small"
          >
            {{ err }}
          </div>
        </div>
      </div>

      <div class="mt-3">
        <SaveButton
          :pending="pending"
          :disabled="disabled"
          :show-remove="!!element"
          :remove-pending="removePending"
          @remove="remove"
          @cancel="$emit('close')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import TaxonomyElementSelect from '@/components/taxonomies/TaxonomyElementSelect';
import { elementType } from '@/utils/dictionary';
import { handleApiError } from '@/utils/handleApiError';
import { mapActions, mapGetters } from 'vuex';
import ElementCapabilitiesSelect from './ElementCapabilitiesSelect';
import ElementSelector from './ElementSelector';

export default {
  props: {
    plant: {
      type: Object,
      required: true,
    },
    element: Object,
    plantElements: Array,
  },
  data: () => ({
    id: null,
    parentId: null,
    plantId: null,
    name: '',
    code: '',
    type: null,
    pending: false,
    removePending: false,
    error: null,
    plantName: null,
    loadingPlantTaxonomies: false,
    loadingElementAssignedTaxonomies: false,
    plantTaxonomies: [],
    plantTaxonomiesAssigns: {},
    elementTypeEnum: elementType,
    capabilities: {},
    taxonomiesFetchError: false,
  }),
  components: {
    TaxonomyElementSelect,
    ElementCapabilitiesSelect,
    ElementSelector,
  },
  computed: {
    ...mapGetters([
      'elementType',
    ]),
    disabled() {
      return !this.name || !this.code || !this.parentId;
    },
    filteredPlantElements() {
      if (!this.plantElements) return [];
      return this.plantElements.filter(x => x.id !== this.id);
    },
  },
  watch: {
    plantTaxonomies() {
      this.plantTaxonomiesAssigns = this.plantTaxonomies.reduce((acc, curr) => {
        acc[curr.id] = '';
        return acc;
      }, {});
    },
    parentId(v) {
      this.type = this.parseStartingTypeBasedOnParentId(v);
    },
  },
  methods: {
    ...mapActions([
      'createElement',
      'updateElement',
      'deleteElement',
      'getElement',
      'getTaxonomiesAssignmentData',
      'getTaxonomiesForElement',
      'assignTaxonomyForElement',
      'unassignTaxonomyForElement',
      'getUserElements',
    ]),
    submit() {
      if (this.element) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      this.pending = true;
      this.createElement({
        data: {
          plantId: this.plantId,
          parentId: this.parentId,
          name: this.name,
          code: this.code,
          type: this.type,
          capabilities: this.capabilities,
          taxonomies: this.taxonomiesFetchError
            ? undefined
            : Object.keys(this.plantTaxonomiesAssigns)
              .map(x => ({
                namespaceId: this.plantId,
                taxonomyId: x,
                taxonomyNodeId: this.plantTaxonomiesAssigns[x] || null,
              })),
        },
      })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
          this.$emit('close');
        });
    },
    update() {
      this.pending = true;
      return this.updateElement({
        params: {
          id: this.element.id,
          plantId: this.plantId,
        },
        data: {
          parentId: this.parentId,
          plantId: this.plantId,
          name: this.name,
          code: this.code,
          type: this.type,
          capabilities: this.capabilities,
          taxonomies: this.taxonomiesFetchError
            ? undefined
            : Object.keys(this.plantTaxonomiesAssigns)
              .map(x => ({
                namespaceId: this.plantId,
                taxonomyId: x,
                taxonomyNodeId: this.plantTaxonomiesAssigns[x] || null,
              })),
        },
      })
        .then(() => {
          this.$emit('close');
        })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    async remove() {
      this.removePending = true;

      try {
        await this.deleteElement({
          params: {
            id: this.id,
            plantId: this.plantId,
          },
        });
        await this.getUserElements();
      } catch ({ response }) {
        handleApiError(response);
      } finally {
        this.removePending = false;
        this.$emit('close');
      }
    },
    async fetchElementDetails() {
      const { data } = await this.getElement({
        params: {
          plantId: this.plantId,
          id: this.element.id,
        },
      });

      this.capabilities = data.capabilities;

      (data.taxonomies || [])
        .filter(t => t.namespaceId === this.plant.id)
        .forEach(t => {
          this.plantTaxonomiesAssigns[t.taxonomyId] = t.taxonomyNodeId;
        });
    },
    async fetchPlantTaxonomies() {
      try {
        this.taxonomiesFetchError = false;
        this.loadingPlantTaxonomies = true;
        const { data } = await this.getTaxonomiesAssignmentData({
          params: {
            query: {
              namespaceId: this.plantId,
            },
          },
        });
        this.plantTaxonomies = data;
        this.loadingPlantTaxonomies = false;
      } catch ({ response }) {
        this.loadingPlantTaxonomies = false;
        this.taxonomiesFetchError = true;
        handleApiError(response);
      }
    },
    parseStartingTypeBasedOnParentId(parentId) {
      const type = this.elementType(parentId);
      switch (type) {
        case this.elementTypeEnum.area:
          return this.elementTypeEnum.line;
        case this.elementTypeEnum.line:
          return this.elementTypeEnum.machine;
        default:
          return this.elementTypeEnum.area;
      }
    },
  },
  async created() {
    if (this.element) {
      this.id = this.element.id;
      this.parentId = this.element.parentId;
      this.name = this.element.name;
      this.code = this.element.code;
      this.type = this.element.type;
    } else {
      this.parentId = null;
    }

    this.plantName = this.plant.name;
    this.plantId = this.plant.id;
    await this.fetchPlantTaxonomies();

    if (this.element) {
      await this.fetchElementDetails();
    } else {
      this.capabilities = {
        orders: 'true',
        oee: 'true',
        downtimes: 'true',
      };
    }
  },
};
</script>
