import { render, staticRenderFns } from "./People.vue?vue&type=template&id=875211ae&scoped=true&"
import script from "./People.vue?vue&type=script&lang=js&"
export * from "./People.vue?vue&type=script&lang=js&"
import style0 from "./People.vue?vue&type=style&index=0&id=875211ae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "875211ae",
  null
  
)

export default component.exports