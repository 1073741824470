<template>
  <div>
    <div class="h4">
      <i class="ion ion-md-paper pr-2" />
      <span v-if="tenant">
        {{ name }}
      </span>
    </div>
    <form
      class="text-left"
      @submit.prevent="submit"
    >
      <label class="mb-0 small font-weight-bold">
        {{ $t('competence.expiryDate') }}
      </label>
      <DateTimePicker
        v-model="expiryDate"
        format="YYYY-MM-DD"
        formatted="DD.MM.YYYY"
        only-date
        auto-close
        class="mb-2"
      />
      <label class="mb-0 small font-weight-bold">
        {{ $t('registration.addressUrl') }}
      </label>
      <BFormInput
        v-model="urlAddress"
        class="mb-2"
      />
      <label class="mb-0 small font-weight-bold">
        {{ $t('registration.products') }}
      </label>
      <BFormSelect
        v-model="product"
        :options="products"
        class="mb-2"
      />
      <div v-if="error">
        <hr
          class="w-25"
          style="margin-top: 10px; margin-bottom: 10px"
        >
        <div class="text-center text-danger error ">
          {{ error.title }}
          <div
            v-for="(err, index) in (error.list)"
            :key="index"
            class="small"
          >
            {{ err }}
          </div>
        </div>
      </div>
      <div class="mt-3">
        <SaveButton
          :pending="pending"
          :disabled="disabled"
          :show-remove="!!tenant"
          :remove-pending="removePending"
          @save="submit"
          @remove="remove"
          @cancel="$emit('close')"
        />
      </div>
    </form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    tenant: Object,
  },
  data: () => ({
    id: null,
    name: '',
    urlAddress: null,
    expiryDate: null,
    product: null,
    products: null,
    pending: false,
    removePending: false,
    error: null,
  }),
  computed: {
    ...mapGetters(['tenantId']),
    disabled() {
      return !this.name || !this.code;
    },
  },
  methods: {
    submit() {
      if (this.tenant) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      return null;
    },
    update() {
      return null;
    },
    remove() {
      return null;
    },
  },
  created() {
    if (this.tenant) {
      this.id = this.tenant.id;
      this.name = this.tenant.name;
    }
  },
};
</script>
<style>
</style>
