export const elementType = {
  plant: 'Plant',
  machine: 'Machine',
  segment: 'Segment',
  area: 'Area',
  line: 'Line',
};

export const taxonomyTag = {
  element: 'Element',
  workEvent: 'WorkEvent',
};

export default {
  elementType,
  taxonomyTag,
};
