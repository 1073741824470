import Vue from 'vue';
import Vuex from 'vuex';

import taxonomies from '@/store/modules/taxonomies';
import display from './modules/display';
import auth from './modules/auth/index';
import auditLog from './modules/auditLog';
import structure from './modules/structure';
import user from './modules/user';
import role from './modules/team';
import tenant from './modules/tenant';
import product from './modules/product';
import accessCard from './modules/accessCard';
import competence from './modules/competence';
import permission from './modules/permission';
import core from './modules/core';
import licenses from './modules/licenses';
import imports from './modules/imports';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    core,
    display,
    auth,
    structure,
    user,
    role,
    accessCard,
    competence,
    permission,
    product,
    tenant,
    auditLog,
    licenses,
    imports,
    taxonomies,
  },
});
