import moment from 'moment';

// for language codes use ISO 639-1 standard

const supportedLanguages = [
  {
    name: 'English',
    lang: 'en',
    flag: 'gb',
  },
  {
    name: 'Polski',
    lang: 'pl',
    flag: 'pl',
  },
  {
    name: 'Português',
    lang: 'pt',
    flag: 'pt',
  },
  {
    name: 'Nederlands',
    lang: 'nl',
    flag: 'nl',
  },
  {
    name: 'Français',
    lang: 'fr',
    flag: 'fr',
  },
  {
    name: 'Deutsch',
    lang: 'de',
    flag: 'de',
  },
  {
    name: 'Español',
    lang: 'es',
    flag: 'es',
  },
  {
    name: 'Pусский',
    lang: 'ru',
    flag: 'ru',
  },
  {
    name: 'Tiếng Việt',
    lang: 'vi',
    flag: 'vn',
  },
  {
    name: 'ภาษาไทย',
    lang: 'th',
    flag: 'th',
  },
  {
    name: '中国人',
    lang: 'zh',
    locale: 'zh-cn',
    flag: 'cn',
  },
];

const updateLanguage = (langCode, i18n, weekStart) => {
  const supported = supportedLanguages.find(x => x.lang === langCode);
  const lang = supported?.lang || 'en';
  const locale = supported?.locale || supported?.lang || 'en';
  moment.locale(locale, {
    week: {
      dow: weekStart,
    },
  });
  // eslint-disable-next-line no-param-reassign
  i18n.locale = lang;
  return { language: lang, locale };
};

const browserLang = () => {
  const lang = navigator.language || navigator.userLanguage;
  return (lang ? lang.substring(0, 2) : null);
};

const language = localStorage.getItem('language') || browserLang() || 'en';

export default i18n => {
  const currentLanguageSettings = updateLanguage(language, i18n, 0);

  return {
    state: {
      language: currentLanguageSettings.language,
      locale: currentLanguageSettings.locale,
      weekStart: 0,
    },
    mutations: {
      changeLanguage(s, { lang, locale }) {
        s.language = lang;
        s.locale = locale;
        localStorage.setItem('language', lang);
        localStorage.setItem('locale', locale);
      },
      setWeekStart(s, weekStart) {
        s.weekStart = weekStart;
        moment.locale(s.locale, {
          week: {
            dow: weekStart,
          },
        });
      },
    },
    getters: {
      weekStart: s => s.weekStart,
      locale: s => s.locale,
      language: (s, g) => g.languages.find(l => l.lang === s.language) || g.languages[0],
      languages: () => supportedLanguages.filter(sl => Object.keys(i18n.messages).includes(sl.lang)),
    },
    actions: {
      setLanguage(ctx, { lang, save }) {
        if (!lang) return;
        const newLanguageSettings = updateLanguage(
          lang,
          i18n,
          ctx.getters.weekStart,
        );

        ctx.commit('changeLanguage', { lang: newLanguageSettings.language, locale: newLanguageSettings.locale });
        if (save) {
          ctx.dispatch('core/setUserSetting', {
            data: {
              key: 'language',
              value: newLanguageSettings.language,
              scope: '',
            },
          }, { root: true });
        }
      },
    },
  };
};
