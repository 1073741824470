<template>
  <div class="d-flex justify-content-center">
    <div class="container">
      <div class="d-flex text-left mt-2">
        <h4>
          <i class="ion ion-md-business pr-2" />
          {{ $t('pages.plants') }}
        </h4>
        <button
          v-if="canAdd"
          class="btn btn-success btn-sm icon-btn ml-3"
          style="margin-top: 2px"
          @click="plant = null; plantForm = true; selected = false;"
        >
          <i class="ion ion-md-add" />
        </button>
      </div>
      <hr style="max-width: 400px; margin-left: 0; margin-right: auto">
      <div v-if="!pending">
        <div class="d-flex align-items-end mb-2">
          <div class="pl-2 flex-grow-1">
            <BFormInput
              ref="search"
              v-model="search"
              type="search"
              :placeholder="`${$t('general.search')}...`"
              style="height: 30px; font-size: 14px; border-radius: 15px"
              @click="$refs.search.select()"
            />
          </div>
        </div>

        <PlantList
          :key="plantForm"
          :search="search"
          :selected="plant"
          @select="select($event)"
          @edit="edit($event)"
        />
      </div>
      <div v-else>
        <Loader />
      </div>

      <LModal
        :show.sync="plantForm"
      >
        <PlantForm
          v-if="plantForm"
          :plant="plant"
          @close="plantForm = false"
        />
      </LModal>
    </div>

    <Transition name="appear-right">
      <div
        v-if="plant && selected && screenWidth >= 800"
        class="flex-grow-1"
        style="max-width: 600px;"
      >
        <ElementStructurePreview
          class="px-3"
          style="min-width: 600px"
          :search="search"
          :plant="plant"
          @close-preview="selected = false"
        />
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PlantForm from '@/components/plants/PlantForm';
import PlantList from '@/components/plants/PlantList';
import ElementStructurePreview from '@/components/elements/ElementStructurePreview';

export default {
  data: () => ({
    search: '',
    plant: null,
    selected: false,
    plantForm: false,
  }),
  components: {
    PlantForm,
    PlantList,
    ElementStructurePreview,
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    ...mapGetters('core', ['can']),
    ...mapGetters(['screenWidth', 'tenantId']),
    ...mapState({
      pending: state => state.structure.pending.plants || state.structure.pending.elements,
    }),
    canAdd() {
      return this.can(this.$perm.CONFIG_TENANT_ADMIN, this.tenantId);
    },
  },
  methods: {
    select(plant) {
      this.plant = plant;
      this.selected = true;
    },
    edit(plant) {
      this.plant = plant;
      this.selected = false;
      this.plantForm = true;
    },
  },
  created() {
    this.$emit('page', 'plants');
  },
};
</script>

<style lang="scss" scoped>
  .appear-right-enter-active, .appear-right-leave-active {
    overflow: hidden;
    transition: opacity 500ms, max-width 500ms, min-width 500ms;
  }
  .appear-right-enter, .appear-right-leave-to {
    opacity: 0;
    min-width: 0;
    max-width: 0 !important;
  }
  .appear-right-enter-to, .appear-right-leave {
    opacity: 1;
    min-width: 500px !important;
    max-width: 500px !important;
  }
</style>
