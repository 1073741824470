<template>
  <div>
    <div class="h4">
      <i class="icon-user pr-2" />
      <span>
        {{ user ? `${user.firstName} ${user.lastName}` : $t('users.new') }}
      </span>
    </div>
    <div class="py-2">
      <HorizontalMenu
        v-if="!!user"
        :selected.sync="page"
        :list="userPages"
      />
    </div>
    <div class="px-3">
      <UserDetails
        v-if="page === 'details'"
        :user="user"
        @create="$emit('create', $event)"
        @close="$emit('close')"
      />
      <PermissionsForm
        v-else-if="page === 'permission'"
        :user="user"
        @update="$emit('update')"
      />
      <UpdatePassword
        v-else-if="page === 'password'"
        :user="user"
        :admin-mode="true"
        @close="$emit('close')"
      />
      <CardSettingsForm
        v-else-if="page === 'card'"
        :user-id="user.id"
        :admin-mode="true"
      />
      <UserRolesSettingForm
        v-else-if="page === 'roles'"
        :user="user"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { handleApiError } from '@/utils/handleApiError';
import HorizontalMenu from '@core/components/menu/HorizontalMenu';
import UpdatePassword from '@/components/UpdatePassword';
import CardSettingsForm from '@/components/CardSettingsForm';
import PermissionsForm from '@/components/permissions/PermissionsForm';
import UserDetails from './UserDetails';
import UserRolesSettingForm from './UserRolesSettingForm';

export default {
  props: {
    user: Object,
  },
  data: () => ({
    page: 'details',
  }),
  components: {
    UserDetails,
    PermissionsForm,
    UpdatePassword,
    HorizontalMenu,
    CardSettingsForm,
    UserRolesSettingForm,
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    ...mapGetters(['tenantId']),
    userPages() {
      return [
        {
          key: 'details',
          name: this.$t('general.details'),
        },
        {
          key: 'permission',
          name: this.$t('account.permissions'),
        },
        {
          key: 'password',
          name: this.$t('account.password'),
        },
        {
          key: 'card',
          name: this.$t('settings.card'),
        },
        {
          key: 'roles',
          name: this.$t('account.roles'),
        },
      ];
    },
    submitDisabled() {
      if (this.user) return false;
      return !this.password || this.repeatedPassword !== this.password;
    },
    userToUpdate() {
      return {
        id: this.id,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        competences: this.competences,
        roles: this.roles,
        permissions: this.permissions,
      };
    },
  },
  methods: {
    ...mapActions(['createUser', 'updateUser', 'deleteUser']),
    save() {
      if (this.user) {
        this.update();
      } else {
        this.create();
      }
    },
    update() {
      this.pending = true;
      this.updateUser({
        params: {
          id: this.id,
        },
        data: this.userToUpdate,
      })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
          this.$emit('close');
        });
    },
    create() {
      this.pending = true;
      const newUser = {
        firstName: this.firstName,
        lastName: this.lastName,
        password: this.password,
        email: this.email,
        phoneNumber: this.phoneNumber,
        competences: this.competences,
        roles: this.roles,
      };
      this.createUser({
        params: {
          tenantId: this.tenantId,
        },
        data: newUser,
      })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
          this.$emit('close');
        });
    },
    remove() {
      this.removePending = true;
      this.deleteUser({
        params: {
          id: this.id,
        },
      })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
          this.$emit('close');
        });
    },
  },
  created() {
    if (this.user) {
      this.id = this.user.id;
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.email = this.user.email;
      this.phoneNumber = this.user.phoneNumber;
      this.competences = (this.user.competences || []).slice();
      this.roles = (this.user.roles || []).slice();
      this.permissions = (this.user.permissions || []).slice();
    }
  },
};
</script>

<style>

</style>
