import axios from '@/api/axios';
import lang from '@/lang';
import * as permissions from '@core/dict/permissions';
import Vapi from 'vuex-rest-api';

const permission = new Vapi({
  axios,
  state: {},
});

const getAllChildren = el => {
  if (el.children) {
    const list = el.children.flatMap(x => getAllChildren(x));
    const perm = { ...el };
    delete perm.children;

    return [perm].concat(list);
  }
  return el;
};

permission.getters = {
  permissionTypeList: (s, g) => g.permissionTypes.flatMap(x => getAllChildren(x)),
  permissionTypes: () => [
    {
      name: 'PackOs',
      text: lang.t('general.packOs'),
      children: [
        {
          name: permissions.PACKOS_PLANT_ADMIN,
          text: lang.t('permissions.plantAdminAccess'),
          arg: 'PlantId',
          children: [
            {
              name: permissions.PACKOS_ELEMENT_ACCESS,
              text: lang.t('permissions.plantElementAccess'),
              arg: 'LineId',
            },
            {
              name: permissions.PACKOS_DOWNTIME_MANAGEMENT,
              text: lang.t('permissions.manageDowntimes'),
              arg: 'LineId',
              children: [
                {
                  name: permissions.PACKOS_MANUAL_DOWNTIME,
                  text: lang.t('permissions.manualDowntime'),
                  arg: 'LineId',
                },
                {
                  name: permissions.PACKOS_DOWNTIME_CHANGE,
                  text: lang.t('permissions.changeDowntimes'),
                  arg: 'LineId',
                },
              ],
            },
            {
              name: permissions.PACKOS_ORDER_MANAGEMENT,
              text: lang.t('permissions.orderManagement'),
              arg: 'LineId',
              children: [
                {
                  name: permissions.PACKOS_ORDER_VIEW,
                  text: lang.t('permissions.orderViewAccess'),
                  arg: 'LineId',
                },
                {
                  name: permissions.PACKOS_ORDER_COMPLETED_MANUALPRODUCTION,
                  text: lang.t('permissions.orderCompleteManualProduction'),
                  arg: 'LineId',
                },
                {
                  name: permissions.PACKOS_ORDER_INPROGRESS_EDIT,
                  text: lang.t('permissions.orderInprogressEdit'),
                  arg: 'LineId',
                },
                {
                  name: permissions.PACKOS_ORDER_COMPLETED_EDIT,
                  text: lang.t('permissions.orderCompleteEdit'),
                  arg: 'LineId',
                },
                {
                  name: permissions.PACKOS_ORDER_LIFECYCLE,
                  text: lang.t('permissions.orderLifecycle'),
                  arg: 'LineId',
                },
                {
                  name: permissions.PACKOS_ORDER_EDIT,
                  text: lang.t('permissions.orderEdit'),
                  arg: 'LineId',
                },
                {
                  name: permissions.PACKOS_MANUAL_PRODUCTION,
                  text: lang.t('permissions.manualProduction'),
                  arg: 'LineId',
                },
              ],
            },
            {
              name: permissions.PACKOS_MATERIAL_MANAGEMENT,
              text: lang.t('permissions.materialManagement'),
              arg: 'PlantId',
              children: [
                {
                  name: permissions.PACKOS_SKU_LIST,
                  text: lang.t('permissions.showSkuList'),
                  arg: 'PlantId',
                },
                {
                  name: permissions.PACKOS_SKU_EDIT,
                  text: lang.t('permissions.editSkuList'),
                  arg: 'PlantId',
                },
              ],
            },
            {
              name: permissions.PACKOS_STATES_MANAGEMENT,
              text: lang.t('permissions.statesManagement'),
              arg: 'PlantId',
              children: [
                {
                  name: permissions.PACKOS_STATES_EDIT,
                  text: lang.t('permissions.statesEdit'),
                  arg: 'PlantId',
                },
                {
                  name: permissions.PACKOS_REASON_ADD,
                  text: lang.t('permissions.addReason'),
                  arg: 'LineId',
                },
                {
                  name: permissions.PACKOS_CORRECTIVE_ACTION_ADD,
                  text: lang.t('permissions.addCorrectiveAction'),
                  arg: 'LineId',
                },
                {
                  name: permissions.PACKOS_PROBLEMS_EDIT,
                  text: lang.t('permissions.editProblems'),
                  arg: 'LineId',
                },
              ],
            },
            {
              name: permissions.PACKOS_REPORT_ACCESS,
              text: lang.t('permissions.reportAccess'),
              arg: 'PlantId',
            },
            {
              name: permissions.PACKOS_FLOW,
              text: lang.t('permissions.configFlow'),
              arg: 'PlantId',
            },
            {
              name: permissions.PACKOS_ELEMENTS_ORDER,
              text: lang.t('permissions.elementsOrder'),
              arg: 'PlantId',
            },
            {
              name: permissions.PACKOS_UNITS,
              text: lang.t('permissions.unitsEdit'),
              arg: 'PlantId',
            },
            {
              name: permissions.PACKOS_SHIFTS,
              text: lang.t('permissions.configShifts'),
              arg: 'PlantId',
            },
            {
              name: permissions.PACKOS_SIGNALS,
              text: lang.t('permissions.configSignals'),
              arg: 'PlantId',
            },
            {
              name: permissions.PACKOS_TARGETS_CONFIG,
              text: lang.t('permissions.targetConfig'),
              arg: 'PlantId',
            },
          ],
        },
      ],
    },
    {
      name: 'HUB',
      text: lang.t('general.config'),
      children: [
        {
          name: permissions.CONFIG_TENANT_ADMIN,
          text: lang.t('permissions.tenantAdminAccess'),
          arg: 'TenantId',
          children: [
            {
              name: permissions.CONFIG_USER_MANAGEMENT,
              text: lang.t('permissions.usersManagementAccess'),
              arg: 'TenantId',
              children: [
                {
                  name: permissions.CONFIG_NOTIFICATIONS,
                  text: lang.t('permissions.notificationEdit'),
                  arg: 'TenantId',
                },
              ],
            },
          ],
        },
        {
          name: permissions.CONFIG_PLANT_ADMIN,
          text: lang.t('permissions.plantConfig'),
          arg: 'PlantId',
        },
        {
          name: permissions.CONFIG_LICENSE_TENANT_ACCESS,
          text: lang.t('permissions.tenantLicenseAccess'),
          arg: 'TenantId',
        },
      ],
    },
    {
      name: 'KEEPUP',
      text: lang.t('general.keepup'),
      children: [
        {
          name: permissions.KEEPUP_PLANT_ADMIN,
          text: lang.t('permissions.plantAdminAccess'),
          arg: 'PlantId',
          children: [
            {
              name: permissions.KEEPUP_WRITE,
              text: lang.t('permissions.writeAccess'),
              arg: 'LineId',
              children: [
                {
                  name: permissions.KEEPUP_READ,
                  text: lang.t('permissions.readAccess'),
                  arg: 'LineId',
                },
              ],
            },
          ],
        },
        {
          name: permissions.KEEPUP_PLANT_ACCESS,
          text: lang.t('permissions.plantAccess'),
          arg: 'PlantId',
        },
      ],
    },
    {
      name: 'WORKBOOST',
      text: lang.t('general.workboost'),
      children: [
        {
          name: permissions.WORKBOOST_TENANT_ADMIN,
          text: lang.t('permissions.tenantAdminAccess'),
          arg: 'TenantId',
          children: [
            {
              name: permissions.WORKBOOST_TENANT_PROJECT_CREATE,
              text: lang.t('permissions.projectCreate'),
              arg: 'TenantId',
            },
            {
              name: permissions.WORKBOOST_PROJECT_ADMIN,
              text: lang.t('permissions.projectAdmin'),
              arg: 'TenantId',
              children: [
                {
                  name: permissions.WORKBOOST_PROJECT_VIEW,
                  text: lang.t('permissions.projectView'),
                  arg: 'TenantId',
                },
                {
                  name: permissions.WORKBOOST_TEMPLATES_MANAGEMENT,
                  text: lang.t('permissions.templatesManagement'),
                  arg: 'TenantId',
                },
                {
                  name: permissions.WORKBOOST_PROJECT_QUESTS_CREATE,
                  text: lang.t('permissions.questsCreate'),
                  arg: 'TenantId',
                },
              ],
            },
            {
              name: permissions.WORKBOOST_GROUP_ADMIN,
              text: lang.t('permissions.questGroupAdmin'),
              arg: 'TenantId',
              children: [
                {
                  name: permissions.WORKBOOST_GROUP_QUESTS_VIEW,
                  text: lang.t('permissions.groupQuests'),
                  arg: 'TenantId',
                },
              ],
            },
            {
              name: permissions.WORKBOOST_REPORT_ACCESS,
              text: lang.t('permissions.reportAccess'),
              arg: 'TenantId',
            },
          ],
        },
      ],
    },
    {
      name: 'LOGICPRINT5',
      text: lang.t('general.logicprint5'),
      children: [
        {
          name: permissions.LOGICPRINT5_LABELLING,
          text: lang.t('permissions.labelling'),
          arg: 'TenantId',
        },
        {
          name: permissions.LOGICPRINT5_SERIALIZATION_AND_AGGREGATION,
          text: lang.t('permissions.serializationAndAggregation'),
          arg: 'TenantId',
        },
        {
          name: permissions.LOGICPRINT5_ORDERS,
          text: lang.t('permissions.orders'),
          arg: 'TenantId',
        },
        {
          name: permissions.LOGICPRINT5_SKU,
          text: lang.t('permissions.sku'),
          arg: 'TenantId',
        },
        {
          name: permissions.LOGICPRINT5_LABELS,
          text: lang.t('permissions.labels'),
          arg: 'TenantId',
        },
        {
          name: permissions.LOGICPRINT5_REQUESTS,
          text: lang.t('permissions.requests'),
          arg: 'TenantId',
        },
        {
          name: permissions.LOGICPRINT5_SETTINGS,
          text: lang.t('permissions.settings'),
          arg: 'TenantId',
        },
      ],
    },
    {
      name: 'LOGICPRINT7',
      text: lang.t('general.logicprint7'),
      children: [
        {
          name: permissions.LOGICPRINT7_COCKPIT_MANAGEMENT,
          text: lang.t('permissions.cockpitManagement'),
          arg: 'TenantId',
          children: [
            {
              name: permissions.LOGICPRINT7_COCKPIT_VIEW,
              text: lang.t('permissions.cockpitView'),
              arg: 'TenantId',
            },
            {
              name: permissions.LOGICPRINT7_COCKPIT_OPERATIONS,
              text: lang.t('permissions.cockpitOperations'),
              arg: 'TenantId',
            },
            {
              name: permissions.LOGICPRINT7_COCKPIT_RESTRICTIONS,
              text: lang.t('permissions.cockpitRestrictions'),
              arg: 'TenantId',
            },
          ],
        },
        {
          name: permissions.LOGICPRINT7_ORDERS_MANAGEMENT,
          text: lang.t('permissions.ordersManagement'),
          arg: 'TenantId',
          children: [
            {
              name: permissions.LOGICPRINT7_ORDERS_VIEW,
              text: lang.t('permissions.ordersView'),
              arg: 'TenantId',
            },
            {
              name: permissions.LOGICPRINT7_ORDERS_LIFECYCLE,
              text: lang.t('permissions.ordersLifecycle'),
              arg: 'TenantId',
            },
            {
              name: permissions.LOGICPRINT7_ORDERS_EDIT,
              text: lang.t('permissions.ordersEdit'),
              arg: 'TenantId',
            },
            {
              name: permissions.LOGICPRINT7_ORDERS_CREATE,
              text: lang.t('permissions.ordersCreate'),
              arg: 'TenantId',
            },
          ],
        },
        {
          name: permissions.LOGICPRINT7_SKU_MANAGEMENT,
          text: lang.t('permissions.skuManagement'),
          arg: 'TenantId',
          children: [
            {
              name: permissions.LOGICPRINT7_SKU_VIEW,
              text: lang.t('permissions.skuView'),
              arg: 'TenantId',
            },
            {
              name: permissions.LOGICPRINT7_SKU_EDIT,
              text: lang.t('permissions.skuEdit'),
              arg: 'TenantId',
            },
            {
              name: permissions.LOGICPRINT7_SKU_RESTRICTIONS,
              text: lang.t('permissions.skuRestrictions'),
              arg: 'TenantId',
            },
          ],
        },
        {
          name: permissions.LOGICPRINT7_PALLETS_MANAGEMENT,
          text: lang.t('permissions.palletsManagement'),
          arg: 'TenantId',
          children: [
            {
              name: permissions.LOGICPRINT7_PALLETS_VIEW,
              text: lang.t('permissions.palletsView'),
              arg: 'TenantId',
            },
            {
              name: permissions.LOGICPRINT7_PALLETS_LIFECYCLE,
              text: lang.t('permissions.palletsLifecycle'),
              arg: 'TenantId',
            },
          ],
        },
        {
          name: permissions.LOGICPRINT7_SETTINGS,
          text: lang.t('permissions.settings'),
          arg: 'TenantId',
        },
      ],
    },
    {
      name: 'GOODIN',
      text: lang.t('general.goodin'),
      children: [
        {
          name: permissions.GOODIN_PLANNING,
          text: lang.t('permissions.planning'),
          arg: 'TenantId',
        },
        {
          name: permissions.GOODIN_PRODUCTION,
          text: lang.t('permissions.production'),
          arg: 'TenantId',
        },
        {
          name: permissions.GOODIN_ARCHIVE,
          text: lang.t('permissions.archive'),
          arg: 'TenantId',
        },
        {
          name: permissions.GOODIN_IMPORT_LOG,
          text: lang.t('permissions.importLog'),
          arg: 'TenantId',
        },
      ],
    },
    {
      name: 'KSM',
      text: 'KSM Vision',
      children: [
        {
          name: permissions.KSM_OPERATOR,
          text: 'Operator',
          arg: 'TenantId',
        },
        {
          name: permissions.KSM_PRODUCTION_ASSISTANCE,
          text: 'Production Assistance',
          arg: 'TenantId',
        },
        {
          name: permissions.KSM_ADMIN,
          text: 'Admin',
          arg: 'TenantId',
        },
        {
          name: permissions.KSM_SERVICE,
          text: 'Service',
          arg: 'TenantId',
        },
      ],
    },
    {
      name: 'KNOWLAKE',
      text: lang.t('general.knowlake'),
    },
  ],
};

export default permission;
