<template>
  <div
    class="taxonomy-list-item"
    @click="handleClick"
  >
    <h4 class="mb-0 text-muted mr-4">
      <i class="fas fa-project-diagram" />
    </h4>

    <div class="w-100">
      <BRow align-v="center">
        <BCol sm="6">
          <h6 class="mb-1">
            {{ description || '-' }}
          </h6>
          <div class="d-flex align-items-center">
            <BBadge
              :variant="isDefault ? 'success' : 'dark'"
              class="mr-1"
            >
              {{ badgeName }}
            </BBadge>
            <p class="text-muted mb-0">
              {{ code }}
            </p>
          </div>
        </BCol>
        <BCol sm="6">
          <div class="ml-auto">
            <div
              v-for="tag in tags"
              :key="tag"
              class="tag-badge"
            >
              {{ tagName(tag) }}
            </div>
          </div>
        </BCol>
      </BRow>
    </div>

    <div
      v-if="false"
      class="ml-auto"
    >
      <BDropdown
        class="m-md-2"
        variant="link"
        right
        no-caret
      >
        <template #button-content>
          <i class="fas fa-ellipsis-h" />
        </template>

        <BDropdownItem
          @click.stop="$emit('clone', id)"
        >
          <i class="fas fa-clone mr-2" />
          {{ $t('general.clone') }}
        </BDropdownItem>
      </BDropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { taxonomyTag } from '@/utils/dictionary';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    namespaceId: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      plants: state => state.structure.plants || [],
    }),
    isDefault() {
      return this.code === 'OeeLossType';
    },
    badgeName() {
      if (this.isDefault) return this.$t('general.general');
      return this.plants.find(p => p.id === this.namespaceId)?.name || '?';
    },
  },
  methods: {
    handleClick() {
      this.$emit('select', this.id);
    },
    tagName(v) {
      switch (v) {
        case taxonomyTag.element:
          return this.$t('taxonomies.factoryStructure');
        case taxonomyTag.workEvent:
        default:
          return this.$t('taxonomies.problems');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.taxonomy-list-item {
  display: flex;
  align-items: center;
  background-color: #F5F5F5;
  border-radius: 5px;
  padding: 1rem 2rem;
  cursor: pointer;

  &:hover {
    background-color: #e8e8e8;
  }
}

.tag-badge {
  display: inline-block;
  font-size: 12px;
  line-height: 11px;
  padding: 6px 10px;
  color: #8F8F8F;
  background: #FFFFFF;
  border-radius: 3.6px;
  margin-right: 6px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
