<template>
  <div class="wrapper">
    <div class="mb-4 d-flex justify-content-between">
      <TaxonomiesBreadcrumbs v-if="!loadingTaxonomies" />

      <div v-if="onTaxonomiesListPage">
        <BButton
          class="rounded-pill"
          variant="success"
          @click="goToCreate"
        >
          <i class="fas fa-plus mr-1" />
          {{ $t('taxonomies.createNewClassification') }}
        </BButton>
      </div>
    </div>

    <BSpinner
      v-if="loadingTaxonomies"
      class="mx-auto mt-5"
    />
    <div
      v-else
      class="content"
    >
      <RouterView />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TaxonomiesBreadcrumbs from '@/components/taxonomies/form/TaxonomiesBreadcrumbs';

export default {
  components: { TaxonomiesBreadcrumbs },
  provide() {
    return {
      refreshTaxonomies: this.fetchTaxonomiesForPlants,
    };
  },
  computed: {
    ...mapGetters(['tenantId']),
    ...mapGetters([
      'taxonomies',
      'loadingTaxonomies',
    ]),
    ...mapState({
      plants: state => state.structure.plants || [],
    }),
    onTaxonomiesListPage() {
      return this.$route.name === 'taxonomies-list';
    },
  },
  watch: {
    plants() {
      this.fetchTaxonomiesForPlants();
    },
  },
  methods: {
    ...mapActions([
      'getTaxonomies',
    ]),
    async fetchTaxonomiesForPlants() {
      this.$store.commit('setLoadingTaxonomies', true);

      const { data } = await this.getTaxonomies({
        params: {
          query: {
            namespaceId: this.plants.map(x => x.id),
            tags: ['WorkEvent', 'Element'],
          },
        },
      });

      this.$store.commit('setTaxonomies', [...data.sort((a, b) => {
        if (a.code === 'OeeLossType') return -1;
        if (b.code === 'OeeLossType') return 1;
        return 0;
      })]);
      this.$store.commit('setLoadingTaxonomies', false);
    },
    goToCreate() {
      this.$router.push({
        path: `${this.$route.path}/create`,
      });
    },
  },
  created() {
    this.$emit('page', 'taxonomies');
    if (this.plants.length > 0) {
      this.fetchTaxonomiesForPlants();
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
  min-height: calc(100vh - 60px + 24px);
  max-height: calc(100vh - 60px + 24px);
  overflow-y: auto;
  margin: -2rem -1.5rem;
  padding: 2.5rem calc(1.5rem + 5px) 0 calc(1.5rem + 5px);
}

.content {
  background: white;
  box-shadow: 0px 0px 10px rgba(179, 179, 179, 0.25);
  border-radius: 8px 8px 0 0;
  padding: 2rem;
  flex-grow: 1;
}
</style>
