<template>
  <div>
    <div
      class="d-flex hoverable bg-white"
      style="height: 33px;"
    >
      <div
        class="plant flex-grow-1 d-flex justify-content-between"
        :class="{ selected }"
        @click.self="$emit('select')"
      >
        <div class="ml-2 mr-2 align-self-center">
          <span class="name">
            {{ name }}
          </span>
        </div>
        <div class="mr-2 align-self-center mr-auto">
          <span
            class="text-secondary small"
            style="line-height: 1; padding-bottom: 2px"
          >
            {{ code }}
          </span>
        </div>
        <div
          class="ml-1 align-self-center"
        >
          <button
            class="btn btn-outline-secondary btn-sm icon-btn"
            @click="$emit('edit')"
          >
            <i class="ion ion-md-create" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    id: String,
    name: String,
    code: String,
    selected: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  .plant {
    box-shadow: 0 0 2px rgba(100,100,100,0.4) inset;
    border-radius: 5px;
    padding: 4px 10px;
    line-height: 1.1;
    text-align: left;
    transition: background-color 500ms;

    .name {
      font-weight: 500;
      font-size: 13px;
    }

    &.selected {
      background-color: #dadada;
    }
  }

  .hoverable {
    transition: background-color 300ms;
    cursor: pointer;

    &:hover {
      background-color: rgba(150, 150, 150, 0.1);
      .name {
        text-decoration: underline;
      }
    }
  }
</style>
